import { Multicall } from "ethereum-multicall";
import { useMemo } from "react";
import { REACT_APP_MULTICALL } from "constants/NetworkChainId";
import useWallet from "../../useWallet";

export const useMultiCallContractInstance = () => {
  const { chainId, provider } = useWallet();
  const multiCallContract = useMemo(() => {
    const multiCallSMC = new Multicall({
      ethersProvider: provider,
      tryAggregate: true,
      multicallCustomContractAddress: REACT_APP_MULTICALL[chainId],
    });

    return multiCallSMC;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, provider]);

  return multiCallContract;
};
