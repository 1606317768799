/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable eqeqeq */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
import { BigNumber, ethers } from "ethers";
import _ from "lodash";

export function numberToString(num) {
  let sign = "";
  num += "";
  if (num.charAt(0) === "-") {
    num = num.substring(1);
    sign = "-";
  }
  const arr = num.split(/[e]/gi);
  if (arr.length < 2) return sign + num;
  const dot = (0.1).toLocaleString().substring(1, 2);
  const n = arr[0].replace(/^0+/, "");
  const exp = +arr[1];
  let w = n.replace(dot, "");
  const pos = n.split(dot)[1] ? n.indexOf(dot) + exp : w.length + exp;
  const L = pos - w.length;
  const s = "" + BigInt(w);

  function r() {
    return w.replace(new RegExp(`^(.{${pos}})(.)`), `$1${dot}$2`);
  }

  if (exp >= 0) {
    if (L >= 0) {
      w = s + "0".repeat(L);
    } else {
      w = r();
    }
  } else {
    if (pos <= 0) {
      w = "0" + dot + "0".repeat(Math.abs(pos)) + s;
    } else {
      w = r();
    }
  }
  const t = w.split(dot);
  if ((+t[0] == 0 && +t[1] == 0) || (+w == 0 && +s == 0)) w = "0";
  return sign + w;
}

export function formatUnits(value, unitName = undefined) {
  if (Number.isNaN(Number(value)) && !value.type) {
    return 0;
  }

  let valueToConvert;
  if ((typeof value === "string" && value.startsWith("0x")) || value.hex || value._hex) {
    valueToConvert = value;
  } else if (typeof value === "number" || (typeof value === "string" && !value.startsWith("0x"))) {
    const nbToString = numberToString(value);
    valueToConvert = nbToString;
  }

  const bn = value.type ? value : BigNumber.from(valueToConvert);
  return ethers.utils.formatUnits(bn, unitName);
}

export function toHex(number) {
  return `0x${Number(number).toString(16)}`;
}

/**
 *
 * @param {Array} arr
 * @returns {*} Returns the minimum value, else `undefined`
 */
export function min(arr) {
  let result = Infinity;
  let value;
  if (typeof arr[0] != "object" && arr != null) {
    arr = _.isArrayLike(arr) ? arr : _.values(arr);
    for (let i = 0; i < arr.length; i++) {
      value = arr[i];
      if (value != null && +value < +result) {
        result = value;
      }
    }
  }
  return result === Infinity ? undefined : result;
}

/**
 * Computes the minimum value of `array`. If `array` is empty or falsey or
 * the first element is not a BigNumber ethers objet.
 * `undefined` is returned.
 * @param {Array} array - Array of BigNumber ethers to iterate over.
 * @return {(BigNumber|undefined)} Returns the minimum value, else `undefined`
 */
export function minBigNumber(arr) {
  if (!BigNumber.isBigNumber(arr[0])) return undefined;

  let result = arr[0];
  let value;
  if (BigNumber.isBigNumber(arr[0]) && arr !== null) {
    arr = _.isArrayLike(arr) ? arr : _.values(arr);
    for (let i = 1; i < arr.length; i++) {
      value = arr[i];
      if (value.lt(result)) {
        result = value;
      }
    }
  }
  return result;
}
