import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  boxStyle: {
    alignItems: "center",
    width: "80%",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },

    "@media(max-width:576px)": {
      justifyContent: "space-between",
    },
    "@media(max-width: 1024px)": {
      justifyContent: "center",
    },
  },

  w80: {
    width: "80%",
    margin: "auto",
  },

  textStyle: {
    marginTop: "10px",
    color: "#6e747b",
    fontSize: "16px",
    fontWeight: 700,
    textAlign: "center",
    "@media(max-width: 1024px)": {
      paddingLeft: "8px",
    },
  },
  marginInputRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
  },
  marginInputWrapper: {
    width: "calc(100% - 70px - 4px)",
  },
  marginActionButtonGroup: {
    display: "flex",
    gap: "4px",
    width: "70px",
    flexDirection: "column",
  },
  marginErrorMessage: {
    margin: "5px 0",
    color: "#ff0000",
  },
  textLeft: {
    textAlign: "left",
  },
  amplifyRow: {
    justifyContent: "left",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  textAmplify: {
    marginTop: "10px",
    color: "#fff",
    fontSize: "16px",
    textAlign: "left",
    "@media(max-width: 1024px)": {
      paddingLeft: "8px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
}));
