import PropTypes from "prop-types";

import { SnackbarProvider } from "notistack";

const options = {
  maxSnack: 3,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  preventDuplicate: true,
};

const NotificationProvider = ({ children }) => (
  <SnackbarProvider {...options}>{children}</SnackbarProvider>
);

NotificationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default NotificationProvider;
