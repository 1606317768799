import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Box } from "@material-ui/core";

const useStyles = makeStyles({
  label: {
    textTransform: "uppercase",
  },
});

const DialogApplyButton = ({ children, color, disabled, onClick }) => {
  const classes = useStyles();

  return (
    <Box>
      <Button
        variant="text"
        color={color}
        className={classes.button}
        classes={{ label: classes.label }}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Button>
    </Box>
  );
};

DialogApplyButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["default", "primary"]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

DialogApplyButton.defaultProps = {
  color: "default",
  disabled: false,
  onClick: () => {},
};

export default DialogApplyButton;
