import { Box } from "@mui/material";
import React from "react";
import { LinearProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  progress: {
    background: "linear-gradient(90deg, #05EE76 0%, #FFC000 50.52%, #FF1D1D 100%)",
    height: 8,
    borderRadius: "50px",
    position: "relative",
  },
  triangle: ({ value }) => ({
    width: 15,
    height: 15,
    background: "#FFF",
    "-webkit-clip-path": "polygon(50% 0, 100% 100%, 0 100%)",
    clipPath: "polygon(50% 0, 100% 100%, 0 100%)",
    position: "absolute",
    top: "5px",
    left: value,
  }),
}));

function DiagramSB() {
  const classes = useStyles({ value: "0%" });
  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <LinearProgress
        value={0}
        className={classes.progress}
        classes={{ barColorPrimary: classes.barColorPrimary }}
      />
      <Box className={classes.triangle} />
    </Box>
  );
}

export default DiagramSB;
