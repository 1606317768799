import { BigNumber } from "ethers";
import { formatUnits } from "utils/number";
import { groupBy, get, omit, map } from "lodash";

export const getLvrFromResult = (results, prjTokenAddress) => {
  const projectTokenInfo = groupBy(
    get(
      groupBy(get(results, ["PITContract"]).callsReturnContext, "methodName"),
      "projectTokenInfo"
    ),
    "reference"
  );

  const lvrNumerator = get(projectTokenInfo, [prjTokenAddress, 0, "returnValues", 3, 0], 0);
  const lvrDenominator = get(projectTokenInfo, [prjTokenAddress, 0, "returnValues", 3, 1], 1);

  return lvrNumerator / (lvrDenominator || 1);
};

export const handleGetPriceInUsd = (results, key) => {
  const priceResults = groupBy(get(results, ["PriceContract"]).callsReturnContext, "reference");
  const priceTokenBN = get(priceResults, [key, 0, "returnValues", 0], 1);
  const priceDecimal = +get(priceResults, [key, 0, "returnValues", 1], 0);
  return {
    priceTokenBN,
    priceDecimal,
  };
};

export const converUSDToToken = (amountUSDBN, tokenAmountBN, tokenEvoluationBN) => {
  const tokenAmount = amountUSDBN.mul(tokenAmountBN).div(tokenEvoluationBN);
  return tokenAmount;
};

export const getTotalDepositPerToken = (results, prjTokenAddress) => {
  const projectTokenInfo = groupBy(
    get(
      groupBy(get(results, ["PITContract"]).callsReturnContext, "methodName"),
      "totalDepositedPerProjectToken"
    ),
    "reference"
  );

  const totalDepositToken = get(projectTokenInfo, [prjTokenAddress, 0, "returnValues", 0]);

  return BigNumber.from(totalDepositToken);
};

export const handleGetDepositTokens = ({ results, listToken, tokenEvoluationList }) => {
  const list = [];
  const PriceContract = groupBy(
    get(results, ["PriceContract", "callsReturnContext"]).filter(
      (i) => !i.reference.includes("getPrice")
    ),
    "reference"
  );

  const myDepositedPerProjectTokenInUSD = groupBy(
    get(
      groupBy(get(results, ["PITContract"]).callsReturnContext, "methodName"),
      "getDepositedPerProjectTokenInUSD"
    ),
    "reference"
  );

  const amountDepositLimitList = groupBy(
    get(
      groupBy(get(results, ["PITContract"]).callsReturnContext, "methodName"),
      "depositLimitPerProjectToken"
    ),
    "reference"
  );

  map(omit(results, ["PriceContract", "PITContract"]), (value, key) => {
    const tokenInfo = listToken.find((o) => o.address === key);
    if (!tokenInfo) return;

    const group = groupBy(value.callsReturnContext, (x) => x.reference);

    const decimal = +get(group, ["decimal", 0, "returnValues"], "0");

    const allowance = get(group, ["isAllowanceForPIT", 0, "returnValues"], "0");

    const balanceBN = get(group, ["balanceOfWallet", 0, "returnValues"], "0");
    const balanceOfWallet = formatUnits(balanceBN, decimal);

    const [priceTokenBN, priceDecimal] = get(PriceContract, [key, 0, "returnValues"]);

    const balanceInUsd = formatUnits(
      BigNumber.from(balanceBN).mul(priceTokenBN),
      priceDecimal + decimal
    );

    const lvr = getLvrFromResult(results, key);

    const limitDeposited = BigNumber.from(get(amountDepositLimitList, [key, 0, "returnValues", 0]));
    const tokenAmountBN = BigNumber.from(get(tokenEvoluationList, [key, "tokenAmount"]));
    const tokenEvoluationBN = BigNumber.from(get(tokenEvoluationList, [key, "tokenEvaluation"]));

    const supllyCap = converUSDToToken(limitDeposited, tokenAmountBN, tokenEvoluationBN);

    const depositedTokenUSDBN = BigNumber.from(
      get(myDepositedPerProjectTokenInUSD, [key, 0, "returnValues", 0])
    );

    const depositedToken = converUSDToToken(depositedTokenUSDBN, tokenAmountBN, tokenEvoluationBN);

    const totalDepositPerToken = getTotalDepositPerToken(results, key);
    const remaining = supllyCap.sub(totalDepositPerToken);

    list.push({
      ...tokenInfo,
      balance: balanceOfWallet,
      allowance,
      healthFactor: 5.999994000006,
      lvr,
      supllyCap: formatUnits(supllyCap, decimal),
      depositedToken,
      totalDepositPerToken: formatUnits(totalDepositPerToken, decimal),
      remaining: formatUnits(remaining, decimal),
      price: balanceInUsd,
    });
  });

  return list;
};
