import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import { ReactComponent as ArrowUpIcon } from "assets/svg/arrow-up.svg";

import { NumericText } from "components";
import { getPercentValueStyles } from "helpers/styles";

const useStyles = makeStyles((theme) => ({
  percentText: (props) => {
    const { color, rotateDeg } = getPercentValueStyles(props.value, theme);

    return {
      display: "flex",
      alignItems: "center",
      color,

      "& svg": {
        transform: `rotate(${rotateDeg}deg)`,
      },

      "& rect": {
        fill: color,
      },
    };
  },
  chip: (props) => {
    const { color, rotateDeg } = getPercentValueStyles(props.value, theme);

    return {
      marginLeft: theme.spacing(1),

      backgroundColor: color,
      color: theme.palette.common.white,
      borderRadius: 10,
      fontSize: theme.typography.body1.fontSize,

      "& svg": {
        transform: `rotate(${rotateDeg}deg)`,
      },
    };
  },
  chipLabel: {
    paddingLeft: theme.spacing(1),
  },
}));

const PercentText = ({ value, variant, icon, suffix, prefix }) => {
  const classes = useStyles({ value });

  const suffixValue = suffix ? "%" : "";

  const text = <NumericText value={value} suffix={suffixValue} />;

  if (variant === "contained") {
    return (
      <Chip
        label={text}
        component="span"
        className={classes.chip}
        avatar={<ArrowUpIcon />}
        classes={{
          label: classes.chipLabel,
        }}
      />
    );
  }

  return (
    <span className={classes.percentText}>
      {icon && <ArrowUpIcon />}

      {prefix && value >= 0 && <>+</>}

      {text}
    </span>
  );
};

PercentText.propTypes = {
  value: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(["outlined", "contained"]),
  icon: PropTypes.bool,
  prefix: PropTypes.bool,
  suffix: PropTypes.bool,
};

PercentText.defaultProps = {
  variant: "outlined",
  icon: true,
  prefix: false,
  suffix: true,
};

export default PercentText;
