import { Box, Grid, makeStyles } from "@material-ui/core";
import { MarginSlider } from "components/Leveraged/MarginSlider";
import { TitleLeverage } from "components/Leveraged/TitleLeverage";
import { Text } from "components/Title/Text";
import { useAmplifyContext } from "context/InstantsLeverage/AmplifyContext/useAmplifyContext";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import React from "react";

const useStyles = makeStyles(() => ({
  BoxStyle: {
    marginTop: "18px",
  },
  RulerStyle: {
    paddingLeft: "3%",
    paddingRight: "8px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    marginTop: "10px",
    "@media(max-width:576px)": {
      width: "55%",
    },
  },
  NumberAmplify: {
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:1200px)": {
      justifyContent: "space-around",
      paddingRight: "40px",
    },
    "@media(max-width:576px)": {
      paddingRight: "0",
      // width: "50%",
      // marginTop: "18px",
    },
  },
  TitleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
}));

const withAmplification = (Component) => {
  const MemoComponent = React.memo(Component);

  return () => {
    const {
      maxAmplification,
      amplification: [amplification],
    } = useLeverageContext();
    const { setAmplification } = useAmplifyContext();

    return (
      <MemoComponent
        maxAmplification={maxAmplification}
        amplification={amplification}
        setAmplification={setAmplification}
      />
    );
  };
};

const AmplificationUI = ({ maxAmplification, amplification, setAmplification }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.BoxStyle}>
      <Grid item xs={12} sm={7} md={12} className={classes.TitleRow}>
        <TitleLeverage
          title="Amplification"
          helptext="The amplification you wish to apply to your selected Margin. From 1 through to the maximum permitted for this collateral type. (Available funds in the lending pool may limit the maximum amplification.)"
        />
        <Box className={classes.NumberAmplify}>
          <Text>{amplification.toFixed(2)}x</Text>
        </Box>
        <Box className={classes.NumberAmplify}>
          <Text>(Max {(+maxAmplification).toFixed(2)})</Text>
        </Box>
      </Grid>
      <Grid item xs={12} sm={7} md={12} className={classes.RulerStyle}>
        <MarginSlider
          maxValue={+maxAmplification}
          setValue={setAmplification}
          value={amplification}
        />
      </Grid>
    </Grid>
  );
};

export const Amplification = withAmplification(AmplificationUI);
