import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

import { PaperTitle, NumericText, PercentText } from "components";

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  title: {
    borderBottom: `1px solid ${theme.palette.info.main}`,
  },

  price: {
    marginRight: 15,
    fontSize: 18,
    fontWeight: "600",
  },

  overviewFooter: {
    borderTop: `1px solid ${theme.palette.info.main}`,
  },

  overviewTotalText: {
    fontWeight: 500,
  },

  percentText: {
    color: theme.palette.success.main,
    fontWeight: 600,
    fontSize: 14,
  },
}));

const TotalInfoPaper = ({ title, value, volume, loan, children }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paperRoot}>
      <Box pb={2} className={classes.title}>
        <PaperTitle>Total {title}</PaperTitle>
      </Box>
      <Box mt={3}>
        <Grid container alignItems="flex-end">
          <Grid item>
            <Typography className={classes.price}>
              <NumericText value={value} moneyValue />
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.percentText}>
              <PercentText value={1.18} icon={false} prefix />
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box>{children}</Box>

      <Box mt="auto" pt={2} className={classes.overviewFooter}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography color="primary">24H Supply Volume</Typography>
          </Grid>

          <Grid item>
            <Typography color="primary">#Of Suppliers</Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.overviewTotalText}>
              <NumericText value={volume} moneyValue />
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={classes.overviewTotalText}>
              <NumericText value={loan} moneyValue />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default TotalInfoPaper;
