import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import { makeStyles } from "@material-ui/core/styles";
import { Input, Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    paddingTop: 0,
    backgroundColor: "transparent",
    color: theme.palette.success.main,

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.success.main,
    },
  },

  buttonLabel: {
    borderBottom: `1px solid ${theme.palette.success.main}`,
    transition: theme.transitions.main,
    textTransform: "uppercase",

    "&:hover": {
      borderBottom: "1px solid transparent",
      transition: theme.transitions.main,
    },
  },
}));

const NumberFormatCustom = (props) => {
  const { inputRef, ...numberFormatProps } = props;

  return (
    <>
      <NumberFormat
        {...numberFormatProps}
        getInputRef={inputRef}
        isNumericString
        allowNegative={false}
        allowLeadingZeros={false}
      />
    </>
  );
};

const NumericTextField = ({
  value,
  onChange,
  maxValue,
  buttonText,
  placeholder,
  decimalScale,
  disabled = false,
}) => {
  const classes = useStyles();

  const onValueChange = (inputValue) => {
    if (!+inputValue || !inputValue.length) onChange("");
    else onChange(+inputValue);
  };

  const onButtonClick = () => {
    onChange(maxValue);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Input
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => onValueChange(e.target.value)}
        inputComponent={NumberFormatCustom}
        inputProps={{
          placeholder,
          decimalScale,
        }}
      />
      {!!maxValue && (
        <Button
          onClick={onButtonClick}
          classes={{ root: classes.button, label: classes.buttonLabel }}
        >
          {buttonText}
        </Button>
      )}
    </Grid>
  );
};

NumericTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  buttonText: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  decimalScale: PropTypes.number,
};

NumericTextField.defaultProps = {
  maxValue: 0,
  buttonText: "Max",
  placeholder: "0",
};

export default NumericTextField;
