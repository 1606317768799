import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { withWalletLoader } from "HOCFunction";
import { useState } from "react";
import MetaTags from "react-meta-tags";
import metaImg from "assets/meta-img.png";
import { ChartContext } from "./ChartContext";
import { MarketCharts } from "./components";
import SelectAsset from "./components/SelectAsset";
import { CHARTS, DEFAULT_INTERVAL_VALUE } from "./constants";

const IndexMarket = () => {
  const [lendingToken, setLendingToken] = useState(null);
  const [selectInterval, setSelectedInterval] = useState(DEFAULT_INTERVAL_VALUE);
  const [activeTab, setActiveTab] = useState(CHARTS[0]);
  const [actLines, setActiveLines] = useState([activeTab]);

  const useStyles = makeStyles((theme) => ({
    titleBox: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",
      color: "#FFFFFF",
      fontSize: "18px",
      textAlign: "center",
      marginBottom: "18px",
      alignItems: "center",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  }));
  const classes = useStyles();
  return (
    <ChartContext.Provider
      value={{
        lendingToken,
        setLendingToken,
        selectInterval,
        setSelectedInterval,
        actLines,
        setActiveLines,
        activeTab,
        setActiveTab,
      }}
    >
      <Container maxWidth="xl">
        <MetaTags>
          <title>Markets overview: Lending and Borrowing APY, TVL | Fringe Finance</title>
          <meta
            name="description"
            content="Real-time market data across all markets on Fringe Finance."
          />
          <meta property="og:title" content="Markets overview: Lending and Borrowing APY, TVL." />
          <meta
            property="og:description"
            content="Real-time market data across all markets on Fringe Finance."
          />
          <meta property="og:image" content={metaImg} />
        </MetaTags>

        <Box className={classes.titleBox}>
          <Box minWidth="240px" />
          <Box>
            <h1>Markets overview</h1>
          </Box>
          <SelectAsset />
        </Box>
        <Box mt={2}>
          <MarketCharts />

          {/* <Box my={3}>
            <IndexMarketTables />
          </Box> */}
        </Box>
      </Container>
    </ChartContext.Provider>
  );
};

export default withWalletLoader(IndexMarket);
