import BigNumber from "bignumber.js";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import { useLendingAssetContext } from "context/tokens/BorrowTokenContext";
import useAllBorrowData from "hooks/contexts/BorrowContext/useAllBorrowData";
import { useDepositedAssetStates } from "hooks/contexts/BorrowContext/useDepositedAssetStates";
import { get } from "lodash";
import numeral from "numeral";
import { useMemo } from "react";

export const useLeveragePositionData = (leverageType) => {
  const [depositedAssetList] = useDepositedAssetStates();
  const { laveragedBorrowList } = useLeverageContext();
  const { lendingTokensObj, collateralTokenObj } = useAllBorrowData();
  const { data: lendingCtx } = useLendingAssetContext();

  const positionData = useMemo(() => {
    let totalExposureBN = new BigNumber(0);
    let totalEquityBN = new BigNumber(0);
    let totalProfitBN = new BigNumber(0);

    const positionList = depositedAssetList
      .filter((x) => x.isLeverage && x.leverageType === leverageType)
      .map((depositedAsset) => {
        const currentPrice = depositedAsset?.data?.price;

        const laveragedBorrow = laveragedBorrowList.find(
          (x) => x?.prjTokenAddress === depositedAsset?.address
        );

        // Liquidation Price
        const longAsset = collateralTokenObj[depositedAsset?.address]?.[0];
        const longAssetAmount = depositedAsset?.prjAmount;
        const longLvr = get(longAsset, "lvr", 0);
        const liquidationPrice = new BigNumber(laveragedBorrow?.exposureLendingAmount)
          .multipliedBy(laveragedBorrow?.lendingTokenPrice)
          .multipliedBy(longLvr)
          .dividedBy(longAssetAmount);

        const shortAsset = lendingTokensObj[depositedAsset?.lendingToken?.address]?.[0];

        // Safety Buffer
        const safetyBuffer = new BigNumber(longAssetAmount)
          .dividedBy(new BigNumber(laveragedBorrow?.exposureAmount).dividedBy(longLvr))
          .minus(1)
          .multipliedBy(100);

        // APY
        const borrowApy = get(shortAsset, "borrowApy", 0);

        // Exposure
        const exposure = new BigNumber(laveragedBorrow?.exposureAmount).plus(
          laveragedBorrow?.marginAmount
        );
        const exposureUSD = new BigNumber(exposure)
          .multipliedBy(laveragedBorrow?.prjTokenPrice)
          .toString();

        // Equity
        const lendingTokenPrice = get(lendingCtx, ["priceLendingToken"], [])?.find(
          (o) => o.lendingToken === shortAsset?.address
        )?.price;
        const shortAssetCurrentValue = new BigNumber(lendingTokenPrice).multipliedBy(
          laveragedBorrow?.exposureLendingAmount
        );
        const longAssetCurrentValue = new BigNumber(depositedAsset?.data?.price).multipliedBy(
          longAssetAmount
        );
        const equityUSD = new BigNumber(longAssetCurrentValue).minus(shortAssetCurrentValue);
        const equity = new BigNumber(equityUSD).dividedBy(depositedAsset?.data?.price);

        // Profit/Loss
        const originalMarginUSD = new BigNumber(laveragedBorrow?.marginAmount).multipliedBy(
          laveragedBorrow?.prjTokenPrice
        );
        const profitUSD = new BigNumber(equityUSD).minus(originalMarginUSD);
        const profit = new BigNumber(profitUSD).dividedBy(depositedAsset?.data?.price);

        // Total data
        totalExposureBN = totalExposureBN.plus(exposureUSD);
        totalEquityBN = totalEquityBN.plus(equityUSD);
        totalProfitBN = totalProfitBN.plus(profitUSD);

        return {
          ...depositedAsset,
          longAsset: depositedAsset?.logo,
          shortAsset: depositedAsset?.lendingToken?.logo,
          pair: `${longAsset?.symbol}/${shortAsset?.symbol}`,
          decimal: longAsset?.decimal,
          asset: depositedAsset?.symbol,
          currentPrice: `$${numeral(currentPrice).format("0,0.[00]")}`,
          liquidationPrice: `$${numeral(liquidationPrice).format("0,0.[00]")}`,
          safetyBuffer: `${numeral(safetyBuffer || 0).format("0,0.[00]")}%`,
          apy: numeral(borrowApy).format("0,0.[0000]%"),
          exposureUSD: `$${numeral(exposureUSD).format("0,0.[00]")}`,
          exposure: `${numeral(exposure).format("0,0.[0000]")} ${depositedAsset?.symbol}`,
          equityUSD: `$${numeral(equityUSD).format("0,0.[00]")}`,
          equity: `${numeral(equity).format("0,0.[0000]")} ${depositedAsset?.symbol}`,
          profitUSD: `$${numeral(profitUSD).format("0,0.[00]")}`,
          profit: `${numeral(profit).format("0,0.[0000]")} ${depositedAsset?.symbol}`,
        };
      });
    return {
      positionList,
      totalExposure: `$${numeral(totalExposureBN.toString()).format("0,0.[00]")}`,
      totalEquity: `$${numeral(totalEquityBN.toString()).format("0,0.[00]")}`,
      totalProfit: `$${numeral(totalProfitBN.toString()).format("0,0.[00]")}`,
    };
  }, [
    depositedAssetList,
    leverageType,
    laveragedBorrowList,
    collateralTokenObj,
    lendingTokensObj,
    lendingCtx,
  ]);

  return positionData;
};
