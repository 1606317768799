import { get } from "lodash";

export function handleEvaluation(evaluationList) {
  const result = {};
  evaluationList.forEach((o) => {
    const key = get(o, ["methodParameters", 0]);
    const tokenAmount = get(o, ["methodParameters", 1]);
    const evaluation = get(o, ["returnValues", 0], 0);
    result[key] = {
      evaluation,
      tokenAmount,
    };
  });
  return result;
}
