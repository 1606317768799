import { Button, Typography, makeStyles } from "@material-ui/core";
import { Box, Dialog } from "@mui/material";
import {
  CoinInfo,
  DepositModal,
  NumericText,
  Table,
  TableSkeleton,
  WithdrawModal,
} from "components";
import { IconInfo } from "components/Leveraged/IconInfo";
import { Text } from "components/Title/Text";
import { logoBySymbol } from "constants/TokenListGoerliNetwork";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { useModalState, useWallet } from "hooks";
import { useDepositedAssetStates } from "hooks/contexts/BorrowContext/useDepositedAssetStates";
import { get } from "lodash";
import numeral from "numeral";
import React, { useCallback, useMemo } from "react";
import ReactGa from "react-ga";
import useWindowDimensions from "pages/BorrowerNewDashboard/hooks/useWindowDimensions";
import { useCommonStyle } from "./commonStyle";

const MARGIN_VALUE = 16;

const useStyles = makeStyles((theme) => ({
  container: ({ noMargin }) => ({
    border: "1px solid #434849",
    margin: noMargin ? 0 : `0 ${MARGIN_VALUE}px ${MARGIN_VALUE}px 0`,

    "& .MuiTableContainer-root": {
      maxHeight: "380px",

      [theme.breakpoints.down("sm")]: {
        maxHeight: "unset",
      },
    },

    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#191919",
    },

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  }),
  title: {
    background: "#191919",
    padding: "8px 8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#FFF",

    "& p": {
      margin: 0,
    },
  },

  TableStyle: {
    "& .MuiTableCell-root": {
      borderBottom: "#3f4445 1px solid",
      fontSize: "14px",
      textWrap: "nowrap",
    },
    "& .icon-style": {
      width: "20px",
      position: "absolute",
      right: 0,
      bottom: "31%",
    },

    [theme.breakpoints.down("sm")]: {
      "& .MuiTableCell-root": {
        fontSize: "12px",
      },
    },
  },

  EmptyDataText: {
    textAlign: "center",
    fontSize: "1.5rem",
    marginTop: "1.5rem",
    color: "#fff",
  },

  actionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.5),
    justifyContent: "end",
  },

  numericText: {
    textWrap: "nowrap",
  },
}));

function MyCollateral({ setHeightState, noMargin = false }) {
  const [depositedAssetStates] = useDepositedAssetStates();
  const { isLoading, decimalOfContractToken } = useBorrowContext();
  const windowDimension = useWindowDimensions();

  const classes = useStyles({ noMargin });
  const commonStyle = useCommonStyle();

  const { account } = useWallet();
  const [modalDataIdx, setModalDataIdx] = React.useState(null);
  const {
    isOpen: isOpenDepositModal,
    onOpen: onOpenDepositModal,
    onClose: onCloseDepositModal,
  } = useModalState();
  const {
    isOpen: isOpenWithdrawModal,
    onOpen: onOpenWithdrawModal,
    onClose: onCloseWithdrawModal,
  } = useModalState();

  const handleOpenWithdrawModal = useCallback(
    (value) => () => {
      setModalDataIdx(value);
      onOpenWithdrawModal();

      ReactGa.event({
        category: "Borrower Dashboard",
        action: "Deposit",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onOpenWithdrawModal]
  );

  const handleOpenDepositModal = useCallback(
    (value) => () => {
      setModalDataIdx(value);
      onOpenDepositModal();

      ReactGa.event({
        category: "Borrower Dashboard",
        action: "Deposit",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onOpenDepositModal]
  );

  const COLUMNS = [
    {
      Header: <Text>Asset</Text>,
      accessor: "asset",
      Cell: ({
        // eslint-disable-next-line no-unused-vars
        value,
        row: {
          original: { logo, underlyingTokens, symbol },
        },
      }) => (
        <CoinInfo
          logoUrl={underlyingTokens.length ? "" : logo ?? "/assets/coins_list/pjToken.png"}
          isLPToken={underlyingTokens.length > 0}
          logoLPOne={underlyingTokens.length ? logoBySymbol[underlyingTokens[0].symbol] : ""}
          logoLPTwo={underlyingTokens.length ? logoBySymbol[underlyingTokens[1].symbol] : ""}
        >
          {underlyingTokens.length
            ? `${underlyingTokens[0].symbol}/${underlyingTokens[1].symbol}`
            : symbol}
        </CoinInfo>
      ),
    },
    {
      Header: (
        <Box display="flex" gap={1}>
          <Text>LVR</Text>
          <IconInfo title="Loan to value ratio for the collateral asset. The maximum borrowing capacity for a given collateral deposit = collateral value * LVR. Each collateral asset has its own LVR." />
        </Box>
      ),
      accessor: "LVR",
      props: {
        align: "center",
      },
      Cell: ({
        row: {
          original: { lvr },
        },
      }) => <Typography color="secondary">{numeral(lvr).format("0,0%")}</Typography>,
    },
    {
      Header: (
        <Box>
          <Text>Collateral </Text>
          <Text>balance</Text>
        </Box>
      ),
      accessor: "collateralBalance",
      Cell: ({
        row: {
          original: {
            data: { collateralBalance },
          },
        },
      }) => {
        if (!collateralBalance) return null;

        const [depositedAmount, symbol, balance] = collateralBalance;

        return (
          <>
            <Typography className={classes.numericText}>
              <NumericText value={depositedAmount} suffix={symbol} precision={2} />
            </Typography>
            <Typography className={classes.numericText}>
              <NumericText value={balance?.rounded} precision={2} moneyValue />
            </Typography>
          </>
        );
      },
    },
  ];

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        Header: "",
        accessor: "button",
        Cell: ({ row: { index } }) => (
          <Box className={classes.actionBox}>
            <Button
              className={commonStyle.button}
              onClick={handleOpenWithdrawModal(index.toString())}
            >
              Withdraw
            </Button>
            <Button
              className={commonStyle.button}
              onClick={handleOpenDepositModal(index.toString())}
            >
              Deposit
            </Button>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const totalBalance = useMemo(() => {
    const total = depositedAssetStates.reduce((pre, cur) => {
      const balance = get(cur, ["data", "collateralBalance", 2, "rounded"]);
      return pre + balance;
    }, 0);
    return total;
  }, [depositedAssetStates]);

  const depositModalData = useMemo(() => {
    const deposit = get(depositedAssetStates, modalDataIdx, depositedAssetStates?.[0]);
    return {
      ...deposit,
      decimal: get(decimalOfContractToken, [deposit?.address?.toLowerCase()], 0),
    };
  }, [depositedAssetStates, decimalOfContractToken, modalDataIdx]);

  const withdrawModalData = useMemo(() => {
    const deposit = get(depositedAssetStates, modalDataIdx, depositedAssetStates?.[0]);
    return deposit;
  }, [depositedAssetStates, modalDataIdx]);

  const handleSetRef = useCallback(
    (node) => {
      if (!setHeightState) return;
      if (node) setHeightState(node?.getBoundingClientRect()?.height + MARGIN_VALUE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowDimension]
  );

  return (
    <Box
      className={`${classes.container} ${commonStyle.containerBackground}`}
      ref={(node) => handleSetRef(node)}
    >
      <Box className={classes.title}>
        <p style={{ fontSize: "18px", paddingLeft: "16px" }}>Your collateral</p>
        <p style={{ fontSize: "14px" }}>
          Balance: <NumericText value={totalBalance} precision={2} moneyValue />
        </p>
      </Box>
      {isLoading && depositedAssetStates.length === 0 ? (
        <TableSkeleton rows={3} />
      ) : (
        account &&
        depositedAssetStates.length > 0 && (
          <Box sx={{ overflowY: "scroll" }}>
            <Table stickyHeader columns={columns} data={depositedAssetStates} />
            <Dialog open={isOpenDepositModal} onClose={onCloseDepositModal} noPadding>
              <DepositModal data={depositModalData} onClose={onCloseDepositModal} />
            </Dialog>
            <Dialog open={isOpenWithdrawModal} onClose={onCloseWithdrawModal} noPadding>
              <WithdrawModal data={withdrawModalData} onClose={onCloseWithdrawModal} />
            </Dialog>
          </Box>
        )
      )}

      {!isLoading && depositedAssetStates?.length <= 0 && (
        <p className={classes.EmptyDataText}>You have no deposited collateral</p>
      )}
    </Box>
  );
}

export default MyCollateral;
