import { get, pick } from "lodash";
import { useContext } from "react";

import { LeverageContext } from "./LeverageContext";

export const useLeverageContext = () => {
  const ctx = useContext(LeverageContext);

  return {
    longAssetSelected: get(ctx, ["longAssetSelected"]),
    shortAssetSelected: get(ctx, "shortAssetSelected"),
    collateralList: get(ctx, "collateralList", []),
    lendingList: get(ctx, "lendingList", []),
    maxAmplification: get(ctx, "maxAmplification", 0),
    maxMargin: get(ctx, "maxMargin", 0),
    minMargin: get(ctx, "minMargin"),
    safeBuffer: get(ctx, ["safeBuffer"], 0),
    notionalExp: get(ctx, ["notionalExp"], "0"),
    ...pick(ctx, [
      "hideZero",
      "margin",
      "amplification",
      "estDayLiquidation",
      "isMarginInvalid",
      "resetStates",
      "resetForm",
      "laveragedBorrowList",
      "refetchLaveragedBorrowList",
      "setLongAssetAddress",
      "setShortAssetAddress",
      "setNotionalExp",
      "setSafeBuffer",
      "exposureLimit",
    ]),
  };
};
