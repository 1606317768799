import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/material";
import { CoinInfo } from "components/CoinInfo";
import _get from "lodash/get";
import { useMemo } from "react";
import { RepayCollateral, SelectCollateral } from ".";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 350,
    paddingTop: 0,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },

  dialogTitle: {
    padding: 0,
    margin: 0,
    marginBottom: 16,
    color: "#000",
    fontSize: 16,
  },

  coinLogo: {},
}));

function SelectCollateralModal({
  onClose,
  setSelectedAsset,
  selectedAsset,
  lendingAsset,
  onCloseRepayModal,
}) {
  const classes = useStyles();

  const [logoLendingToken, lendingTokenName] = useMemo(
    () => [
      _get(lendingAsset, ["logo"], "./assets/coins_list/usd-coin.svg"),
      _get(lendingAsset, ["name"], ""),
      _get(lendingAsset, ["symbol"], ""),
    ],
    [lendingAsset]
  );

  return (
    <Box className={classes.rootContainer}>
      <p className={classes.dialogTitle}>Select collateral to repay</p>
      <SelectCollateral
        setSelectedAsset={setSelectedAsset}
        selectedAsset={selectedAsset}
        lendingAsset={lendingAsset}
      />

      {selectedAsset && (
        <Box marginTop={4}>
          <Box className={classes.coinLogo} marginBottom={1}>
            <CoinInfo logoUrl={logoLendingToken} size="large">
              {lendingTokenName}
            </CoinInfo>
          </Box>
          <Box>
            <RepayCollateral
              onCloseRepayModal={onCloseRepayModal}
              onClose={onClose}
              lendingAsset={lendingAsset}
              selectedAsset={selectedAsset}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SelectCollateralModal;
