import theme from "../theme";

const MuiDrawer = {
  props: {
    anchor: "right",
    variant: "persistent",
  },
  overrides: {
    paper: {
      top: "100px",
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        top: "80px",
      },
    },
  },
};

export default MuiDrawer;
