export const CHAINID_NETWORK = {
  arbitrum: process.env.REACT_APP_CHAIN_ID_ARBITRUM,
  optimism: process.env.REACT_APP_CHAIN_ID_OPTIMISM,
  polygon: process.env.REACT_APP_CHAIN_ID_POLYGON,
  ethereum: process.env.REACT_APP_CHAIN_ID_ETHEREUM,
  zkSync: process.env.REACT_APP_CHAIN_ID_ZKSYNC,
};

export const CHAINID_WITH_API = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_API_URL_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_API_URL_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_API_URL_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_API_URL_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_API_URL_ZKSYNC,
};

// Address of contract hooks

export const PRIMARY_INDEX_TOKEN = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_ZKSYNC,
};

export const BUSDC_CONTRACT = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_SC_BUSDC_ADDRESS,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_SC_BUSDC_ADDRESS_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_SC_BUSDC_ADDRESS_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_SC_BUSDC_ADDRESS_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_SC_BUSDC_ADDRESS_ZKSYNC,
};

export const PRICE_PROVIDER_AGGREGATOR_CONTRACT = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_ZKSYNC,
};

export const LENDING_TOKEN_ADDRESS = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ZKSYNC,
};

export const LENDING_USDC_TOKEN_ADDRESS = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ZKSYNC,
};

export const ATOMIC_REPAYMENT_CONTRACT = {
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_ETHEREUM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_ZKSYNC,
};

export const REACT_APP_UNISWAPV2_FACTORY_ETHEREUM = {
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_UNISWAPV2_FACTORY_ETHEREUM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_UNISWAPV2_FACTORY_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_UNISWAPV2_FACTORY_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_UNISWAPV2_FACTORY_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_UNISWAPV2_FACTORY_ZKSYNC,
};

export const REACT_APP_UNISWAPV2_ROUTER = {
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_UNISWAPV2_ROUTER_ETHEREUM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_UNISWAPV2_ROUTER_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_UNISWAPV2_ROUTER_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_UNISWAPV2_ROUTER_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_UNISWAPV2_ROUTER_ZKSYNC,
};

export const REACT_APP_INSTANT_LEVERAGE = {
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_INSTANT_LEVERAGE_ETHEREUM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_INSTANT_LEVERAGE_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_INSTANT_LEVERAGE_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_INSTANT_LEVERAGE_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_INSTANT_LEVERAGE_ZKSYNC,
};

export const REACT_APP_MULTICALL = {
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_MULTICALL_ZKSYNC,
};

export const LENDING_TOKEN_LIST = [
  {
    ftoken: "0x26868261FB06113cd347A33799Bf8E643c56734f",
    token: "0xccc70874548F12320DB34b1f36343C5afc5484e4",
    logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    name: "USDC",
  },
  {
    ftoken: "0xcEC6943aC4462FBFCc9AD0Be6B1391e2B3b17422",
    token: "0xFee9CFE582C501234C8ac18b01EA3F3199f4efB8",
    logo: "/assets/images/usb_token.png",
    name: "USB",
  },
];
