export const getPercentValueStyles = (value, theme) => {
  const isPositivePercent = value >= 0;
  const color = isPositivePercent
    ? theme.palette.success.light
    : theme.palette.error.main;
  const rotateDeg = isPositivePercent ? 0 : 180;

  return {
    color,
    rotateDeg,
  };
};

const HEALTH_FACTOR_MIN_VALUE = 1;
const HEALTH_FACTOR_MAX_VALUE = 3;

export const normaliseHealthValue = (value) => {
  const formattedValue =
    ((value - HEALTH_FACTOR_MIN_VALUE) * 100) /
    (HEALTH_FACTOR_MAX_VALUE - HEALTH_FACTOR_MIN_VALUE);

  // need for revert negative health factor
  if (formattedValue < 0) {
    return 1;
  }

  return formattedValue;
};

export const getHealthColor = (value) => {
  if (value >= 0 && value < 15) {
    return "#FF1D1D";
  }
  if (value > 15 && value < 30) {
    return "#FF5413";
  }
  if (value > 30 && value < 45) {
    return "#FE7D0D";
  }
  if (value > 45 && value < 60) {
    return "#E8B502";
  }
  if (value > 60 && value < 75) {
    return "#AAC021";
  }
  if (value > 75 && value < 90) {
    return "#65DD4A";
  }
  return "#21E868";
};
