import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { MIN_AMPLIFY } from "constants/contract";

const MarginSliderStyle = styled(Slider)(({ theme }) => ({
  color: "transparent",
  height: "20px",
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#0880ae",
    top: "50%",

    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
    },
  },
  "& .MuiSlider-markLabel": {
    color: "#fff",
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: 2,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#fff",
    width: "107%",
    right: "-10px",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "transparent",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
  "& .MuiSlider-valueLabelLabel": {
    color: "#fff",
  },
}));

export const MarginSlider = ({ setValue, maxValue = 1.5, value, disabled }) => (
  <MarginSliderStyle
    aria-label="ios slider"
    value={value}
    step={0.01}
    max={+maxValue - 0.01}
    min={MIN_AMPLIFY}
    valueLabelDisplay="on"
    onChange={(e) => setValue(e.target.value)}
    disabled={disabled}
  />
);
