export const lenderFakeData = [
  {
    symbol: "USDC",
    borrowAPY: {
      amount: 0,
    },
    lendingAPY: {
      amount: 0,
    },
    balance: "0",
    balanceInUsd: "0",
    balanceOfUnderlyingView: "0",
    logo: "/assets/coins_list/usd-coin.svg",
    priceInfo: {
      price: "0",
    },
    totalSupply: 0,
    totalSupplyInUsd: 0,
  },
  {
    symbol: "USB",
    borrowAPY: {
      amount: 0,
    },
    lendingAPY: {
      amount: 0,
    },
    balance: "0",
    balanceInUsd: "0",
    balanceOfUnderlyingView: "0",
    logo: "/assets/images/usb_token.png",
    priceInfo: {
      price: "0",
    },
    totalSupply: 0,
    totalSupplyInUsd: 0,
  },
];
