// import { formatUnits } from "utils/number";
import { useWallet } from "hooks";
import { useMultiCallContractInstance } from "hooks/contract/multicall/useMultiCallContract";
import { useCheckLeveragePositions, useGetLeverageTypes } from "hooks/contract/useLeverageContract";
import { get, groupBy, map, omit } from "lodash";
import { useQuery } from "react-query";
import { ERC20TokenABI } from "utils/ethereum/abi";
import { getPriceProviderAggregatorContract, getPrimaryIndexToken } from "utils/ethereum/contracts";
import { formatUnits } from "utils/number";
import BNjs from "bignumber.js";

import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import { BigNumber } from "ethers";
import {
  getLvrFromResult,
  handleGetDepositTokens,
  handleGetPriceInUsd,
} from "./helper/getDataContract";
import { useGetTokens } from "./useTokenSupported";

const localChainId = localStorage.getItem("chainId");

const useAvailableMultiCall = () => {
  const { projectTokenList: collaterals, availableBorrowTokens } = useGetTokens();

  const { chainId, account } = useWallet();
  const { APY } = useFetchGraphData();

  const multiCallContract = useMultiCallContractInstance();

  const { checkLeveragePosition } = useCheckLeveragePositions(
    [...collaterals].map((o) => o?.address)
  );

  const { getLeverageTypes } = useGetLeverageTypes([...collaterals].map((o) => o?.address));

  return useQuery(
    ["available-multicall", account, collaterals, chainId],
    async () => {
      const { data: leveragePositionMap } = await checkLeveragePosition();
      const { data: leverageType } = await getLeverageTypes();

      const listToken = [...collaterals];

      const PITToken = getPrimaryIndexToken(chainId ?? localChainId);

      /* A list of requests to token contract. */
      const listRequestToTokenContracts = [];

      /* A list of requests to PIT contract. */
      const listRequestToPITContract = {
        contractAddress: PITToken.address,
        abi: PITToken.abi,
        reference: "PITContract",
        calls: [
          {
            methodParameters: [],
            methodName: "decimals",
            reference: `decimal`,
          },
        ],
      };

      const PriceContract = getPriceProviderAggregatorContract(chainId ?? localChainId);
      /* A list of requests to Price contract. */
      const listRequestToPriceContract = {
        contractAddress: PriceContract.address,
        abi: PriceContract.abi,
        reference: "PriceContract",
        calls: [],
      };

      availableBorrowTokens.forEach((token) => {
        listRequestToTokenContracts.push({
          contractAddress: token.address,
          abi: ERC20TokenABI,
          reference: token.address,
          calls: [
            {
              methodParameters: [account, PITToken.address],
              methodName: "allowance",
              reference: "isAllowanceForPIT",
            },
            {
              methodParameters: [account],
              methodName: "balanceOf",
              reference: "balanceOfWallet",
            },
            {
              methodParameters: [],
              methodName: "decimals",
              reference: `decimal`,
            },
          ],
        });
        listRequestToPITContract.calls.push({
          reference: `${token.symbol}`,
          methodName: "lendingTokenInfo",
          methodParameters: [token.address],
        });
        listRequestToPriceContract.calls.push({
          methodParameters: [token.address],
          methodName: "getPrice",
          reference: `getPrice-${token.address}`,
        });
      });

      listToken.forEach((token) => {
        listRequestToPITContract.calls.push(
          {
            reference: `${token.address}`,
            methodName: "projectTokenInfo",
            methodParameters: [token.address],
          },
          {
            reference: `${token.address}`,
            methodName: "depositLimitPerProjectToken",
            methodParameters: [token.address],
          },
          {
            reference: `${token.address}`,
            methodName: "getDepositedPerProjectTokenInUSD",
            methodParameters: [token.address],
          },
          {
            reference: `${token.address}`,
            methodName: "totalDepositedPerProjectToken",
            methodParameters: [token.address],
          }
        );

        listRequestToPriceContract.calls.push({
          methodName: "getPrice",
          reference: `${token.address}`,
          methodParameters: [token.address],
        });

        listRequestToTokenContracts.push({
          contractAddress: token.address,
          abi: ERC20TokenABI,
          reference: token.address,
          calls: [
            {
              methodParameters: [account, PITToken.address],
              methodName: "allowance",
              reference: "isAllowanceForPIT",
            },
            {
              methodParameters: [account],
              methodName: "balanceOf",
              reference: "balanceOfWallet",
            },
            {
              methodParameters: [],
              methodName: "decimals",
              reference: `decimal`,
            },
          ],
        });
      });

      const { results: listRequestToTokenContractResults } = await multiCallContract.call([
        ...listRequestToTokenContracts,
        listRequestToPriceContract,
        listRequestToPITContract,
      ]);

      const results = { ...listRequestToTokenContractResults };

      const priceOfLendingTokens = get(results, ["PriceContract", "callsReturnContext"])
        .filter((i) => i.reference.startsWith("getPrice-"))
        .map((o) => {
          const priceBN = get(o, ["returnValues", 0], 0);
          const priceDecimal = get(o, ["returnValues", 1], 0);
          const lendingToken = get(o, ["methodParameters", 0], "");

          return {
            price: formatUnits(priceBN, priceDecimal),
            lendingToken,
          };
        });

      const userTokenInfo = [...availableBorrowTokens, ...listToken].map((token) => {
        const tokenContract = get(results, [token.address, "callsReturnContext"], []);

        const methodData = groupBy(tokenContract, "methodName");

        return {
          ...token,
          allowance: !!get(methodData, ["allowance", 0, "returnValues"], false),
          balanceOf: formatUnits(
            get(methodData, ["balanceOf", 0, "returnValues"], "0"),
            get(methodData, ["decimals", 0, "returnValues"], "0")
          ),
          decimalNumber: formatUnits(get(methodData, ["decimals", 0, "returnValues"], "0"), 0),
        };
      });

      const decimalOfContractToken = {};
      map(omit(results, ["PriceContract"]), (obj) => {
        const key = get(obj, ["originalContractCallContext", "contractAddress"]).toLowerCase();

        const value = get(
          groupBy(obj.callsReturnContext, "methodName"),
          ["decimals", 0, "returnValues"],
          0
        );
        decimalOfContractToken[key] = +value;
      });

      const lendingTokenInfo = get(
        groupBy(get(results, ["PITContract"]).callsReturnContext, "methodName"),
        "lendingTokenInfo"
      ).map((i) => {
        const decimal = decimalOfContractToken[i.methodParameters[0]];
        const tokenAmount = (10 ** decimal).toString();
        return {
          lendingToken: i.methodParameters[0],
          fToken: get(i, ["returnValues", 2]),
          lvr: get(i, ["returnValues", 3]),
          tokenAmount,
        };
      });

      const amountDepositLimitPerProjectToken = get(
        groupBy(get(results, ["PITContract", "callsReturnContext"]), "methodName"),
        "depositLimitPerProjectToken"
      ).map((i) => {
        const decimal = decimalOfContractToken[i.reference];
        const tokenAmount = (10 ** decimal).toString();
        return { value: i.returnValues[0], prjTokenAddress: i.reference, tokenAmount };
      });

      /* A list of requests to ERC20 contract. */
      const listRequestToGetTokenEvalution = {
        contractAddress: PITToken.address,
        abi: PITToken.abi,
        reference: "PITContract",
        calls: [],
      };

      amountDepositLimitPerProjectToken.forEach((token) => {
        listRequestToGetTokenEvalution.calls.push({
          methodParameters: [token.prjTokenAddress, token.tokenAmount],
          methodName: "getTokenEvaluation",
          reference: `getTokenEvaluation`,
        });
      });

      const listRequestToERCBToken = [];
      lendingTokenInfo.forEach((token) => {
        listRequestToERCBToken.push({
          contractAddress: token.fToken,
          abi: ERC20TokenABI,
          reference: token.lendingToken,
          calls: [
            {
              methodParameters: [],
              methodName: "getCash",
              reference: `getCash`,
            },
            {
              methodParameters: [account],
              methodName: "balanceOfUnderlyingView",
              reference: `balanceOfUnderlyingView`,
            },
          ],
        });

        listRequestToERCBToken.push({
          contractAddress: token.lendingToken,
          abi: ERC20TokenABI,
          reference: `allowance-${token.lendingToken}`,
          calls: [
            {
              methodParameters: [account, token.fToken],
              methodName: "allowance",
              reference: `allowance`,
            },
          ],
        });

        listRequestToGetTokenEvalution.calls.push({
          methodParameters: [token.lendingToken, token.tokenAmount],
          methodName: "getTokenEvaluation",
          reference: `getTokenEvaluation`,
        });
      });

      const { results: listRequestMultiCallContractResults } = await multiCallContract.call([
        ...listRequestToERCBToken,
        listRequestToGetTokenEvalution,
      ]);

      const tokenEvoluationList = get(
        groupBy(
          get(listRequestMultiCallContractResults, ["PITContract", "callsReturnContext"]),
          "methodName"
        ),
        "getTokenEvaluation"
      ).reduce(
        (prev, cur) => ({
          ...prev,
          [cur.methodParameters[0]]: {
            tokenEvaluation: cur.returnValues[0],
            tokenAmount: cur.methodParameters[1],
          },
        }),
        {}
      );

      const availableDepositTokens = handleGetDepositTokens({
        results,
        listToken,
        tokenEvoluationList,
      }).map((o) => ({
        ...o,
        isLeverage: leveragePositionMap.get(o.address),
        leverageType: leverageType.get(o.address),
      }));

      const lvrByProjectTokens = listToken.reduce(
        (res, o) => ({
          ...res,
          [o.address]: getLvrFromResult(results, o.address),
        }),
        {}
      );

      const priceOfTokens = listToken.reduce((res, o) => {
        const { priceTokenBN, priceDecimal } = handleGetPriceInUsd(results, o.address);

        return {
          ...res,
          [o.address]: +formatUnits(priceTokenBN, priceDecimal),
        };
      }, {});

      const availableBorrowOrLendTokens = availableBorrowTokens.map((i) => {
        const lvrNumerator = get(
          lendingTokenInfo.find((o) => o.lendingToken === i.address),
          ["lvr", 0],
          0
        );
        const lvrDenominator = get(
          lendingTokenInfo.find((o) => o.lendingToken === i.address),
          ["lvr", 1],
          1
        );

        const fToken = get(
          lendingTokenInfo.find((o) => o.lendingToken === i.address),
          ["fToken"]
        );

        const lvr = lvrNumerator / (lvrDenominator || 1);
        const groupByObj = groupBy(
          get(listRequestMultiCallContractResults, [i.address]).callsReturnContext,
          "methodName"
        );

        const groupByObjAllowance = groupBy(
          get(listRequestMultiCallContractResults, [`allowance-${i.address}`]).callsReturnContext,
          "methodName"
        );

        const decimal = decimalOfContractToken[i.address];
        const getCash = get(groupByObj, ["getCash", 0, "returnValues"]);
        const balanceOfUnderlyingView = get(groupByObj, [
          "balanceOfUnderlyingView",
          0,
          "returnValues",
        ]);

        const allowance = get(groupByObjAllowance, ["allowance", 0, "returnValues"]);

        const priceInfo = priceOfLendingTokens.find((p) => p.lendingToken === i.address);

        const balanceOfLendingToken = get(results, [
          i.address,
          "callsReturnContext",
          1,
          "returnValues",
        ]);
        const decimalOfLendingToken = get(results, [
          i.address,
          "callsReturnContext",
          2,
          "returnValues",
        ]);

        const balanceOf = formatUnits(balanceOfLendingToken, Number(decimalOfLendingToken));
        const balanceInUsd = new BNjs(balanceOf).multipliedBy(priceInfo.price).toString();

        const totalSupply = formatUnits(getCash, Number(decimal));
        const totalSupplyInUsd = new BNjs(totalSupply).multipliedBy(priceInfo.price).toString();

        const lendingAPY = get(APY, ["lender_apy"], []).find(
          (o) => o.lendingTokenAddress === i.address
        );

        const borrowAPY = get(APY, ["borrowing_apy"], []).find(
          (o) => o.lendingTokenAddress === i.address
        );

        return {
          ...i,
          lvr,
          priceInfo,
          balanceInUsd,
          balance: balanceOf,
          totalSupply,
          totalSupplyInUsd,
          decimal,
          lendingAPY,
          borrowAPY,
          allowance: !BigNumber.from(allowance).isZero(),
          allowanceBN: BigNumber.from(allowance),
          fToken,
          balanceOfUnderlyingView: formatUnits(balanceOfUnderlyingView, Number(decimal)),
        };
      });
      return {
        availableDepositTokens,
        userTokenInfo,
        decimalOfContractToken,
        lvrByProjectTokens,
        availableBorrowOrLendTokens,
        priceOfTokens,
      };
    },
    {
      enabled: !!account && !!collaterals?.length && !!availableBorrowTokens?.length && !!chainId,
    }
  );
};

export default useAvailableMultiCall;
