import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Spinner } from "components";
import { useMarketChart } from "hooks/query/graphQL/useMarketChart";
import { useChartContext } from "pages/IndexMarket/ChartContext";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ChartChip, ChartIntervals, ChartLinesSelect, TradingChart } from "..";
import { DEFAULT_INTERVAL_VALUE } from "../../constants";

const useStyles = makeStyles((theme) => ({
  chartBox: {
    minHeight: 465,
    marginTop: theme.spacing(2),
  },
}));

const MarketChart = () => {
  const classes = useStyles();

  const [selectedInterval, setSelectedInterval] = useState(DEFAULT_INTERVAL_VALUE);

  const { actLines, setActiveLines, activeTab } = useChartContext();
  const { isLoading = true, fullData } = useMarketChart();

  useEffect(() => {
    setActiveLines([activeTab]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleSelectInterval = useCallback((newInterval) => {
    setSelectedInterval(newInterval);
  }, []);

  const handleRemoveLine = (line) =>
    setActiveLines((prevState) => [...prevState].filter(({ title }) => title !== line.title));

  const renderChart = () => {
    if (isLoading) {
      return <Spinner />;
    }

    return <TradingChart data={fullData} lines={actLines} interval={selectedInterval} />;
  };

  return (
    <Paper>
      <Box
        p={3}
        sx={{
          padding: {
            xs: "24px 12px",
            md: "24px",
          },
        }}
      >
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <ChartIntervals selectedInterval={selectedInterval} onChange={handleSelectInterval} />
          </Grid>

          <Grid item xs={12} sm="auto">
            <ChartLinesSelect activeLines={actLines} />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          padding: {
            xs: "0 12px",
            md: "0 24px",
          },
        }}
      >
        <Grid container spacing={1}>
          {actLines.map((line) => (
            <Grid item key={line.value}>
              <ChartChip line={line} onRemove={handleRemoveLine} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        className={classes.chartBox}
        sx={{
          padding: {
            xs: "24px 12px",
            md: "24px",
          },
        }}
      >
        {renderChart()}
      </Box>
    </Paper>
  );
};

MarketChart.propTypes = {};

export default MarketChart;
