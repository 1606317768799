import theme from "../theme";

const MuiSwitch = {
  props: {
    color: "primary",
    disableRipple: true,
  },

  overrides: {
    root: {
      display: "flex",
      width: 32,
      height: 18,
      padding: 0,
      borderRadius: 15,
      marginLeft: "auto",

      [theme.breakpoints.up("sm")]: {
        width: 52,
        height: 30,
      },
    },

    thumb: {
      width: 15,
      height: 15,
      backgroundColor: theme.palette.common.white,
      boxShadow: "0px 2px 4px rgba(44, 39, 56, 0.1)",
      border: `1px solid ${theme.palette.info.main}`,
      boxSizing: "border-box",

      [theme.breakpoints.up("sm")]: {
        width: 24,
        height: 24,
      },
    },

    track: {
      border: `1px solid ${theme.palette.info.main}`,
      borderRadius: 15,
      boxSizing: "border-box",
      backgroundColor: theme.palette.common.white,
    },

    switchBase: {
      top: "50%",
      left: 0,
      padding: 3,
      transform: "translateY(-50%)",

      [theme.breakpoints.up("sm")]: {
        top: 0,
        left: 1,
        transform: "translateY(0)",
      },

      "&$checked": {
        transform: "translateX(12px) translateY(-50%)",

        [theme.breakpoints.up("sm")]: {
          transform: "translateX(20px)",
        },
      },

      "&:hover": {
        backgroundColor: "transparent",
      },
    },

    colorPrimary: {
      "&$checked": {
        "& + $track": {
          border: "1px solid transparent",
          opacity: 1,
        },

        "&$disabled": {
          "& + $track": {
            border: `1px solid ${theme.palette.info.main}`,
            opacity: 1,
          },

          "& $thumb": {
            border: `1px solid ${theme.palette.info.main}`,
            backgroundColor: theme.palette.info.light,
          },
        },

        "& $thumb": {
          border: "1px solid transparent",
          backgroundColor: theme.palette.common.white,
        },

        "&:hover": {
          backgroundColor: "transparent",
        },
      },

      "&$disabled": {
        "& + $track": {
          border: `1px solid ${theme.palette.info.main}`,
          backgroundColor: theme.palette.info.light,
          opacity: 1,
        },

        "& $thumb": {
          backgroundColor: theme.palette.info.light,
        },
      },
    },
  },
};

export default MuiSwitch;
