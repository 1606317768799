// import BigNumber from "bignumber.js";
// import { USD_DECIMAL } from "constants/contract";
// import { constants } from "ethers";
// import { formatUnits } from "utils/number";
import { get as _get, groupBy } from "lodash";
// import {reduce } from "lodash";
// import { getLimitBorrowingOfLendingTokenFromMultiData } from "./getLimitBorrowingOfLendingTokenFromMultiData";
// import { handleGetDataFromPosition } from "./handleGetDataFromPosition";
// import { handleGetHealthFactor } from "./handleGetHealthFactor";
// import { handleGetIssuedPITAmount } from "./handleGetIssuedPITAmount";
// import { handleGetPITRemainingAmount } from "./handleGetPITRemainingAmount";
// import { handleGetLiquidationThreshold } from "./handleGetLiquidationThreshold";
// import { handleLvrLending } from "./handleLvrLending";
import { formatUnits } from "utils/number";
import { BigNumber } from "ethers";
import { handleEvaluation } from "./handleEvaluation";
import methodOfPITContract from "./methodOfPitContract";
import {
  handleBorrowLimitPerLendingToken,
  handleTotalBorrowedPerLendingToken,
} from "./getLimitBorrowingOfLendingTokenFromMultiData";

const {
  // pitCollateralMethod,
  // pitRemainingMethod,
  // convertPitRemainingMethod,
  // positionMethod,
  // totalBorrowedInCollateralMethod,
  // limitBorrowInCollateralMethod,
  // totalBorrowedInLendingTokenMethod,
  // lendingTokenInfoMethod,
  // lvrLendingTokenMethod,
  tokenEvaluationMethod,
  convertEstimatedPitRemainingMethod,
  getCollateralAvailableToWithdrawMethod,
  depositedAmountMethod,
  lendingTokenInfoMethod,
  totalEstimatedPITRemainingMethod,
  totalEstimatedOutstandingInUSDMethod,
  totalEstimatedWeightedLoanInUSDMethod,
  borrowPositionMethod,
  getEstimatedOutstandingInUSDMethod,
  limitBorrowInLendingTokenMethod,
  totalBorrowedPerLendingTokenMethod,
} = methodOfPITContract;

export function getDataForBorrowContext(resultFromMultiCall) {
  const pitContract = _get(resultFromMultiCall, "PITContract");

  const dataOnPitContract = groupBy(pitContract.callsReturnContext, "methodName");

  // const liquidationThresholdList = handleGetLiquidationThreshold(
  //   _get(dataOnPitContract, methodOfPITContract.liquidationThreshold, {})
  // );

  const fTokenAddresses = _get(dataOnPitContract, lendingTokenInfoMethod, []).map((o) => ({
    ftoken: _get(o, ["returnValues", 2]),
    isPaused: _get(o, ["returnValues", 1]),
    token: _get(o, ["methodParameters", 0]),
  }));

  // const pitRemainingList = _get(dataOnPitContract, [pitRemainingMethod]);
  // const convertPitRemainingList = _get(dataOnPitContract, [convertPitRemainingMethod]);
  // const pitCollateralList = _get(dataOnPitContract, [pitCollateralMethod]);
  // const positionData = _get(dataOnPitContract, [positionMethod]);
  const getCollateralAvailableToWithdraw = _get(dataOnPitContract, [
    getCollateralAvailableToWithdrawMethod,
  ]);

  const getDepositedAmount = _get(dataOnPitContract, [depositedAmountMethod]);

  const depositedAmount = getDepositedAmount.reduce((prev, cur) => {
    const value = formatUnits(_get(cur, ["returnValues", 0]), 0);
    const keyAddress = _get(cur, ["reference"], "0");
    return { ...prev, [keyAddress]: { value } };
  }, {});

  const getConvertEstimatedPitRemaining = _get(dataOnPitContract, [
    convertEstimatedPitRemainingMethod,
  ]);

  const getTotalEstimatedPITRemaining = _get(dataOnPitContract, [totalEstimatedPITRemainingMethod]);

  const totalEstimatedPITRemaining = _get(getTotalEstimatedPITRemaining, [0, "returnValues", 0], 0);

  const getTotalEstimatedOutstandingInUSD = _get(dataOnPitContract, [
    totalEstimatedOutstandingInUSDMethod,
  ]);

  const totalEstimatedOutstandingInUSD = _get(
    getTotalEstimatedOutstandingInUSD,
    [0, "returnValues", 0],
    0
  );

  const getTotalEstimatedWeightedLoanInUSD = _get(dataOnPitContract, [
    totalEstimatedWeightedLoanInUSDMethod,
  ]);

  const totalEstimatedWeightedLoanInUSD = _get(getTotalEstimatedWeightedLoanInUSD, [
    0,
    "returnValues",
    0,
  ]);

  const getBorrowPosition = _get(dataOnPitContract, [borrowPositionMethod]);

  const borrowPosition = getBorrowPosition.map((o) => {
    const loanBody = _get(o, ["returnValues", 0]);
    const accrual = _get(o, ["returnValues", 1]);
    const lendingToken = _get(o, ["methodParameters", 1]);
    return {
      loanBody,
      accrual,
      address: lendingToken,
    };
  });

  const getEstimatedOutstandingInUSD = _get(dataOnPitContract, [
    getEstimatedOutstandingInUSDMethod,
  ]);

  const estimatedOutstandingInUSD = getEstimatedOutstandingInUSD.map((o) => {
    const loanBody = _get(o, ["returnValues", 0]);
    const accrual = _get(o, ["returnValues", 1]);
    const value = _get(o, ["returnValues", 2]);
    const lendingToken = _get(o, ["methodParameters", 1]);

    return {
      loanBody,
      accrual,
      lendingToken,
      estimatedOutstandingInUSD: value,
    };
  });

  const getBorrowLimitPerLendingToken = _get(dataOnPitContract, [limitBorrowInLendingTokenMethod]);

  const borrowLimitPerLendingTokenUSD = handleBorrowLimitPerLendingToken(
    getBorrowLimitPerLendingToken
  );

  const getTotalBorrowedPerLendingToken = _get(dataOnPitContract, [
    totalBorrowedPerLendingTokenMethod,
  ]);

  const totalBorrowedPerLendingToken = handleTotalBorrowedPerLendingToken(
    getTotalBorrowedPerLendingToken
  );

  // const lvrList = _get(dataOnPitContract, [lvrLendingTokenMethod]);
  const evaluationList = _get(dataOnPitContract, [tokenEvaluationMethod]);
  const evaluations = handleEvaluation(evaluationList);

  // const limitBorrowInLendingToken = getLimitBorrowingOfLendingTokenFromMultiData(dataOnPitContract);

  // const limitBorrowInCollateral = _get(dataOnPitContract, [limitBorrowInCollateralMethod])?.reduce(
  //   (res, resultCall) => ({
  //     ...res,
  //     [_get(resultCall, ["reference"], constants.AddressZero)]: formatUnits(
  //       _get(resultCall, ["returnValues", 0, "hex"], "0"),
  //       USD_DECIMAL
  //     ),
  //   }),
  //   {}
  // );

  // const totalBorrowedInCollateral = _get(dataOnPitContract, [
  //   totalBorrowedInCollateralMethod,
  // ])?.reduce(
  //   (res, resultCall) => ({
  //     ...res,
  //     [_get(resultCall, ["reference"], constants.AddressZero)]: formatUnits(
  //       _get(resultCall, ["returnValues", 0, "hex"], "0"),
  //       USD_DECIMAL
  //     ),
  //   }),
  //   {}
  // );

  // const totalBorrowedInLendingToken = _get(dataOnPitContract, [
  //   totalBorrowedInLendingTokenMethod,
  // ])?.reduce(
  //   (res, resultCall) => ({
  //     ...res,
  //     [_get(resultCall, ["reference"], constants.AddressZero)]: formatUnits(
  //       _get(resultCall, ["returnValues", 0, "hex"], "0"),
  //       USD_DECIMAL
  //     ),
  //   }),
  //   {}
  // );

  // const currentLimitLendingToken = reduce(
  //   limitBorrowInLendingToken,
  //   (res, limit, key) => ({
  //     ...res,
  //     [key]: {
  //       limitMax: limit,
  //       totalBorrowed: _get(totalBorrowedInLendingToken, [key], "0"),
  //       limit: new BigNumber(limit).minus(_get(totalBorrowedInLendingToken, [key], "0")).toString(),
  //     },
  //   }),
  //   {}
  // );

  // const currentLimitPrjToken = reduce(
  //   limitBorrowInCollateral,
  //   (res, limit, key) => ({
  //     ...res,
  //     [key]: {
  //       limitMax: limit,
  //       totalBorrowed: _get(totalBorrowedInCollateral, [key], "0"),
  //       limit: new BigNumber(limit).minus(_get(totalBorrowedInCollateral, [key], "0")).toString(),
  //     },
  //   }),
  //   {}
  // );

  // const depositedAssets = handleGetDataFromPosition(positionData, 0);

  // const totalOutstandingAssets = handleGetDataFromPosition(positionData, 1);

  // const accrualAssets = handleGetDataFromPosition(positionData, 2);

  // const healthFactor = handleGetHealthFactor(positionData);

  // const pitRemaining = handleGetPITRemainingAmount(convertPitRemainingList);
  // const totalPITCollateral = handleGetIssuedPITAmount(
  //   pitCollateralList,
  //   pitRemainingList,
  //   totalOutstandingAssets
  // );
  // const lvrLending = handleLvrLending(lvrList);

  const collateralAvailableToWithdraw = getCollateralAvailableToWithdraw.reduce((pre, cur) => {
    const key = cur.methodParameters[1]; // project token
    const value = cur.methodParameters[2]; // lending token

    const valueNumber = Number(cur.returnValues[0].hex);

    if (key in pre) {
      pre[key][value] = valueNumber;
    } else {
      pre[key] = {};
      pre[key][value] = valueNumber;
    }

    return { ...pre };
  }, {});

  const convertEstimatedPitRemaining = getConvertEstimatedPitRemaining.reduce((pre, cur) => {
    const key = cur.methodParameters[1]; // lending token
    const valueNumber = Number(cur.returnValues[0].hex);

    pre[key] = valueNumber;

    return { ...pre };
  }, {});

  return {
    // currentLimitLendingToken,
    // currentLimitPrjToken,
    // pitRemaining,
    // totalPITCollateral,
    // totalOutstandingAssets,
    // accrualAssets,
    // healthFactor,
    // depositedAssets,
    // fTokenAddresses,
    // lvrLending,
    // liquidationThresholdList,
    evaluations,
    collateralAvailableToWithdraw,
    depositedAmount,
    fTokenAddresses,
    convertEstimatedPitRemaining,
    totalEstimatedPITRemaining: BigNumber.from(totalEstimatedPITRemaining),
    totalEstimatedOutstandingInUSD: BigNumber.from(totalEstimatedOutstandingInUSD),
    totalEstimatedWeightedLoanInUSD: BigNumber.from(totalEstimatedWeightedLoanInUSD),
    borrowPosition,
    estimatedOutstandingInUSD,
    borrowLimitPerLendingTokenUSD,
    totalBorrowedPerLendingToken,
  };
}
