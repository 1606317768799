import { Grid, makeStyles, Box } from "@material-ui/core";
import React from "react";
import { TitleLeverage } from "./TitleLeverage";

const useStyles = makeStyles(() => ({
  BoxStyle: {
    fontWeight: 700,
    paddingBottom: "10px",
    alignItems: "center",
    "@media(max-width: 576px)": {
      justifyContent: "space-between",
    },
  },
  FigureTitle: {
    width: "60%",
    alignItems: "center",
  },
  IconStyle: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
}));

export const FigureRow = ({ label, value, titleInfo }) => {
  const classes = useStyles();
  const { pathname } = window.location;
  const colorText = pathname === "/amplify" ? "" : "#fff !important";
  return (
    <Box color="#fff">
      <Grid container className={classes.BoxStyle}>
        <Grid item md={7}>
          <TitleLeverage title={label} helptext={titleInfo} color={colorText} />
        </Grid>
        <Grid item md={5} textAlign="left">
          <Box color={colorText}>{value}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};
