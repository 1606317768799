import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from "@material-ui/core";
import { DECIMAL_SCALE, REPAY_MAX_BUFFER, REPAY_TOLERANCE_AMOUNT } from "app/constants";
import BigNumber from "bignumber.js";
import { DialogApplyButton } from "components/DialogApplyButton";
import { NumericTextField } from "components/NumericTextField";
import { Spinner } from "components/Spinner";
import { constants, ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils.js";
import { useWallet } from "hooks";
import { useAtomicRepayment } from "hooks/atomic/useAtomicRepayment";
import { useDebounce } from "hooks/common/useDebounce";
import { useRouterMutations } from "hooks/mutation";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isEqualLowerString } from "utils/addressUtils";
import { createBuyCallData } from "utils/ethereum/createBuyCallData";
import { formatUnits } from "utils/number";
import { NumericText } from "components/NumericText";
import { useStyles } from "./useStyles";

function RepayCollateral({ onClose, lendingAsset, selectedAsset, onCloseRepayModal }) {
  const {
    address: prjTokenAddress,
    decimal: prjTokenDecimal,
    data: { price: prjTokenPrice },
    symbol: prjTokenSymbol,
    remainingDepositValue,
    maxValueToRepayCollateral,
    owedAmount,
    outstandingFormat,
    pairToken,
  } = selectedAsset;

  const {
    address: lendingTokenAddress,
    decimal: lendingDecimal,
    priceInfo: { price: lendingTokenPrice },
  } = lendingAsset;

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [amountSwap, setAmountSwap] = useState(0);
  const [priceDiff, setPriceDiff] = useState(0);

  const [inputNum, setInputValue] = useState("");
  const inputValue = useDebounce(inputNum, 200);

  const { chainId, signer, account } = useWallet();

  const { getAmountOut, isLoadingData } = useRouterMutations();
  const { repayCollateral, isWaitingTx } = useAtomicRepayment();

  const isHavePairToken = useMemo(
    () =>
      pairToken &&
      !isEqualLowerString(pairToken, constants.AddressZero) &&
      !isEqualLowerString(prjTokenAddress, constants.AddressZero),
    [prjTokenAddress, pairToken]
  );

  const isRepayFully = useMemo(
    () => new BigNumber(inputValue).minus(owedAmount).abs().lte(REPAY_TOLERANCE_AMOUNT),
    [inputValue, owedAmount]
  );

  const isRepayCollateralDisabled =
    new BigNumber(inputValue).gt(maxValueToRepayCollateral) ||
    new BigNumber(inputValue).gt(owedAmount) ||
    isLoadingData;

  const maxValueRepayCollateral = useMemo(() => {
    const availableLendingAmountDecimals = maxValueToRepayCollateral;
    const totalOutStandingDecimals = outstandingFormat;

    if (
      Number(totalOutStandingDecimals) * (1 + REPAY_MAX_BUFFER) <=
      Number(availableLendingAmountDecimals)
    ) {
      // eslint-disable-next-line consistent-return
      return Number(totalOutStandingDecimals);
    }
    // eslint-disable-next-line consistent-return
    return Math.min(availableLendingAmountDecimals, totalOutStandingDecimals);
  }, [maxValueToRepayCollateral, outstandingFormat]);

  const fetchAmountOut = useCallback(async () => {
    if (
      !inputValue ||
      !prjTokenAddress ||
      !lendingTokenAddress ||
      !getAmountOut ||
      isEqualLowerString(pairToken, constants.AddressZero) ||
      +maxValueToRepayCollateral <= 1e-6
    ) {
      setAmountSwap(0);
      setPriceDiff(0);
    } else {
      const { amountOut } = await getAmountOut({
        maxAmountIn: ethers.utils.parseUnits(inputValue.toFixed(lendingDecimal), lendingDecimal),
        inputToken: lendingTokenAddress,
        outputToken: prjTokenAddress,
      });

      const amountAfterSwap = Number(formatUnits(amountOut, prjTokenDecimal));
      const priceLendingToken = inputValue * lendingTokenPrice;
      const pricePrjToken = amountAfterSwap * prjTokenPrice;

      const priceDifference = (
        ((pricePrjToken - priceLendingToken) / priceLendingToken) *
        100
      ).toFixed(2);

      setPriceDiff(priceDifference);
      setAmountSwap(amountAfterSwap.toFixed(6));
    }
  }, [
    getAmountOut,
    inputValue,
    lendingDecimal,
    lendingTokenAddress,
    lendingTokenPrice,
    maxValueToRepayCollateral,
    pairToken,
    prjTokenAddress,
    prjTokenDecimal,
    prjTokenPrice,
  ]);

  useEffect(() => {
    fetchAmountOut();
  }, [fetchAmountOut]);

  const resetInputValue = () => {
    setInputValue("");
  };

  const handleRepayCollateral = async () => {
    try {
      if (!isHavePairToken) {
        enqueueSnackbar(`No pair token!`, { variant: "error", autoHideDuration: 5000 });
        return;
      }
      if (!+remainingDepositValue) {
        enqueueSnackbar(`No collateral amount!`, {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }
      if (!inputValue.toString().length || !Number(inputValue)) {
        enqueueSnackbar(`No input value!`, {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }

      let inputValueWithBuffer = inputValue;

      if (isRepayFully) {
        inputValueWithBuffer = new BigNumber(inputValue).multipliedBy(1 + REPAY_MAX_BUFFER);
        // inputValueWithBuffer = new BigNumber(inputValue);
      }

      const repayAmount = parseUnits(
        inputValueWithBuffer.toFixed(lendingDecimal) || "0",
        lendingDecimal
      );

      const buyCalldata = await createBuyCallData(
        prjTokenAddress,
        remainingDepositValue,
        repayAmount,
        lendingTokenAddress,
        { chainId, signer, account }
      );

      await repayCollateral({
        lendingAddress: lendingTokenAddress,
        prjAddress: prjTokenAddress,
        collateralAmount: remainingDepositValue,
        buyCalldata,
        isRepayFully,
      });

      onClose();
      onCloseRepayModal();
    } catch (error) {
      if (
        error.reason !== "execution reverted: UniswapV2Router: INSUFFICIENT_INPUT_AMOUNT" &&
        error.reason !== "execution reverted: AtomicRepayment: invalid amount" &&
        error.reason !== "execution reverted: UniswapV2: INSUFFICIENT_INPUT_AMOUNT" &&
        error.reason !== "user rejected transaction"
      ) {
        enqueueSnackbar("Repay using collateral failed!", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    } finally {
      resetInputValue();
    }
  };

  const handleRepayCollateralMax = async () => {
    setInputValue(maxValueRepayCollateral);
  };

  return (
    <>
      {isWaitingTx && <Spinner position="absolute" color="success" />}
      <Box>
        <NumericTextField value={inputNum} onChange={setInputValue} decimalScale={DECIMAL_SCALE} />

        <Box className={classes.collateralAmount}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item md={6}>
              <Tooltip
                title="The amount of collateral that needs to be swapped."
                arrow
                placement="top"
              >
                <Box display="inline">Collateral amount</Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <NumericText
                value={amountSwap}
                suffix={prjTokenSymbol}
                precision={amountSwap ? 6 : 0}
              />
            </Grid>
          </Grid>{" "}
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item md={6}>
              <Tooltip
                title="The estimated difference between the USD values of input and output amounts."
                arrow
                placement="top"
              >
                <Box display="inline">Price discrepancy</Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Typography color="primary">{priceDiff}% </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid item xs={9}>
            <DialogApplyButton disabled={isRepayCollateralDisabled} onClick={handleRepayCollateral}>
              Repay
            </DialogApplyButton>
          </Grid>
          <Grid item xs={3}>
            <Button
              className={classes.maxButton}
              onClick={isLoadingData ? () => {} : handleRepayCollateralMax}
            >
              {isLoadingData ? (
                <CircularProgress className={classes.circularProgress} size={16} color="inherit" />
              ) : (
                "MAX"
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default RepayCollateral;
