import { Box, Grid } from "@material-ui/core";
import { MarginButton } from "components/Button/MarginButton";
import { IconInfo } from "components/Leveraged/IconInfo";
import { Text } from "components/Title/Text";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import numeral from "numeral";
import { ShowNumber } from "pages/AmplifyDashboard/components/ShowNumber";
import { useGetLongInfoAsset } from "pages/AmplifyDashboard/hook/useGetInfoAsset";
import { useEffect, useMemo, useState } from "react";
import { useAmplifyContext } from "context/InstantsLeverage/AmplifyContext/useAmplifyContext";
import { useStyles } from "./useStyles";

export const AmplifyMarginLeverage = () => {
  const classes = useStyles();
  const {
    margin: [margin],
    maxMargin,
    minMargin,
    longAssetSelected,
    isMarginInvalid,
    resetForm,
  } = useLeverageContext();
  const { setMargin } = useAmplifyContext();
  const { price, symbol } = useGetLongInfoAsset();
  const marginConvert = price ? margin / price : "0";
  const marginConvertFormatted =
    numeral(marginConvert).format("0,0.[0000]") !== "NaN"
      ? numeral(marginConvert).format("0,0.[0000]")
      : "0";
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setIsFocused(false);
  }, [resetForm]);

  const invalidMarginErrorMessage = useMemo(
    () =>
      isMarginInvalid && isFocused
        ? `Margin must be between $${numeral(minMargin).format("0,0.[000000]")} and $${numeral(
            maxMargin
          ).format("0,0.[000000]")}`
        : "",
    [maxMargin, minMargin, isMarginInvalid, isFocused]
  );

  const disabled = !longAssetSelected;

  return (
    <Grid container alignItems="center" className={classes.boxStyle}>
      <Grid item xs={12} sm={7} md={12}>
        <Box display="flex" alignItems="center" marginBottom="10px">
          <Box display="flex" gridGap={2} textAlign="left" justifyContent="left" width="100%">
            <IconInfo
              title={
                <Box>
                  <p>Dollar value of margin you wish to supply.</p>
                  <p>
                    “Min” button resets the Exposure amount to any already-deposited amount for the
                    long asset type.
                  </p>
                  <p>
                    “Max” button sets the Exposure amount to your already-deposited amount plus your
                    wallet balance for the long asset type.
                  </p>
                </Box>
              }
            />
            <Text>Margin</Text>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={7} md={12} className={classes.amplifyRow}>
        <Box className={classes.marginInputWrapper}>
          <ShowNumber
            min={minMargin}
            max={maxMargin}
            setValueInput={setMargin}
            valueInput={margin}
            disabled={disabled}
            onMouseLeave={() => setIsFocused(true)}
          />
        </Box>
        <Box className={classes.marginActionButtonGroup}>
          <MarginButton onClickButton={() => setMargin(minMargin)} disabled={disabled}>
            Min
          </MarginButton>
          <MarginButton onClickButton={() => setMargin(maxMargin)} disabled={disabled}>
            Max
          </MarginButton>
        </Box>
      </Grid>

      <p className={[classes.marginErrorMessage, classes.textLeft].join(" ")}>
        {invalidMarginErrorMessage}
      </p>

      <Grid item xs={12} sm={7} md={12} className={classes.textAmplify}>
        {marginConvertFormatted} {symbol}
      </Grid>
    </Grid>
  );
};
