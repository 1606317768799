import { Box, Grid, Typography } from "@material-ui/core";
import { constants } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import _get from "lodash/get";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";

import { DECIMAL_SCALE, REPAY_MAX_BUFFER } from "app/constants";
import { ReactComponent as ExitIcon } from "assets/svg/exit.svg";
import {
  CoinInfo,
  DialogApplyButton,
  DialogLogo,
  HealthFactorProgressBar,
  NumericTextField,
  Spinner,
} from "components";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { useWallet } from "hooks";
import { useAtomicRepayment } from "hooks/atomic/useAtomicRepayment";
import { useGetPair } from "hooks/atomic/useGetPair";
import { useGetRemainingDeposit } from "hooks/atomic/useGetRemainingDeposit";
import { useGetTotalOutstanding } from "hooks/atomic/useGetTotalOutstanding";
import { useDebounce } from "hooks/common/useDebounce";
import { isEqualLowerString } from "utils/addressUtils";
import { createBuyCallData } from "utils/ethereum/createBuyCallData";
import BigNumber from "bignumber.js";
import { useStyles } from "./useStyles";

const CloseLeverageModal = ({ data: dataModal, onClose }) => {
  const {
    lendingToken: { address: lendingTokenAddress, decimal: lendingDecimal },
    healthFactor,
    address,
  } = dataModal;

  const { enqueueSnackbar } = useSnackbar();
  const { chainId, signer, account } = useWallet();

  const context = useBorrowContext();
  const classes = useStyles();
  const [inputNum, setInputValue] = useState("");
  const inputValue = useDebounce(inputNum, 200);

  const fTokenRate = useMemo(
    () => _get(context, ["fTokenRate", lendingTokenAddress], {}),
    [context, lendingTokenAddress]
  );

  const { repayCollateral, isWaitingTx } = useAtomicRepayment();

  const remainingDepositValue = useGetRemainingDeposit(address);

  const outstanding = useGetTotalOutstanding(address, lendingTokenAddress);
  const outstandingFormat = formatUnits(outstanding || 0, lendingDecimal);

  const owedAmount = outstandingFormat;

  const pairToken = useGetPair(address, lendingTokenAddress);

  const isRepayDisabled =
    !inputValue.toString().length ||
    !Number(inputValue) ||
    isEqualLowerString(pairToken, constants.AddressZero);

  const resetInputValue = () => {
    setInputValue("");
  };

  const isHavePairToken = useMemo(
    () =>
      pairToken &&
      !isEqualLowerString(pairToken, constants.AddressZero) &&
      !isEqualLowerString(address, constants.AddressZero),
    [address, pairToken]
  );

  const handleRepayCollateral = async () => {
    try {
      if (!isHavePairToken) {
        enqueueSnackbar(`No pair token!`, { variant: "error", autoHideDuration: 5000 });
        return;
      }
      if (!+remainingDepositValue) {
        enqueueSnackbar(`No collateral amount!`, {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }
      if (!inputValue.toString().length || !Number(inputValue)) {
        enqueueSnackbar(`No input value!`, {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }

      const inputValueWithBuffer = new BigNumber(inputValue).multipliedBy(1 + REPAY_MAX_BUFFER);

      const repayAmount = parseUnits(
        inputValueWithBuffer.toFixed(lendingDecimal) || "0",
        lendingDecimal
      );

      const buyCalldata = await createBuyCallData(
        address,
        remainingDepositValue,
        repayAmount,
        lendingTokenAddress,
        { chainId, signer, account }
      );

      await repayCollateral({
        prjAddress: address,
        collateralAmount: remainingDepositValue,
        buyCalldata,
        isRepayFully: true,
      });

      onClose();
    } catch (error) {
      if (
        error.reason !== "execution reverted: UniswapV2Router: INSUFFICIENT_INPUT_AMOUNT" &&
        error.reason !== "execution reverted: AtomicRepayment: invalid amount" &&
        error.reason !== "execution reverted: UniswapV2: INSUFFICIENT_INPUT_AMOUNT" &&
        error.reason !== "user rejected transaction"
      ) {
        enqueueSnackbar("Close position failed!", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    } finally {
      resetInputValue();
    }
  };

  const [logoLendingToken, lendingTokenName, lendingAsset] = useMemo(
    () => [
      _get(dataModal, ["lendingToken", "logo"], "./assets/coins_list/usd-coin.svg"),
      _get(dataModal, ["lendingToken", "name"], ""),
      _get(dataModal, ["lendingToken", "symbol"], ""),
    ],
    [dataModal]
  );

  useEffect(() => {
    setInputValue(+outstandingFormat);
  }, [outstandingFormat]);

  return (
    <>
      <DialogLogo logoUrl={logoLendingToken} name={lendingTokenName} />
      {isWaitingTx && <Spinner position="absolute" color="success" />}
      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputNum}
          onChange={setInputValue}
          decimalScale={DECIMAL_SCALE}
          disabled
        />
        <Box px={2} pb={2} mt={2} className={classes.contentInner}>
          <Box py={2}>
            <Typography color="textSecondary" className={classes.supplyRates}>
              Supply Rates <ExitIcon />
            </Typography>
          </Box>
          <Box className={classes.supplyBox}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                <CoinInfo logoUrl={logoLendingToken}>Distribution APY ({lendingAsset}) </CoinInfo>
              </Grid>
              <Grid item>
                <Typography color="primary">{_get(fTokenRate, "borrowApy", "-")} %</Typography>
              </Grid>
            </Grid>
          </Box>
          <HealthFactorProgressBar value={healthFactor} />
          <Box className={classes.maxRepay}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                Owed
              </Grid>
              <Grid item>
                <Typography color="primary">{`${owedAmount} ${lendingAsset}`} </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box borderTop="#ccc 1px solid" marginTop={3}>
            <Grid container>
              <Grid item xs={12}>
                <DialogApplyButton onClick={handleRepayCollateral} disabled={isRepayDisabled}>
                  Close position
                </DialogApplyButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CloseLeverageModal;
