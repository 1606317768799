import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { useMemo } from "react";
import fontFamily from "theme/fontFamily";
import { HealthFactor } from ".";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#FFF",
    background: "#212222",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: "4px 23px",
    borderRadius: "6px",

    "& *": {
      fontFamily: `${fontFamily} !important`,
    },
  },
  title: {
    fontWeight: "300",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
  },
  healthFactor: {
    maxWidth: "700px",
    margin: "0 auto",
    paddingBottom: 70,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function BorrowOverView() {
  const classes = useStyles();

  const { totalEstimatedPITRemaining, totalEstimatedWeightedLoanInUSD } = useBorrowContext();

  const isRender = useMemo(() => {
    if (totalEstimatedPITRemaining && totalEstimatedWeightedLoanInUSD)
      return !(totalEstimatedPITRemaining.isZero() && totalEstimatedWeightedLoanInUSD.isZero());

    return false;
  }, [totalEstimatedPITRemaining, totalEstimatedWeightedLoanInUSD]);

  return (
    <>
      {isRender && (
        <Box className={classes.container}>
          <Box className={classes.healthFactor}>
            <HealthFactor />
          </Box>
        </Box>
      )}
    </>
  );
}

export default BorrowOverView;
