import { makeStyles } from "@material-ui/core/styles";

export const useCommonStyle = makeStyles((theme) => ({
  buttonBg: {
    background: "#6a6872",
    "&:hover": {
      background: "#96959c",
    },
  },
  button: {
    background: "#6a6872",
    height: 28,
    width: 100,

    "& span": {
      lineHeight: "30px",
    },

    "&:hover": {
      background: "#96959c",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  containerBackground: {
    background: "#191919",
  },
}));
