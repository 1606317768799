import { Grid, makeStyles } from "@material-ui/core";
import { FigureRow } from "components/Leveraged/FigureRow";
import { OpenPosition } from "components/Button/OpenPosition";

const useStyles = makeStyles((theme) => ({
  BoxStyle: {
    justifyContent: "right",
    background: "#212222",
    padding: "24px 0 24px 24px",
    borderRadius: "5px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      padding: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "24px 12px",
    },
  },
  FigureList: {
    marginBottom: 0,
  },
  warningMessage: {
    color: "#d5d5d5",
    textAlign: "center",
    width: "100%",
    paddingRight: "24px",
    margin: "10px 0",

    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
}));

export const ListFigures = ({ listValue, leverageType }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.BoxStyle}>
      <Grid item xs={12} sm={7} lg={12} className={classes.FigureList}>
        {listValue.map((item) => (
          <FigureRow
            key={item.label}
            value={item.value}
            label={item.label}
            titleInfo={item.helpText}
          />
        ))}
      </Grid>
      <Grid item xs={12} sm={7} lg={12} className={classes.warningMessage}>
        This information is only an estimation and might change in the future.
      </Grid>
      <Grid item xs={12} sm={7} lg={12}>
        <OpenPosition leverageType={leverageType} />
      </Grid>
    </Grid>
  );
};
