import { BorrowContext } from "context/contracts/BorrowContext";
import useGetApyOnToken from "hooks/useApyForPIT";
import { get } from "lodash";
import { useAvailableMultiCall } from "pages/BorrowerNewDashboard/hooks";
import usePrimaryIndexTokenMultiCall from "pages/BorrowerNewDashboard/hooks/PITContract/usePrimaryIndexTokenMultiCall";
import { useAllowanceToken } from "pages/BorrowerNewDashboard/hooks/useAllowanceToken";
import { useMemo } from "react";

export const BorrowContextProvider = ({ children }) => {
  const {
    data: availableDepositData,
    isLoading: depositingLoading,
    isError: depositingError,
    isFetched: isFetched1,
    refetch,
  } = useAvailableMultiCall();

  const {
    data: borrowedData,
    isError: borrowedError,
    isLoading: borrowedLoading,
    isFetched: isFetched2,
  } = usePrimaryIndexTokenMultiCall();

  const contractValue = useMemo(
    () => ({
      ...availableDepositData,
      ...borrowedData,
      isLoading: depositingLoading || borrowedLoading,
      isError: borrowedError || depositingError,
    }),
    [
      availableDepositData,
      borrowedData,
      borrowedError,
      borrowedLoading,
      depositingError,
      depositingLoading,
    ]
  );

  const fTokens = useMemo(() => get(contractValue, "fTokenAddresses", []), [contractValue]);

  const { data: fTokenRate, isFetched: isFetched3 } = useGetApyOnToken(fTokens);

  const { data: FTokenData, isFetched: isFetched4 } = useAllowanceToken(fTokens);

  const contextValue = useMemo(
    () => ({
      ...contractValue,
      isFetched: isFetched1 && isFetched2 && isFetched3 && isFetched4,
      refetch,
      fTokenRate,
      allowanceForFToken: get(FTokenData, "allowanceForFToken", null),
      cashFToken: get(FTokenData, "cashFToken", null),
    }),
    [contractValue, isFetched3, isFetched4, fTokenRate, FTokenData, isFetched1, isFetched2, refetch]
  );

  return <BorrowContext.Provider value={{ ...contextValue }}>{children}</BorrowContext.Provider>;
};
