import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    height: "100%",
  },

  tabContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {
  const classes = useStyles();

  const isVisible = value === index;
  return (
    <Box
      role="tabpanel"
      hidden={!isVisible}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      classes={{ root: classes.tabContainer }}
    >
      {isVisible && <Box className={classes.tabContent}>{children}</Box>}
    </Box>
  );
};

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default TabPanel;
