import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";
import AssetDash from "./AssetDash";
import SelectAsset from "./SelectAsset";

const useStyles = makeStyles(() => ({
  container: {
    color: "#fff",
    background: "#212222",
    borderRadius: "5px",
    padding: "14px",
    height: "100%",
  },
}));

function OpenPositionFrame() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <SelectAsset />
        <AssetDash />
      </Box>
    </>
  );
}

export default OpenPositionFrame;
