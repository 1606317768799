import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import React, { useEffect, useRef } from "react";
import { EventContractContext } from "./EventContext";

export const useEventContractAction = () => React.useContext(EventContractContext);

export const EventContractProvider = ({ children }) => {
  const [eventName, setEventName] = React.useState();

  const { updateDataGraph, isLoading, borrowLogsRaw } = useFetchGraphData();

  const reloadDataIntervalRef = useRef(null);

  useEffect(() => {
    if (eventName) {
      reloadDataIntervalRef.current = setInterval(updateDataGraph, 5000);

      setEventName(null);
    }
  }, [eventName, updateDataGraph]);

  useEffect(() => {
    if (reloadDataIntervalRef.current) {
      clearInterval(reloadDataIntervalRef.current);
    }
  }, [borrowLogsRaw?.length]);

  return (
    !isLoading && (
      <EventContractContext.Provider value={{ onEvent: (e) => setEventName(e) }}>
        {children}
      </EventContractContext.Provider>
    )
  );
};
