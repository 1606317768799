import { TokenProvider } from "context/tokens";
import { withWalletLoader } from "HOCFunction";
import { MarginTradeContextProvider } from "context/InstantsLeverage/MarginTradeContext/MarginTradeContext";
import { MarginTradeDashboardV3 } from "./components/MarginTradeV3";

export const MarginTrade = withWalletLoader(() => (
  <TokenProvider>
    <MarginTradeContextProvider>
      <MarginTradeDashboardV3 />
    </MarginTradeContextProvider>
  </TokenProvider>
));
