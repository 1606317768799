import { useCallback } from "react";

import { useChartContext } from "pages/IndexMarket/ChartContext";
// import { CHARTS } from "../../constants";

import { MarketChart, IndexMarketTabs } from "..";

const MarketCharts = () => {
  // const [activeTab, setActiveTab] = useState(CHARTS[0]);
  const { setActiveTab } = useChartContext();
  const handleTabChange = useCallback(
    (newTab) => {
      setActiveTab(newTab);
    },
    [setActiveTab]
  );

  return (
    <>
      <IndexMarketTabs onTabChange={handleTabChange} />

      <MarketChart />
    </>
  );
};

export default MarketCharts;
