import { DECIMAL_SCALE, EVENT_TYPES } from "app/constants";
import { ethers } from "ethers";
import floor from "lodash/floor";
import _min from "lodash/min";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { parseUnits } from "ethers/lib/utils.js";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { lendingTokenPropType } from "types/lendingToken";
import {
  DialogApplyButton,
  DialogLogo,
  DialogTotal,
  NumericText,
  NumericTextField,
  Spinner,
} from "components";
import { useWallet } from "hooks";
import { useLendingTokenMutations } from "hooks/mutation";
import { useGetCash } from "pages/BorrowerNewDashboard/hooks/useGetCash";
import { useBorrowContext } from "context/contracts/BorrowContext";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },
  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  dialogTotal: {
    width: "100%",
    backgroundColor: "#333333",
  },
}));

const RedeemModal = ({ data, onClose }) => {
  const { name, lendingAPY, lendingTokenBalance, symbol, logo, address, decimal, fToken } = data;
  const cash = useGetCash(fToken);

  const classes = useStyles();
  const { connected } = useWallet();
  const [inputValue, setInputValue] = useState("");
  const { refetch } = useBorrowContext();
  const { isLoading, redeemUnderlying } = useLendingTokenMutations({
    name,
    amount: inputValue,
    kind: EVENT_TYPES.withdraw,
  });
  const maxValue = useMemo(
    () =>
      _min([
        floor(lendingTokenBalance, DECIMAL_SCALE),
        ethers.utils.formatUnits(cash || 0, decimal),
      ]),
    [lendingTokenBalance, cash, decimal]
  );
  const isDisabled =
    !connected || !inputValue || Number(inputValue) === 0 || Number(inputValue) > Number(maxValue);

  const resetInputValue = () => setInputValue("");

  const handleSubmit = async () => {
    try {
      await redeemUnderlying({
        lendingTokenAmount: parseUnits(`${+inputValue || 0}`, decimal),
        address,
      });
      refetch();
      onClose();
    } finally {
      resetInputValue();
    }
  };

  return (
    <>
      <DialogLogo logoUrl={logo} name={symbol} />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputValue}
          onChange={setInputValue}
          maxValue={maxValue}
          decimalScale={DECIMAL_SCALE}
        />

        <Box className={classes.contentInner} mt={2}>
          <Box py={2} px={2}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                Supply APY
              </Grid>
              <Grid item>
                <Typography color="primary">
                  <NumericText value={lendingAPY?.amount} suffix="%" />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box>
          <DialogApplyButton disabled={isDisabled} onClick={handleSubmit}>
            Withdraw
          </DialogApplyButton>
          <Box py={1} className={classes.dialogTotal}>
            <DialogTotal
              title="Available To Withdraw"
              value={maxValue}
              currency={symbol}
              type="withdraw"
            />
            <DialogTotal
              title="Supplied Amount"
              value={Number(lendingTokenBalance).toFixed(9)}
              currency={symbol}
              type="withdraw"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

RedeemModal.propTypes = {
  data: lendingTokenPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RedeemModal;
