import { useSnackbar } from "notistack";
import { useCallback } from "react";
import ReactGa from "react-ga";
import { useMutation, useQueryClient } from "react-query";

import { useEventContractAction } from "context/EventContract/EventContractProvider";
import { useWallet } from "hooks";
import { useERC20TokenApproval } from "hooks/contract/tokens/useERC20TokenApproval";
import { usePITContract } from "hooks/contract/usePITContract";
import { PIT_EVENT } from "utils/ethereum/pitEventConstant";

const useCoinMutations = ({ projectTokenAddress }) => {
  const queryClient = useQueryClient();
  const { onEvent } = useEventContractAction();
  const { account } = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const {
    callback: { depositCall, withdrawCall },
    data: { PitInstance },
  } = usePITContract();

  const { approve: approvePIT, isLoading } = useERC20TokenApproval(projectTokenAddress, [
    PitInstance.address,
  ]);

  const handleError = useCallback(
    (error) => {
      onEvent(null);
      if (error.message === "userRejectedRequest") {
        enqueueSnackbar("Transaction has been canceled", {
          variant: "warning",
          autoHideDuration: 5000,
        });
      }
      if (error.message.includes("user rejected transaction")) {
        enqueueSnackbar(`Transaction rejected: user rejected transaction`, {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(`Transaction rejected: ${error.message}`, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [enqueueSnackbar, onEvent]
  );

  const deposit = useMutation(
    async (value) => {
      await depositCall({ collateralAddress: projectTokenAddress, collateralAmount: value });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["available-multicall", account]);
        await queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        onEvent(PIT_EVENT.Deposit);

        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Deposit success",
        });
      },
      onError: handleError,
    }
  );

  const withdraw = useMutation(
    async ({ value }) => {
      await withdrawCall({
        collateralAddress: projectTokenAddress,
        collateralAmount: value,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["available-multicall", account]);
        await queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        onEvent(PIT_EVENT.Withdraw);

        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Withdraw success",
        });
      },
      onError: handleError,
    }
  );

  return {
    isLoading: isLoading || deposit.isLoading || withdraw.isLoading,
    approve: approvePIT,
    deposit: deposit.mutateAsync,
    withdraw: withdraw.mutateAsync,
  };
};

export default useCoinMutations;
