import axios from "axios";

export const createCallDataViaAPI = async (
  tokenCollateral,
  lendingToken,
  amountTokenBN,
  side = "BUY",
  { chainId = 1, account }
) => {
  const { data: priceData } = await axios.get("https://apiv5.paraswap.io/prices/", {
    params: {
      srcToken: tokenCollateral,
      amount: amountTokenBN,
      destToken: lendingToken,
      side,
      network: chainId,
    },
  });

  const txDataBody = {
    ...priceData,
    srcToken: tokenCollateral,
    destToken: lendingToken,
    slippage: "5",
    srcAmount: amountTokenBN,
    userAddress: account,
  };

  const { data: txData } = await axios.post(
    `https://apiv5.paraswap.io/transactions/${chainId}/`,
    txDataBody,
    { params: { ignoreChecks: true } }
  );
  return txData.data;
};
