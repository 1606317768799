import BigNumber from "bignumber.js";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import { useDepositedAssetStates } from "hooks/contexts/BorrowContext/useDepositedAssetStates";
import { get } from "lodash";
import { useMemo } from "react";

export const useGetLongInfoAsset = () => {
  const { longAssetSelected } = useLeverageContext();
  const [depositedAssetList] = useDepositedAssetStates();

  const infoMemo = useMemo(() => {
    if (longAssetSelected) {
      const depositedAsset = get(longAssetSelected, ["depositedAmount"], "0");
      const depositedAssetState = depositedAssetList.find(
        (x) => x.address === longAssetSelected.address
      );

      const depositedAssetUSD = new BigNumber(depositedAsset)
        .multipliedBy(longAssetSelected?.price || 0)
        .toString();

      const balance = new BigNumber(longAssetSelected?.balance || 0).toString();

      const balanceUSD = new BigNumber(longAssetSelected?.balance || 0)
        .multipliedBy(longAssetSelected?.price || 0)
        .toString();

      return {
        ...longAssetSelected,
        depositedAsset,
        depositedAssetUSD,
        symbol: longAssetSelected?.symbol || "$",
        balance,
        balanceUSD,
        allowance: longAssetSelected?.allowance,
        prjRemaining: depositedAssetState?.data?.prjRemaining,
        totalOutstanding: depositedAssetState?.data?.totalOutstanding,
      };
    }
    return {
      decimal: "18",
      symbol: "",
      price: "0",
      depositedAsset: "0",
      depositedAssetUSD: "0",
      balance: "0",
      balanceUSD: "0",
      allowance: false,
      prjRemaining: {},
      totalOutstanding: {},
    };
  }, [longAssetSelected, depositedAssetList]);

  return infoMemo;
};
