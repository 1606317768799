import { transitions } from "../transitions";

const MuiLink = {
  props: {},
  overrides: {
    root: {
      transition: transitions.main,
      "&:hover": {
        transition: transitions.main,
      },
    },
  },
};

export default MuiLink;
