import ReactGa from "react-ga";

import { coinPropType } from "types/coin";

import { Button } from "@material-ui/core";

import { Dialog, SelectAssetModal, WithdrawModal } from "components";
import { useModalState } from "hooks";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.white,
    padding: 0,
    margin: "2px 0px",
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
    },
  },
}));

const CoinsActionsMenu = ({ data, disabled }) => {
  const classes = useStyles();
  const {
    isOpen: withdrawModalOpened,
    onOpen: handleWithdrawModalOpen,
    onClose: handleWithdrawModalClose,
  } = useModalState();
  const {
    isOpen: borrowModalOpened,
    onOpen: handleBorrowModalOpen,
    onClose: handleBorrowModalClose,
  } = useModalState();

  const handleWithdraw = () => {
    handleWithdrawModalOpen();

    ReactGa.event({
      category: "Borrower Dashboard",
      action: "Withdraw",
    });
  };

  const handleBorrow = () => {
    handleBorrowModalOpen();

    ReactGa.event({
      category: "Borrower Dashboard",
      action: "Borrow",
    });
  };

  return (
    <div className={classes.container}>
      <Button
        className={classes.button}
        style={{ height: 36, width: 100 }}
        onClick={handleBorrow}
        disabled={disabled}
      >
        Borrow
      </Button>
      <Button style={{ height: 36, width: 100 }} onClick={handleWithdraw} disabled={disabled}>
        Withdraw
      </Button>

      <Dialog open={withdrawModalOpened} onClose={handleWithdrawModalClose} noPadding>
        <WithdrawModal data={data} onClose={handleWithdrawModalClose} />
      </Dialog>

      <SelectAssetModal open={borrowModalOpened} onClose={handleBorrowModalClose} data={data} />
    </div>
  );
};

CoinsActionsMenu.propTypes = {
  data: coinPropType.isRequired,
};

export default CoinsActionsMenu;
