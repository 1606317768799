import { primary, common } from "../palette";

const MuiTableCell = {
  props: {},
  overrides: {
    root: {
      borderTop: "1px solid #434849",
      borderBottom: "1px solid #434849",

      "&$sizeSmall": {
        padding: "8px 11px",
      },
    },

    head: {
      borderTop: "1px solid #434849",
      borderBottom: "1px solid #434849",
      color: primary.light,
      fontSize: 14,
      fontWeight: 600,

      "&$sizeSmall": {
        padding: "8px 11px",
      },
    },

    body: {
      color: common.white,
      fontWeight: 500,
    },
  },
};

export default MuiTableCell;
