export const PITContractMethod = {
  lendingTokenInfoMethod: "lendingTokenInfo",
  limitBorrowInCollateralMethod: "borrowLimitPerCollateral",
  limitBorrowInLendingTokenMethod: "borrowLimitPerLendingToken",
  pitRemainingMethod: "pitRemaining",
  convertPitRemainingMethod: "convertPitRemaining",
  pitCollateralMethod: "pitCollateral",
  positionMethod: "getPosition",
  totalBorrowedInCollateralMethod: "getTotalBorrowPerCollateral",
  totalBorrowedInLendingTokenMethod: "getTotalBorrowPerLendingToken",
};

export const TokenMethod = {
  balanceOf: "balanceOf",
  name: "name",
  allowance: "allowance",
  decimals: "decimals",
  accountTokens: "accountTokens",
  supplyRatePerBlock: "supplyRatePerBlock",
  borrowRatePerBlock: "borrowRatePerBlock",
  balanceOfUnderlyingView: "balanceOfUnderlyingView",
  totalBorrows: "totalBorrows",
  exchangeRateStored: "exchangeRateStored",
  symbol: "symbol",
};
