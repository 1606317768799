import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  title: {
    position: "relative",

    "&:before": {
      content: "''",
      position: "absolute",
      left: "50%",
      top: 0,
      width: "100vw",
      height: "100%",
      transform: "translateX(-50%)",
      backgroundColor: theme.palette.primary.dark,
      zIndex: -1,
    },
  },
}));

const MobilePaperCard = ({ title, data, component: Component }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box my={2} py={1} className={classes.title}>
        <Typography color="secondary">{title}</Typography>
      </Box>

      {data.map((item) => (
        <Component data={item} key={item.name} />
      ))}
    </Box>
  );
};

MobilePaperCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  component: PropTypes.element.isRequired,
};

export default MobilePaperCard;
