import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  BoxStyle: {
    paddingLeft: "20px",
  },
  ButtonStyle: {
    padding: "0px 8px",
    borderRadius: "5px",
    minWidth: "55px",
    lineHeight: 1.5,
    backgroundColor: "rgb(110 116 123)",
    "&:hover": {
      backgroundColor: "rgb(137 143 150)",
    },
    "&:disabled": {
      backgroundColor: "rgb(61 61 61)",
      color: "#a7a7a7",
    },
  },
}));

export const MarginButton = ({ children, onClickButton, disabled }) => {
  const classes = useStyles();
  return (
    <Button className={classes.ButtonStyle} onClick={onClickButton} disabled={disabled}>
      {children}
    </Button>
  );
};
