import { useMutation } from "react-query";
import { useUniswapV2RouterContract } from "hooks/contract/core/useUniswapV2RouteContract";
import { isEqualLowerString } from "utils/addressUtils";

const useRouterMutations = () => {
  const { useUniswapV2Router } = useUniswapV2RouterContract();

  const getAmountOut = useMutation(
    async ({ maxAmountIn, inputToken, outputToken }) => {
      if (!useUniswapV2Router) {
        return { amountIn: maxAmountIn, amountOut: 0 };
      }
      const usdcToken = localStorage.getItem("usdcToken");
      const path = [inputToken];
      if (!isEqualLowerString(outputToken, usdcToken)) {
        path.push(usdcToken);
      }
      path.push(outputToken);

      const uniqPath = [...new Set(path)];
      const result = await useUniswapV2Router.getAmountsOut(maxAmountIn, uniqPath);
      return { amountIn: result[0], amountOut: result.at(-1) };
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );

  return {
    getAmountOut: getAmountOut.mutateAsync,
    isLoadingData: getAmountOut.isLoading,
  };
};

export default useRouterMutations;
