import { makeStyles, Grid, Box } from "@material-ui/core";
import { ShowNumber } from "pages/AmplifyDashboard/components/ShowNumber";
import { IconInfo } from "components/Leveraged/IconInfo";
import { Text } from "components/Title/Text";
import BigNumber from "bignumber.js";
import { useCallback, useMemo } from "react";
import { useMarginTradeContext } from "context/InstantsLeverage/MarginTradeContext/useMarginTradeContext";
import { MARGIN_TRADE_FIELD } from "context/InstantsLeverage/MarginTradeContext/MarginTradeContext";

const useStyles = makeStyles(() => ({
  boxStyle: {
    margin: "auto",
    marginTop: "16px",
    width: "100%",
  },
  titleStyle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  safetyBufferInput: {},
  marginErrorMessage: {
    margin: "5px 0",
    color: "#ff0000",
  },
}));

export const SafetyBuffer = () => {
  const classes = useStyles();
  const { safetyBuffer, setSafetyBuffer, setFocussedField } = useMarginTradeContext();

  const onMouseEnter = useCallback(() => {
    setFocussedField(MARGIN_TRADE_FIELD.SAFETY_BUFFER);
  }, [setFocussedField]);

  const invalidMarginErrorMessage = useMemo(
    () => (new BigNumber(safetyBuffer).lte(0) ? `Safety buff should be greater than 0` : ""),
    [safetyBuffer]
  );

  return (
    <Grid container alignItems="center" className={classes.boxStyle}>
      <Grid item xs={12}>
        <Box className={classes.titleStyle} gridGap={2}>
          <Box textAlign="left">
            <Text>New Account Safety Buffer</Text>
          </Box>
          <IconInfo
            title={
              <Box>
                <p>
                  Percent fall in collateral value before the position is subject to liquidation.
                </p>
                <p>I.e. Max drawdown before liquidation.</p>
              </Box>
            }
          />
        </Box>
      </Grid>
      <Grid item sm={6} xs={12} className={classes.safetyBufferInput}>
        <ShowNumber
          valueInput={safetyBuffer}
          min={0}
          inputPercent
          setValueInput={setSafetyBuffer}
          onMouseEnter={onMouseEnter}
        />
      </Grid>

      <p className={classes.marginErrorMessage}>{invalidMarginErrorMessage}</p>
    </Grid>
  );
};
