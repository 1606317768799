import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";
import metaImg from "assets/meta-img.png";
import MetaTags from "react-meta-tags";
import React from "react";
import { BorrowOverView } from "./components";
import {
  AssetToBorrowOrLend,
  CollateralToSupply,
  MyBorrrowingPosition,
  MyCollateral,
  MyLendingPosition,
} from "./components/BorrowV3";

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: "border-box",
  },
  title: {
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    margin: 0,

    "& h2": {
      fontWeight: 300,
    },

    "& h2:first-child": {
      marginRight: 50,
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& h2": {
        marginTop: 0,
        fontSize: 18,
      },
    },
  },
  myCollateralStyle: {
    padding: "0 16px 16px 0",

    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },

  collateralToSupply: {
    margin: "0 16px 0 0",

    [theme.breakpoints.down("sm")]: {
      margin: "0 0 16px 0",
    },
  },

  main: {
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  left: {
    width: "40%",
    height: "fit-content",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  right: {
    width: "60%",
    height: "fit-content",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function BorrowNewDashboard() {
  const classes = useStyles();

  const [myCollateralHeight, setMyCollateralHeight] = React.useState(0);
  const [myBorrowingHeight, setMyBorrowingHeight] = React.useState(0);
  const [myLendingHeight, setMyLendingHeight] = React.useState(0);

  return (
    <Box my={3} className={classes.container}>
      <MetaTags>
        <title>Borrow against your crypto assets | Fringe Finance</title>
        <meta
          name="description"
          content="Borrowing capital is easy! Use your crypto as collateral to get a loan instantly"
        />
        <meta
          property="og:title"
          content="Borrow stablecoins and other assets against your crypto"
        />
        <meta
          property="og:description"
          content="Borrowing stablecoin capital and other assets is easy! Use your crypto as collateral to get a loan instantly."
        />
        <meta property="og:image" content={metaImg} />
      </MetaTags>

      <Box className={classes.title}>
        <h2>Borrow against your crypto assets.</h2>
        <h2>Earn interest lending out your crypto assets.</h2>
      </Box>
      <Box>
        <BorrowOverView />
      </Box>
      <Box container mt={3} className={classes.main}>
        <Box className={classes.left}>
          <MyCollateral setHeightState={setMyCollateralHeight} />
          <Box className={classes.collateralToSupply}>
            <CollateralToSupply myCollateralHeight={myCollateralHeight} />
          </Box>
        </Box>
        <Box className={classes.right}>
          <MyBorrrowingPosition setMyBorrowingHeight={setMyBorrowingHeight} />
          <MyLendingPosition setMyLendingHeight={setMyLendingHeight} />
          <AssetToBorrowOrLend
            myBorrowingHeight={myBorrowingHeight}
            myLendingHeight={myLendingHeight}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default BorrowNewDashboard;
