import { Button, Typography, makeStyles, Tooltip, Collapse } from "@material-ui/core";
import { Box, FormControlLabel } from "@mui/material";
import { CoinInfo, DepositModal, Dialog, NumericText, Table, TableSkeleton } from "components";
import { IconInfo } from "components/Leveraged/IconInfo";
import { SwitchButton } from "components/Leveraged/SwitchButton";
import { Text } from "components/Title/Text";
import { logoBySymbol } from "constants/TokenListGoerliNetwork";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { useModalState, useToggle, useWallet } from "hooks";
import { get } from "lodash";
import numeral from "numeral";
import React, { useCallback, useMemo, useState } from "react";
import ReactGa from "react-ga";
import { depositTokenListExample } from "utils/availableDepositTokenExample";
import { ReactComponent as FilterIcon } from "assets/svg/filter.svg";
import useWindowDimensions from "pages/BorrowerNewDashboard/hooks/useWindowDimensions";
import { useCommonStyle } from "./commonStyle";
import { SearchField } from ".";

const MAX_HEIGHT_CONTENT = 1236.87890625;

function CollateralToSupply({ myCollateralHeight }) {
  const [titleHeight, setTitleHeight] = useState(0);
  const windowDimension = useWindowDimensions();

  const maxHeight = useMemo(
    () => MAX_HEIGHT_CONTENT - myCollateralHeight - titleHeight - 2,
    [myCollateralHeight, titleHeight]
  );

  const useStyles = makeStyles((theme) => ({
    container: {
      border: "1px solid #434849",
      "& .MuiTableContainer-root": {
        maxHeight,

        [theme.breakpoints.down("sm")]: {
          maxHeight: "unset",
        },
      },

      "& .MuiTableCell-stickyHeader": {
        backgroundColor: "#191919",
      },
    },
    title: {
      background: "#191919",
      padding: "8px 8px",
      paddingRight: 24,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#FFF",

      "& p": {
        margin: 0,
      },

      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
        flexDirection: "row",
      },
    },

    TableStyle: {
      backgroundColor: "rgb(33 34 34) !important",
      color: "#fff !important",
      "& .MuiTableRow-head": {
        borderBottom: "#3f4445 1px solid",
      },
      "& .MuiTableCell-root": {
        borderBottom: "#3f4445 1px solid",
        fontSize: "14px",
        textWrap: "nowrap",
      },
      "& .icon-style": {
        width: "20px",
        position: "absolute",
        right: 0,
        bottom: "31%",
      },

      [theme.breakpoints.down("sm")]: {
        "& .MuiTableCell-root": {
          fontSize: "12px",
        },
      },
    },

    EmptyDataText: {
      textAlign: "center",
      fontSize: "1.5rem",
      marginTop: "1.5rem",
      color: "#fff",
    },

    switchButton: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      color: "#6E747B",
      fontSize: 16,
      fontWeight: 600,

      [theme.breakpoints.down("md")]: {
        padding: "16px 0 0 16px",
      },

      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },

    supplyCapRemaining: {
      display: "flex",
      flexDirection: "column",
    },

    filterIcon: {
      width: 20,
    },

    collapse: ({ shouldShow }) => ({
      marginBottom: shouldShow ? 8 : 0,
      padding: shouldShow ? "0 24px 8px 24px" : "0 24px 0px 24px",
    }),

    textField: {
      border: "1px solid #FFF",
      width: "100%",
      padding: "0 8px",
      borderRadius: "4px",

      "& input": {
        color: "#FFF",
        fontSize: 16,
      },
    },
  }));

  const { availableDepositTokens, isLoading, isError, decimalOfContractToken, depositedAmount } =
    useBorrowContext();

  const [inputDebounce, setInputDebounce] = useState("");

  const { account } = useWallet();
  const [modalDataIdx, setModalDataIdx] = React.useState(null);
  const { isOpen, onOpen, onClose } = useModalState();

  const [hideZero, setHideZero] = React.useState(false);
  const [shouldShow, toggleShow] = useToggle(false);

  const classes = useStyles({
    shouldShow,
  });

  const commonStyle = useCommonStyle();

  const filterAvailableDepositTokens = React.useMemo(() => {
    if (availableDepositTokens && hideZero) {
      return availableDepositTokens
        .filter((token) => {
          const isDeposite = !!Number(depositedAmount[token.address].value);
          return isDeposite || Number(token.price) !== 0;
        })
        .filter(
          (token) =>
            token.name.toLowerCase().includes(inputDebounce) ||
            token.symbol.toLowerCase().includes(inputDebounce)
        );
    }

    return (
      availableDepositTokens &&
      availableDepositTokens.filter(
        (token) =>
          token.name.toLowerCase().includes(inputDebounce) ||
          token.symbol.toLowerCase().includes(inputDebounce)
      )
    );
  }, [availableDepositTokens, depositedAmount, hideZero, inputDebounce]);

  const handleClick = useCallback(
    (value) => () => {
      setModalDataIdx(value);
      onOpen();

      ReactGa.event({
        category: "Borrower Dashboard",
        action: "Deposit",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onOpen]
  );

  const COLUMNS = [
    {
      Header: <Text>Asset</Text>,
      accessor: "asset",
      Cell: ({
        // eslint-disable-next-line no-unused-vars
        value,
        row: {
          original: { logo, underlyingTokens, symbol },
        },
      }) => (
        <CoinInfo
          logoUrl={underlyingTokens.length ? "" : logo ?? "/assets/coins_list/pjToken.png"}
          isLPToken={underlyingTokens.length > 0}
          logoLPOne={underlyingTokens.length ? logoBySymbol[underlyingTokens[0].symbol] : ""}
          logoLPTwo={underlyingTokens.length ? logoBySymbol[underlyingTokens[1].symbol] : ""}
        >
          {underlyingTokens.length
            ? `${underlyingTokens[0].symbol}/${underlyingTokens[1].symbol}`
            : symbol}
        </CoinInfo>
      ),
    },
    {
      Header: (
        <Box display="flex" gap={1}>
          <Text>LVR</Text>
          <IconInfo title="Loan to value ratio for the collateral asset. The maximum borrowing capacity for a given collateral deposit = collateral value * LVR. Each collateral asset has its own LVR." />
        </Box>
      ),
      accessor: "LVR",
      props: {
        align: "center",
      },
      Cell: ({
        row: {
          original: { lvr },
        },
      }) => <Typography color="secondary">{numeral(lvr).format("0,0%")}</Typography>,
    },
    {
      Header: (
        <Box>
          <Text>Wallet </Text>
          <Text>balance</Text>
        </Box>
      ),
      accessor: "walletBalance",
      Cell: ({
        row: {
          original: { balance, symbol, price },
        },
      }) => (
        <>
          <Typography>
            <NumericText value={price} precision={2} moneyValue />
          </Typography>
          <NumericText value={balance} precision={2} suffix={symbol} />
        </>
      ),
    },
    {
      Header: (
        <Tooltip
          title="Supply Cap = The collateral asset's Supply Cap. Remaning = The collateral asset's Supply Cap minus current deposit balance by all users."
          arrow
          placement="top"
        >
          <Box>
            <Text>Supply cap </Text>
            <Text>remaining</Text>
          </Box>
        </Tooltip>
      ),
      accessor: "supplyCapAndRemaining",
      Cell: ({
        row: {
          original: { remaining },
        },
      }) => (
        <Box
          sx={{ display: "flex", flexDirection: "column" }}
          className={classes.supplyCapRemaining}
        >
          <NumericText value={remaining.toString()} precision={2} />
        </Box>
      ),
    },
  ];

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        Header: "",
        accessor: "button",
        props: {
          align: "right",
        },
        Cell: ({ row: { index } }) => (
          <Button className={commonStyle.buttonBg} onClick={handleClick(index.toString())}>
            Deposit
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClick]
  );

  const depositModalData = useMemo(() => {
    const deposit = get(
      filterAvailableDepositTokens,
      modalDataIdx,
      filterAvailableDepositTokens?.[0]
    );
    return {
      ...deposit,
      decimal: get(decimalOfContractToken, [deposit?.address?.toLowerCase()], 0),
    };
  }, [decimalOfContractToken, filterAvailableDepositTokens, modalDataIdx]);

  const handleSetRef = useCallback(
    (node) => {
      if (node) setTitleHeight(node?.getBoundingClientRect()?.height);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowDimension]
  );

  return (
    !isError && (
      <Box className={`${classes.container} ${commonStyle.containerBackground}`}>
        <Box className={classes.title} ref={(node) => handleSetRef(node)}>
          <p style={{ fontSize: "18px", paddingLeft: "16px" }}>Collateral to supply</p>
          <Box className={classes.switchButton}>
            <Box>Hide zero balances</Box>
            <FormControlLabel
              className={classes.SwitchWrapper}
              control={
                <SwitchButton
                  sx={{ m: 1 }}
                  checked={hideZero}
                  setHideZero={setHideZero}
                  hideZero={hideZero}
                />
              }
            />
          </Box>
          <Box onClick={toggleShow} sx={{ cursor: "pointer" }}>
            <FilterIcon className={classes.filterIcon} />
          </Box>
        </Box>
        <Collapse in={shouldShow} collapsedSize={0} className={classes.collapse}>
          <SearchField setInputDebounce={setInputDebounce} />
        </Collapse>
        {!account && (
          <>
            <Table columns={columns} data={depositTokenListExample} />
          </>
        )}
        {isLoading || !filterAvailableDepositTokens ? (
          account && <TableSkeleton rows={6} />
        ) : (
          <Box>
            <Table stickyHeader columns={columns} data={filterAvailableDepositTokens} />
            <Dialog open={isOpen} onClose={onClose} noPadding>
              <DepositModal data={depositModalData} onClose={onClose} />
            </Dialog>
          </Box>
        )}
      </Box>
    )
  );
}

export default React.memo(CollateralToSupply);
