import { primary } from "../palette";

const MuiInput = {
  props: {},
  overrides: {
    underline: {
      fontSize: "36px",
      caretColor: primary.light,

      "&:before, &:after": {
        display: "none",
      },
    },

    input: {
      textAlign: "center",

      "&::placeholder": {
        color: "#C4C4C4",
      },
    },
  },
};

export default MuiInput;
