export const depositTokenListExample = [
  {
    address: "0x255b584d5e43c092e209318cbd71971ec05895cd",
    allowance: false,
    balance: "0.0",
    healthFactor: 0,
    isLeverage: false,
    logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3267.png",
    lvr: 0.9,
    name: "Uniswap V2",
    price: "0.0",
    symbol: "PRJ2/PRJ1",
    type: "projectToken",
    underlyingTokens: [],
    remaining: 0,
    supllyCap: 0,
  },
  {
    address: "0x557c9280df3f06d9ebd09985207326c520eab421",
    allowance: false,
    balance: "0.0",
    healthFactor: 0,
    isLeverage: false,
    logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3267.png",
    lvr: 0.9,
    name: "Uniswap V2",
    price: "0.0",
    symbol: "USDC/PRJ1",
    type: "projectToken",
    underlyingTokens: [],
    remaining: 0,
    supllyCap: 0,
  },
  {
    address: "0x78d1f8c7bc0794d59c50abc4f5e74c47b212f402",
    allowance: false,
    balance: "0.0",
    healthFactor: 0,
    isLeverage: false,
    logo: "/assets/coins_list/pjToken.png",
    lvr: 0.9,
    name: "Uniswap V2",
    price: "0.0",
    symbol: "PRJ3",
    type: "projectToken",
    underlyingTokens: [],
    remaining: 0,
    supllyCap: 0,
  },
  {
    address: "0x96dc1f009f9e01d15b87f78795b538e5827f365e",
    allowance: false,
    balance: "0.0",
    healthFactor: 0,
    isLeverage: false,
    logo: "/assets/coins_list/pjToken.png",
    lvr: 0.9,
    name: "Uniswap V2",
    price: "0.0",
    symbol: "PRJ2",
    type: "projectToken",
    underlyingTokens: [],
    remaining: 0,
    supllyCap: 0,
  },
  {
    address: "0xed9f4c37094ded8a681e0ffcc9f5a7ee5247001a",
    allowance: false,
    balance: "0.0",
    healthFactor: 0,
    isLeverage: false,
    logo: "/assets/coins_list/pjToken.png",
    lvr: 0.9,
    name: "Uniswap V2",
    price: "0.0",
    symbol: "PRJ1",
    type: "projectToken",
    underlyingTokens: [],
    remaining: 0,
    supllyCap: 0,
  },
];
