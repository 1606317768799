import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Tooltip } from "@material-ui/core";
import { NumericText } from "components";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { formatUnits } from "utils/number";
import { useWallet } from "hooks";
import { usePlatformState } from "hooks/contexts/BorrowContext/usePlatformState";
import { get } from "lodash";
import { useMemo } from "react";
import { getPrimaryIndexToken } from "utils/ethereum/contracts";
import { convertNumberHex } from "utils/utils";
import { GraphicBar } from "components/GraphicBar";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontFamily: "Mulish",
    lineHeight: "21px",
    color: "#434849",
  },
  subTitle: {
    fontSize: 26,
    fontFamily: "Mulish",
    lineHeight: "36px",
    color: "#AFB0B1",

    marginTop: theme.spacing(2),
  },
  divider: {
    backgroundColor: "#434849",
    marginLeft: 20,
    marginRight: 20,

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    margin: 0,
    minHeight: "auto !important",
    "& > .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },

    "& > div:first-child": {
      flex: 1,
      maxWidth: "100%",
    },

    [theme.breakpoints.up("lg")]: {
      flexWrap: "nowrap",
    },
  },
  price: {
    fontSize: 52,
    fontFamily: "Mulish",
    marginTop: theme.spacing(1),
    lineHeight: "64px",
  },
  priceTitle: {
    fontFamily: "Mulish",
  },
}));

const BorrowerOverview = () => {
  const classes = useStyles();
  const { connected, chainId } = useWallet();
  const { pitCollateralBalance, decimalOfContractToken } = useBorrowContext();

  const { totalDepositedInUsd, totalOutstandingUsd, totalAccrualUsd } = usePlatformState();

  const totalPitAmount = useMemo(() => {
    const pitContract = getPrimaryIndexToken(chainId);
    return formatUnits(
      convertNumberHex(get(pitCollateralBalance, "totals", 0)),
      get(decimalOfContractToken, [pitContract?.address?.toLowerCase()], 0)
    );
  }, [pitCollateralBalance, decimalOfContractToken, chainId]);

  return (
    <>
      <Grid style={{ minHeight: "auto !important" }} item container className={classes.container}>
        <GraphicBar
          debt={Number(totalOutstandingUsd - totalAccrualUsd)}
          interest={Number(totalAccrualUsd)}
          remaining={Number(totalPitAmount)}
        />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Grid item xs={12} lg={3} xl={2}>
          <Box sx={{ display: "inline-block" }} mt={3}>
            <Tooltip title="Current deposited collateral value." arrow placement="top">
              <Typography color="primary" className={classes.title}>
                Total Deposited
              </Typography>
            </Tooltip>
            <Typography color="secondary" className={classes.subTitle}>
              <NumericText value={connected ? totalDepositedInUsd : 0} moneyValue />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BorrowerOverview;
