import { createContext, useEffect, useMemo, useState } from "react";
import { useDebounce } from "hooks/common/useDebounce";
import BigNumber from "bignumber.js";
import { getMargin, getSafeBuffer } from "utils/formula/geLeverageFields";
import { get } from "lodash";
import { LeverageContextProvider } from "../LeverageContext/LeverageContext";
import { useLeverageContext } from "../LeverageContext/useLeverageContext";

export const MarginTradeContext = createContext();

export const MARGIN_TRADE_FIELD = {
  NOTIONAL_EXPOSURE: "NOTIONAL_EXPOSURE",
  MARGIN: "MARGIN",
  SAFETY_BUFFER: "SAFETY_BUFFER",
};

const ContextProvider = ({ children }) => {
  const {
    margin: [, setLeverageMargin],
    amplification: [, setLeverageAmplification],
    longAssetSelected,
    setNotionalExp: setLeverageNotionalExp,
    setSafeBuffer: setLeverageSafeBuffer,
    resetStates: resetLeverageContextStates,
    minMargin,
    maxMargin,
    exposureLimit,
  } = useLeverageContext();

  const [focussedField, setFocussedField] = useState("");
  const [notionalExp, setNotionalExp] = useState("");
  const [margin, setMargin] = useState("");
  const [safetyBuffer, setSafetyBuffer] = useState("");

  const debouncedNotionalExp = useDebounce(notionalExp, 1000);
  const debouncedSafeBuffer = useDebounce(safetyBuffer, 1000);
  const debouncedMargin = useDebounce(margin, 1000);

  const resetStates = () => {
    setMargin("");
    setFocussedField("");
    setNotionalExp("");
    setSafetyBuffer("");
    resetLeverageContextStates();
  };

  const isMarginTradeFormInvalid = useMemo(
    () =>
      notionalExp === "" ||
      margin === "" ||
      safetyBuffer === "" ||
      new BigNumber(notionalExp).lte(0) ||
      new BigNumber(margin).lte(0) ||
      new BigNumber(safetyBuffer).lte(0) ||
      (exposureLimit !== null && new BigNumber(notionalExp).gt(exposureLimit)),
    [exposureLimit, margin, notionalExp, safetyBuffer]
  );

  /**
   * Push Margin, Notional Exposure and Safety Buffer to LeverageContext
   */
  useEffect(() => {
    setLeverageMargin(debouncedMargin);
  }, [debouncedMargin, setLeverageMargin]);

  useEffect(() => {
    setLeverageNotionalExp(debouncedNotionalExp);
  }, [debouncedNotionalExp, setLeverageNotionalExp]);

  useEffect(() => {
    setLeverageSafeBuffer(debouncedSafeBuffer / 100);
  }, [debouncedSafeBuffer, setLeverageSafeBuffer]);

  /**
   * Calculate Amplification by Margin and Notional Exposure
   * Re-calculate on: Margin changes or Notional Exposure changes
   */
  useEffect(() => {
    if (new BigNumber(debouncedNotionalExp).lte(0) || debouncedNotionalExp === "") {
      setLeverageAmplification(1);
      return;
    }

    if (new BigNumber(debouncedMargin).lte(0) || debouncedMargin === "") {
      setLeverageAmplification(0);
      return;
    }

    const caculatedAmplify = new BigNumber(debouncedNotionalExp)
      .dividedBy(debouncedMargin)
      .plus(1)
      .toNumber();

    setLeverageAmplification(caculatedAmplify);
  }, [debouncedMargin, debouncedNotionalExp, setLeverageAmplification]);

  /**
   * Calculate Margin by Safety Buffer and Notional Exposure
   * Re-calculate on: Safety Buffer changes
   */
  useEffect(() => {
    if (
      debouncedSafeBuffer !== "" &&
      debouncedNotionalExp !== "" &&
      longAssetSelected &&
      focussedField === MARGIN_TRADE_FIELD.SAFETY_BUFFER
    ) {
      const caculatedMargin = getMargin({
        safeBuffer: new BigNumber(debouncedSafeBuffer).dividedBy(100).toString(),
        notionalExp: debouncedNotionalExp,
        lvr: get(longAssetSelected, "lvr", 0),
      });

      const caculatedMarginBN = new BigNumber(caculatedMargin);
      let newMarginBN = caculatedMarginBN;
      if (
        caculatedMarginBN.lt(minMargin) &&
        new BigNumber(minMargin).minus(caculatedMarginBN).lt(0.01)
      ) {
        newMarginBN = new BigNumber(minMargin);
      } else if (caculatedMarginBN.gt(maxMargin) && caculatedMarginBN.minus(maxMargin).lt(0.01)) {
        newMarginBN = new BigNumber(maxMargin);
      }

      setLeverageMargin(newMarginBN.toFixed(2));
      setMargin(newMarginBN.toFixed(2));
    }
  }, [
    debouncedNotionalExp,
    debouncedSafeBuffer,
    focussedField,
    longAssetSelected,
    maxMargin,
    minMargin,
    setLeverageMargin,
  ]);

  /**
   * Calculate Safety Buffer by margin and Notional Exposure
   * Re-calculate on: Margin changes or Notional Exposure changes
   */
  useEffect(() => {
    if (
      (debouncedMargin === "" ||
        debouncedNotionalExp === "" ||
        new BigNumber(debouncedMargin).lte(0) ||
        new BigNumber(debouncedNotionalExp).lte(0)) &&
      (focussedField === MARGIN_TRADE_FIELD.MARGIN ||
        focussedField === MARGIN_TRADE_FIELD.NOTIONAL_EXPOSURE)
    ) {
      setLeverageSafeBuffer("");
      setSafetyBuffer("");
      return;
    }

    if (
      debouncedMargin !== "" &&
      debouncedNotionalExp !== "" &&
      longAssetSelected &&
      (focussedField === MARGIN_TRADE_FIELD.MARGIN ||
        focussedField === MARGIN_TRADE_FIELD.NOTIONAL_EXPOSURE)
    ) {
      const safeBuffer = getSafeBuffer({
        margin: debouncedMargin,
        notionalExp: debouncedNotionalExp,
        lvr: get(longAssetSelected, "lvr", 0),
      });

      setLeverageSafeBuffer(safeBuffer);
      setSafetyBuffer(new BigNumber(safeBuffer).multipliedBy(100).toFixed(2));
    }
  }, [
    debouncedMargin,
    debouncedNotionalExp,
    focussedField,
    longAssetSelected,
    setLeverageSafeBuffer,
  ]);

  const contextValue = {
    margin,
    setMargin,
    notionalExp,
    setNotionalExp,
    safetyBuffer,
    setSafetyBuffer,
    setFocussedField,
    resetStates,
    isMarginTradeFormInvalid,
  };

  return <MarginTradeContext.Provider value={contextValue}>{children}</MarginTradeContext.Provider>;
};

export const MarginTradeContextProvider = ({ children }) => (
  <LeverageContextProvider>
    <ContextProvider>{children}</ContextProvider>
  </LeverageContextProvider>
);
