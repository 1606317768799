import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "14px",
    color: "#fff",
    fontWeight: 500,
    textAlign: "left",

    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
}));
export const Text = ({ children, color }) => {
  const classes = useStyles();
  return (
    <Box className={classes.text} color={color}>
      {children}
    </Box>
  );
};
