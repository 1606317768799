import { Box, Grid, makeStyles } from "@material-ui/core";
import { NumericText } from "components";
import { IconInfo } from "components/Leveraged/IconInfo";
import { Text } from "components/Title/Text";
import { useGetLongInfoAsset } from "pages/AmplifyDashboard/hook/useGetInfoAsset";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: "14px",
    color: "#fff",
    fontWeight: 500,
  },
  rowStyle: {
    flexWrap: "wrap",
    marginTop: "10px",
  },
}));
export const LongAssetDash = () => {
  const classes = useStyles();

  const { price } = useGetLongInfoAsset();

  return (
    <Box marginBottom={2} className={classes.text}>
      <Grid container className={classes.rowStyle}>
        <Grid item xs={12}>
          <Box display="flex" gridGap={2}>
            <Text>Price</Text>
            <IconInfo title="Current price of the collateral asset." />
          </Box>
        </Grid>
        <Grid item xs={12} zeroMinWidth>
          <NumericText moneyValue value={price} />
        </Grid>
      </Grid>
    </Box>
  );
};
