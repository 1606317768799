import { Button, makeStyles } from "@material-ui/core";
import { Box, Dialog, Grid } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { REPAY_MAX_BUFFER } from "app/constants";
import BigNumber from "bignumber.js";
import { parseUnits } from "ethers/lib/utils.js";
import { useGetRemainingDeposit } from "hooks/atomic/useGetRemainingDeposit";
import { useGetTotalOutstanding } from "hooks/atomic/useGetTotalOutstanding";
import { useRouterMutations } from "hooks/mutation";
import React, { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { formatUnits } from "utils/number";
import ReactGa from "react-ga";
import { useModalState } from "hooks";
import { CloseLeverageModal } from "components/CloseLeverageModal";
import { RepayModal } from "components/RepayModal";
import { WithdrawModal } from "components/WithdrawModal";
import { DepositModal } from "components/DepositModal";

const useStyles = makeStyles((theme) => ({
  RowStyle: {
    background: "#2a2a2a",
    "& .MuiTableCell-body": {
      color: "#fff !important",
    },
    "& .buffer-color": {
      background: "#d79b00",
      color: "#fff",
      borderRadius: "8px",
      padding: "3px 6px",
    },
  },
  RowButton: {
    width: "250px !important",
    justifyContent: "space-between",
  },
  TableButton: {
    padding: "4px 8px",
    borderRadius: "5px",
    maxWidth: "unset",
    backgroundColor: "rgb(110 116 123)",
    "&:hover": {
      backgroundColor: "rgb(137 143 150)",
    },
  },
  TableCellText: {
    [theme.breakpoints.down("sm")]: {
      "& p, & div": {
        fontSize: 14,
      },
    },

    [theme.breakpoints.down("xs")]: {
      "& p, & div": {
        fontSize: 12,
      },
    },
  },
}));

function OpenPositionRow({ row, rowProps, positionList }) {
  const classes = useStyles();
  const modalDataAddress = row?.original?.address;
  const { getAmountOut } = useRouterMutations();

  const positionData = useMemo(
    () =>
      positionList?.length > 0
        ? positionList.find((x) => modalDataAddress?.toLowerCase() === x.address.toLowerCase())
        : undefined,
    [modalDataAddress, positionList]
  );

  const {
    lendingToken: { address: lendingTokenAddress, decimal: lendingDecimal },
    address,
  } = positionData;

  const outstanding = useGetTotalOutstanding(address, lendingTokenAddress);
  const outstandingFormat = useMemo(
    () => formatUnits(outstanding || 0, lendingDecimal),
    [lendingDecimal, outstanding]
  );

  const remainingDepositValue = useGetRemainingDeposit(address);
  const inputValueWithBuffer = useMemo(
    () => BigNumber(outstandingFormat).multipliedBy(1 + REPAY_MAX_BUFFER),
    [outstandingFormat]
  );

  const repayAmount = useMemo(
    () => parseUnits(inputValueWithBuffer.toFixed(lendingDecimal) || "0", lendingDecimal),
    [inputValueWithBuffer, lendingDecimal]
  );

  const { data: amountOutData, isLoading: isLoadingAmountOut } = useQuery(
    ["get-amount-out", remainingDepositValue, address, lendingTokenAddress],
    async () => {
      const { amountOut } = await getAmountOut({
        maxAmountIn: remainingDepositValue,
        inputToken: address,
        outputToken: lendingTokenAddress,
      });

      return amountOut;
    }
  );

  const safeTyExit = useMemo(() => {
    if (isLoadingAmountOut || !amountOutData || !repayAmount) return false;
    if (amountOutData.lt(repayAmount)) return true;
    return false;
  }, [isLoadingAmountOut, repayAmount, amountOutData]);

  const {
    isOpen: isOpenDepositModal,
    onOpen: onOpenDepositModal,
    onClose: onCloseDepositModal,
  } = useModalState();

  const {
    isOpen: isOpenCloseModal,
    onOpen: onOpenCloseModal,
    onClose: onCloseCloseModal,
  } = useModalState();

  const {
    isOpen: isOpenWithdrawModal,
    onOpen: onOpenWithdrawModal,
    onClose: onCloseWithdrawModal,
  } = useModalState();

  const {
    isOpen: isOpenRepayModal,
    onOpen: onOpenRepayModal,
    onClose: onCloseRepayModal,
  } = useModalState();

  const handleOpenDepositModal = useCallback(() => {
    onOpenDepositModal();

    ReactGa.event({
      category: "Amplify Dashboard",
      action: "Deposit",
    });
  }, [onOpenDepositModal]);

  const handleOpenWithdrawModal = useCallback(() => {
    onOpenWithdrawModal();

    ReactGa.event({
      category: "Amplify Dashboard",
      action: "Withdraw",
    });
  }, [onOpenWithdrawModal]);

  const handleOpenCloseModal = useCallback(() => {
    onOpenCloseModal();

    ReactGa.event({
      category: "Amplify Dashboard",
      action: "Close",
    });
  }, [onOpenCloseModal]);

  const handleOpenRepayModal = useCallback(() => {
    onOpenRepayModal();

    ReactGa.event({
      category: "Amplify Dashboard",
      action: "Repay",
    });
  }, [onOpenRepayModal]);

  return (
    <>
      <TableRow className={classes.RowStyle} {...rowProps}>
        {row?.cells?.map((column) => (
          <TableCell key={column.column?.id} {...column.getCellProps(column.column.props)}>
            <Box className={classes.TableCellText}>{column.render("Cell")}</Box>
          </TableCell>
        ))}
        <TableCell size="500px">
          <Grid container className={classes.RowButton}>
            <Grid item>
              <Button className={classes.TableButton} onClick={handleOpenDepositModal}>
                Deposit
              </Button>
            </Grid>
            <Grid item>
              <Button className={classes.TableButton} onClick={handleOpenWithdrawModal}>
                Withdraw
              </Button>
            </Grid>
            <Grid item>
              {safeTyExit ? (
                <Button onClick={handleOpenRepayModal} className={classes.TableButton}>
                  Safety Exit
                </Button>
              ) : (
                <Button onClick={handleOpenCloseModal} className={classes.TableButton}>
                  Close
                </Button>
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      {positionData && (
        <>
          <Dialog open={isOpenDepositModal} onClose={onCloseDepositModal} noPadding>
            <DepositModal data={positionData} onClose={onCloseDepositModal} />
          </Dialog>
          <Dialog open={isOpenWithdrawModal} onClose={onCloseWithdrawModal} noPadding>
            <WithdrawModal data={positionData} onClose={onCloseWithdrawModal} />
          </Dialog>
          {positionData?.lendingToken && (
            <>
              <Dialog open={isOpenCloseModal} onClose={onCloseCloseModal} nopadding="true">
                <CloseLeverageModal data={positionData} onClose={onCloseCloseModal} />
              </Dialog>
              <Dialog open={isOpenRepayModal} onClose={onCloseRepayModal} nopadding="true">
                <RepayModal data={positionData} onClose={onCloseRepayModal} />
              </Dialog>
            </>
          )}
        </>
      )}
    </>
  );
}

export default OpenPositionRow;
