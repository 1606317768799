import theme from "../theme";

const MuiContainer = {
  props: {
    disableGutters: true,
  },

  overrides: {
    maxWidthLg: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1370,
      },
    },
  },
};

export default MuiContainer;
