import { constants } from "ethers";
import useWallet from "hooks/useWallet";
import { useQuery } from "react-query";

import { useUniswapV2FactoryContract } from "hooks/contract/core/useUniswapV2FactoryContract";

export const useGetPair = (prjToken, lendingToken) => {
  const { account, chainId, signer } = useWallet();
  const { uniswapV2Factory } = useUniswapV2FactoryContract(signer);

  const pair = useQuery(["get-pair", account, chainId, prjToken, lendingToken], async () => {
    if (!uniswapV2Factory) {
      return constants.AddressZero;
    }
    const res = await uniswapV2Factory.getPair(prjToken, lendingToken);
    return res;
  });

  return pair.data;
};
