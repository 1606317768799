import useWallet from "hooks/useWallet";
import { useCallback, useMemo } from "react";
import { getPrimaryIndexToken } from "utils/ethereum/contracts";
import { useContract } from "wagmi";

export const usePITContract = () => {
  const { chainId, signer } = useWallet();
  const PitInstance = useMemo(() => getPrimaryIndexToken(chainId), [chainId]);
  const contract = useContract({
    address: PitInstance?.address,
    abi: PitInstance?.abi,
    signerOrProvider: signer,
  });

  const depositCall = useCallback(
    async ({ collateralAddress, collateralAmount }) => {
      if (!contract) return;

      const callTx = await contract.deposit(collateralAddress, collateralAmount);

      await callTx.wait();
    },
    [contract]
  );

  const withdrawCall = useCallback(
    async ({ collateralAddress, collateralAmount }) => {
      if (!contract) return;

      const callTx = await contract.withdraw(collateralAddress, collateralAmount);

      await callTx.wait();
    },
    [contract]
  );

  const supplyCall = useCallback(
    async ({ lendingTokenAddress, lendingTokenAmount }) => {
      if (!contract) return;

      const callTx = await contract.supply(lendingTokenAddress, lendingTokenAmount);

      await callTx.wait();
    },
    [contract]
  );

  const redeemUnderlyingCall = useCallback(
    async ({ lendingTokenAddress, lendingTokenAmount }) => {
      if (!contract) return;

      const callTx = await contract.redeemUnderlying(lendingTokenAddress, lendingTokenAmount);

      await callTx.wait();
    },
    [contract]
  );

  const borrowCall = useCallback(
    async ({ lendingTokenAmount, lendingToken }) => {
      if (!contract) return;

      const callTx = await contract.borrow(lendingToken, lendingTokenAmount);

      await callTx.wait();
    },
    [contract]
  );

  return {
    callback: {
      depositCall,
      withdrawCall,
      supplyCall,
      redeemUnderlyingCall,
      borrowCall,
    },
    data: {
      PitInstance,
    },
  };
};
