import { Fragment } from "react";
import { v4 as uuid } from "uuid";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const TableSkeleton = ({ rows }) => {
  const classes = useStyles();

  const SKELETON_STRUCTURE = (
    <Box className={classes.root} px={2} my={1}>
      <Box>
        <Skeleton animation="wave" variant="circle" width={45} height={45} />
      </Box>
      <Box width="25%" mx={1}>
        <Skeleton animation="wave" variant="rect" width="100%" height={40} />
      </Box>
      <Box width="25%" mx={1}>
        <Skeleton animation="wave" variant="rect" width="100%" height={40} />
      </Box>
      <Box width="25%" mx={1}>
        <Skeleton animation="wave" variant="rect" width="100%" height={40} />
      </Box>
      <Box width="25%" mx={1}>
        <Skeleton animation="wave" variant="rect" width="100%" height={40} />
      </Box>
    </Box>
  );

  const rowArray = Array(rows).fill(SKELETON_STRUCTURE);

  return (
    <>
      {rowArray.map((item) => (
        <Fragment key={uuid()}>{item}</Fragment>
      ))}
    </>
  );
};

TableSkeleton.propTypes = {
  rows: PropTypes.number,
};

TableSkeleton.defaultProps = {
  rows: 1,
};

export default TableSkeleton;
