const MuiDialogContent = {
  props: {},

  overrides: {
    root: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};

export default MuiDialogContent;
