import { constants, Contract } from "ethers";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import ReactGa from "react-ga";
import { useMutation, useQueryClient } from "react-query";

import { useEventContractAction } from "context/EventContract/EventContractProvider";
import { useWallet } from "hooks";
import { usePITContractAction } from "hooks/contract/core/usePITContractAction";
import { usePITContract } from "hooks/contract/usePITContract";
import { ERC20TokenABI } from "utils/ethereum/abi";
import { PIT_EVENT } from "utils/ethereum/pitEventConstant";
import { useBorrowContext } from "context/contracts/BorrowContext";

const useLendingTokenMutations = () => {
  const queryClient = useQueryClient();
  const { onEvent } = useEventContractAction();
  const { refetch } = useBorrowContext();
  const { repayByLendingToken } = usePITContractAction();
  const { enqueueSnackbar } = useSnackbar();
  const { account, chainId, signer } = useWallet();
  const {
    callback: { supplyCall, redeemUnderlyingCall, borrowCall },
  } = usePITContract();

  const handleError = useCallback(
    (error) => {
      onEvent(null);
      if (error.message === "userRejectedRequest") {
        enqueueSnackbar("Transaction has been canceled", {
          variant: "warning",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(`Transaction rejected: ${error.message}`.split(/[([]/)[0], {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [enqueueSnackbar, onEvent]
  );

  const approve = useMutation(
    async ({ address, ftoken }) => {
      const contract = new Contract(address, ERC20TokenABI, signer);

      const approved = await contract.approve(ftoken, constants.MaxUint256);
      await approved.wait();
    },
    {
      onSuccess: async () => {
        await refetch();

        queryClient.invalidateQueries(["allowance", account, chainId]);
        queryClient.invalidateQueries(["#User-F-Token-Balance-lending", account, chainId]);

        enqueueSnackbar("Transaction approved!", {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      onError: handleError,
    }
  );

  const supply = useMutation(
    ({ lendingTokenAmount, address }) =>
      supplyCall({ lendingTokenAddress: address, lendingTokenAmount }),
    {
      onSuccess: async () => {
        await refetch();

        await queryClient.invalidateQueries(["#User-F-Token-Balance-lending", account, chainId]);
        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      onError: handleError,
    }
  );

  const redeemUnderlying = useMutation(
    ({ lendingTokenAmount, address }) =>
      redeemUnderlyingCall({
        lendingTokenAddress: address,
        lendingTokenAmount,
      }),
    {
      onSuccess: async () => {
        await refetch();

        await queryClient.invalidateQueries(["#User-F-Token-Balance-lending", account, chainId]);
        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      onError: handleError,
    }
  );

  const borrow = useMutation(
    async ({ lendingTokenAmount, lendingToken }) => {
      await borrowCall({
        lendingToken,
        lendingTokenAmount,
      });
    },
    {
      onSuccess: async () => {
        await refetch();

        queryClient.invalidateQueries(["available-multicall", account]);
        queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        queryClient.invalidateQueries(["allowance", account, chainId]);
        onEvent(PIT_EVENT.Borrow);

        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Borrow success",
        });
      },
      onError: handleError,
    }
  );

  const repay = useMutation(
    async ({ borrowToken, prjAmount }) => {
      await repayByLendingToken({ borrowToken, prjAmount });
    },
    {
      onSuccess: async () => {
        await refetch();

        queryClient.invalidateQueries(["available-multicall", account]);
        queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        queryClient.invalidateQueries(["allowance", account, chainId]);
        onEvent(PIT_EVENT.RepayBorrow);

        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Repay success",
        });
      },
      onError: handleError,
    }
  );

  return {
    isLoading:
      approve.isLoading ||
      supply.isLoading ||
      redeemUnderlying.isLoading ||
      borrow.isLoading ||
      repay.isLoading,
    approve: approve.mutateAsync,
    supply: supply.mutateAsync,
    redeemUnderlying: redeemUnderlying.mutateAsync,
    borrow: borrow.mutateAsync,
    repay: repay.mutateAsync,
  };
};

export default useLendingTokenMutations;
