import { Box, CircularProgress, MenuItem } from "@mui/material";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { constants } from "ethers";
import { useGetPair } from "hooks/atomic/useGetPair";
import { useGetRemainingDeposit } from "hooks/atomic/useGetRemainingDeposit";
import { useGetTotalOutstanding } from "hooks/atomic/useGetTotalOutstanding";
import { useRouterMutations } from "hooks/mutation";
import _get from "lodash/get";
import { useEffect, useMemo, useState } from "react";
import { isEqualLowerString } from "utils/addressUtils";
import { formatUnits } from "utils/number";

function MenuCollateralItem({ projectAsset, classes, handleChange, children, lendingAsset }) {
  const { address: lendingTokenAddress, decimal: lendingDecimal } = lendingAsset;

  const { address: prjTokenAddress } = projectAsset;

  const context = useBorrowContext();

  const remainingDepositValue = useGetRemainingDeposit(prjTokenAddress);
  const { getAmountOut, isLoadingData } = useRouterMutations();
  const pairToken = useGetPair(prjTokenAddress, lendingTokenAddress);

  const outstanding = useGetTotalOutstanding(lendingTokenAddress);
  const outstandingFormat = formatUnits(outstanding || 0, lendingDecimal);

  const owedAmount = outstandingFormat;

  const userLendingTokenInfo = useMemo(
    () => _get(context, ["userTokenInfo"], []).find((o) => o.address === lendingTokenAddress),
    [context, lendingTokenAddress]
  );

  const userLendingBalance = useMemo(
    () => (userLendingTokenInfo ? Number(userLendingTokenInfo.balanceOf) : 0),
    [userLendingTokenInfo]
  );
  const maxValue = Math.min(owedAmount, userLendingBalance);

  const isHavePairToken = useMemo(
    () => !isEqualLowerString(pairToken, constants.AddressZero),
    [pairToken]
  );
  const [maxValueToRepayCollateral, setMaxValueToRepayCollateral] = useState(0);

  const isDisable = useMemo(
    () =>
      !isHavePairToken ||
      +maxValueToRepayCollateral <= 1e-6 ||
      !(maxValue === maxValueToRepayCollateral),
    [isHavePairToken, maxValueToRepayCollateral, maxValue]
  );

  useEffect(() => {
    const fetchData = async () => {
      const { amountOut } = await getAmountOut({
        maxAmountIn: remainingDepositValue,
        inputToken: prjTokenAddress,
        outputToken: lendingTokenAddress,
      });
      const maxFormat = formatUnits(amountOut, lendingDecimal);
      setMaxValueToRepayCollateral(Math.min(maxFormat, maxValue));
    };
    if (
      !remainingDepositValue ||
      !prjTokenAddress ||
      !lendingTokenAddress ||
      !getAmountOut ||
      !isHavePairToken
    )
      return;
    fetchData();
  }, [
    remainingDepositValue,
    prjTokenAddress,
    lendingTokenAddress,
    getAmountOut,
    lendingDecimal,
    isHavePairToken,
    maxValue,
  ]);

  return (
    <MenuItem
      onClick={() =>
        handleChange({
          ...projectAsset,
          maxValueToRepayCollateral,
          maxValue,
          remainingDepositValue,
          owedAmount,
          outstanding,
          outstandingFormat,
          pairToken,
        })
      }
      className={classes.SelectItem}
      name={projectAsset?.symbol}
      disabled={isDisable || isLoadingData}
    >
      <Box sx={{ display: "flex", gap: 1.5, alignItems: "center" }}>
        {children}
        {isLoadingData && (
          <CircularProgress className={classes.circularProgress} size={16} color="warning" />
        )}
      </Box>
    </MenuItem>
  );
}

export default MenuCollateralItem;
