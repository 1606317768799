import PropTypes from "prop-types";

import { Box, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  spinnerWrapper: {
    position: "absolute",
    zIndex: 999,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    left: 0,
    top: 0,
  },
  spinner: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  root: (props) => ({
    color:
      props.color === "default"
        ? theme.palette.error.main
        : theme.palette.success.main,
  }),
}));

const Spinner = ({ position, color }) => {
  const classes = useStyles({ color });

  const spin = <CircularProgress className={classes.root} size={80} />;

  if (position === "absolute") {
    return (
      <div className={classes.spinnerWrapper}>
        <div className={classes.spinner}>{spin}</div>
      </div>
    );
  }

  return (
    <Box my={3} textAlign="center">
      {spin}
    </Box>
  );
};

Spinner.propTypes = {
  position: PropTypes.oneOf(["static", "absolute"]),
  color: PropTypes.oneOf(["default", "success"]),
};

Spinner.defaultProps = {
  position: "static",
  color: "default",
};

export default Spinner;
