export default {
  // lendingTokenInfoMethod: "lendingTokenInfo",
  // limitBorrowInCollateralMethod: "borrowLimitPerCollateral",
  // pitRemainingMethod: "pitRemaining",
  // convertPitRemainingMethod: "convertPitRemaining",
  // pitCollateralMethod: "pitCollateral",
  // positionMethod: "getPosition",
  // totalBorrowedInCollateralMethod: "getTotalBorrowPerCollateral",
  // totalBorrowedInLendingTokenMethod: "getTotalBorrowPerLendingToken",
  // lvrLendingTokenMethod: "lendingTokenLoanToValueRatio",
  convertEstimatedPitRemainingMethod: "convertEstimatedPitRemaining",
  getCollateralAvailableToWithdrawMethod: "getCollateralAvailableToWithdraw",
  depositedAmountMethod: "depositedAmount",
  lendingTokenInfoMethod: "lendingTokenInfo",
  totalEstimatedPITRemainingMethod: "totalEstimatedPITRemaining",
  totalEstimatedOutstandingInUSDMethod: "totalEstimatedOutstandingInUSD",
  totalEstimatedWeightedLoanInUSDMethod: "totalEstimatedWeightedLoanInUSD",
  borrowPositionMethod: "borrowPosition",
  getEstimatedOutstandingInUSDMethod: "getEstimatedOutstandingInUSD",
  tokenEvaluationMethod: "getTokenEvaluation",
  limitBorrowInLendingTokenMethod: "borrowLimitPerLendingToken",
  totalBorrowedPerLendingTokenMethod: "totalBorrowedPerLendingToken",
};
