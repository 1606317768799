import { common } from "../palette";

const MuiDialog = {
  props: {
    open: false,
    disableBackdropClick: true,
  },

  overrides: {
    paper: {
      padding: "35px 20px 30px 20px",
      borderRadius: 8,
      backgroundColor: common.white,
    },
  },
};

export default MuiDialog;
