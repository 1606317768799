import { Multicall } from "ethereum-multicall";
// import { ethers } from "ethers";
import { useWallet } from "hooks";
// import { reduce } from "lodash";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { getPrimaryIndexToken } from "utils/ethereum/contracts";
import { REACT_APP_MULTICALL } from "constants/NetworkChainId";
import { useGetTokens } from "../useTokenSupported";
import { useDecimalToken } from "../useDecimalToken";
import { getDataForBorrowContext } from "./getDataForBorrowContext";
import { prepareRequestForMulticall } from "./prepareRequestForMulticall";

const localChainId = localStorage.getItem("chainId");

const usePrimaryIndexTokenMultiCall = () => {
  const { chainId, account, provider } = useWallet();

  const { availableBorrowTokens, projectTokenList } = useGetTokens();
  const { data: decimalTokens } = useDecimalToken([...availableBorrowTokens, ...projectTokenList]);

  const multicall = useMemo(
    () =>
      new Multicall({
        ethersProvider: provider,
        multicallCustomContractAddress: REACT_APP_MULTICALL[chainId],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [provider]
  );

  return useQuery(
    ["borrowed-pit-multicall", account],
    async () => {
      const listToken = [...projectTokenList];

      const PITToken = getPrimaryIndexToken(chainId ?? localChainId);

      /* A list of requests to PIT contract. */
      const listRequestToPITContract = prepareRequestForMulticall({
        PITToken,
        listToken,
        availableBorrowTokens,
        decimalTokens,
        account,
      });
      const { results } = await multicall.call([listRequestToPITContract]);
      const {
        // currentLimitLendingToken,
        // currentLimitPrjToken,
        // pitRemaining,
        // totalPITCollateral,
        // totalOutstandingAssets,
        // accrualAssets,
        // healthFactor,
        // depositedAssets,
        // fTokenAddresses,
        // liquidationThresholdList,
        // lvrLending,
        evaluations,
        convertEstimatedPitRemaining,
        collateralAvailableToWithdraw,
        depositedAmount,
        fTokenAddresses,
        totalEstimatedPITRemaining,
        totalEstimatedOutstandingInUSD,
        totalEstimatedWeightedLoanInUSD,
        borrowPosition,
        estimatedOutstandingInUSD,
        borrowLimitPerLendingTokenUSD,
        totalBorrowedPerLendingToken,
      } = getDataForBorrowContext(results);
      // const newTotalOutStanding = reduce(
      //   totalOutstandingAssets,
      //   (res, value, key) => {
      //     const newData = {
      //       ...value,
      //       loanBody: ethers.BigNumber.from(value?.value || "0").toString(),
      //       value: ethers.BigNumber.from(value?.value || "0")
      //         .add(accrualAssets[key]?.value || 0)
      //         .toString(),
      //     };
      //     return {
      //       ...res,
      //       [key]: newData,
      //     };
      //   },
      //   {}
      // );

      return {
        availableBorrowTokens,
        // currentLimitLendingToken,
        // currentLimitPrjToken,
        // fTokenAddresses,
        projectTokenList,
        // pitCollateralBalance: totalPITCollateral,
        // pitRemaining,
        // totalOutstandingAssets: newTotalOutStanding,
        // accrualAssets,
        // healthFactor,
        // depositedAssets,
        // liquidationThresholdList,
        // lvrLending,
        evaluations,
        convertEstimatedPitRemaining,
        collateralAvailableToWithdraw,
        depositedAmount,
        fTokenAddresses,
        totalEstimatedPITRemaining,
        totalEstimatedOutstandingInUSD,
        totalEstimatedWeightedLoanInUSD,
        borrowPosition,
        estimatedOutstandingInUSD,
        borrowLimitPerLendingTokenUSD,
        totalBorrowedPerLendingToken,
      };
    },
    {
      enabled:
        !!decimalTokens &&
        !!availableBorrowTokens?.length &&
        !!projectTokenList?.length &&
        !!account,
    }
  );
};

export default usePrimaryIndexTokenMultiCall;
