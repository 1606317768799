import React from "react";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button, Box, Collapse } from "@material-ui/core";

import { useToggle } from "hooks";
import { PaperTitle } from "components";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    position: "relative",
    marginTop: 20,
    marginBottom: 20,
  },

  toggleButton: {
    position: "absolute",
    right: 0,
    top: 20,
    [theme.breakpoints.down("md")]: {
      right: 10,
    },
    [theme.breakpoints.down("sm")]: {
      top: 15,
    },
  },

  button: {
    backgroundColor: "transparent",
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      fontSize: 13,
    },

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const TogglePaper = ({ title, children }) => {
  const classes = useStyles();

  const [show, setShow] = useToggle(true);

  const buttonText = show ? "Hide" : "Show";

  return (
    <Paper className={classes.rootContainer}>
      <Box className={classes.toggleButton}>
        <Button onClick={setShow} className={classes.button} color="inherit">
          {buttonText}
        </Button>
      </Box>
      <PaperTitle>{title}</PaperTitle>
      <Collapse in={show} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Paper>
  );
};

TogglePaper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

TogglePaper.defaultProps = {
  title: "",
};

export default TogglePaper;
