import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/material";
import { DECIMAL_SCALE, EVENT_TYPES } from "app/constants";
import { DialogLogo } from "components/DialogLogo";
import { NumericTextField } from "components/NumericTextField";
import { Spinner } from "components/Spinner";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { useLendingTokenMutations } from "hooks/mutation";
import { useMemo, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { BigNumber, constants } from "ethers";
import _get from "lodash/get";
import { formatUnits, minBigNumber } from "utils/number";
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import useDecimalToken from "hooks/contexts/BorrowContext/useDecimalToken";
import { NumericText } from "components/NumericText";
import { DialogApplyButton } from "components/DialogApplyButton";
import { parseUnits } from "ethers/lib/utils.js";
import { convertNumberHex } from "utils/utils";
import { DialogTotal } from "components/DialogTotal";
import { HealthFactorProgressBar } from "components/HealthFactorProgressBar";
import { converUSDToToken } from "pages/BorrowerNewDashboard/hooks/helper/getDataContract";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },

  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  supplyBox: {
    borderBottom: "1px solid #E0E0E0",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
  },
  supplyRates: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "18px",
  },
  borrowInfo: {
    [theme.breakpoints.down("xs")]: {
      "& p, & span": {
        fontSize: 14,
      },
    },
  },
}));

const BorrowModal = ({ onClose, token, healthFactor, setShareData, openShare }) => {
  const classes = useStyles();
  const ctx = useBorrowContext();
  const [inputValue, setInputValue] = useState("");
  const tokenDecimal = useDecimalToken();
  const usdcToken = localStorage.getItem("usdcToken");
  const [convertEstimatedPitRemaining] = useMemo(
    () => [_get(ctx, ["convertEstimatedPitRemaining"])],
    [ctx]
  );

  const borrowLimitPerLendingTokenUSD = useMemo(
    () => _get(ctx, ["borrowLimitPerLendingTokenUSD"])[token.lendingToken],
    [ctx, token.lendingToken]
  );

  const totalBorrowedPerLendingToken = useMemo(
    () => _get(ctx, ["totalBorrowedPerLendingToken"])[token.lendingToken],

    [ctx, token.lendingToken]
  );

  const evaluations = useMemo(
    () => _get(ctx, ["evaluations"])[token.lendingToken],

    [ctx, token.lendingToken]
  );

  const borrowLimitPerLendingTokenInToken = converUSDToToken(
    BigNumber.from(borrowLimitPerLendingTokenUSD),
    BigNumber.from(evaluations.tokenAmount),
    BigNumber.from(evaluations.evaluation)
  );

  const borrowAvailableRemaning = useMemo(
    () =>
      formatUnits(
        borrowLimitPerLendingTokenInToken.sub(BigNumber.from(totalBorrowedPerLendingToken)),
        token.decimal
      ),
    [borrowLimitPerLendingTokenInToken, token.decimal, totalBorrowedPerLendingToken]
  );
  const borrowAvailableRemaningBN = useMemo(
    () => parseUnits(borrowAvailableRemaning, token?.decimal || 0),
    [borrowAvailableRemaning, token?.decimal]
  );

  const { APY } = useFetchGraphData();

  const [borrowingApy, lendingApy] = useMemo(
    () => [
      [..._get(APY, "borrowing_apy", [])].find((o) => o.lendingTokenAddress === token?.address),
      [..._get(APY, "lender_apy", [])].find((o) => o.lendingTokenAddress === token?.address),
    ],

    [APY, token]
  );

  const totalAvailableToBorrow = useMemo(() => token.totalSupply, [token]);
  const totalAvailableToBorrowBN = useMemo(
    () => parseUnits(totalAvailableToBorrow, token?.decimal || 0),
    [totalAvailableToBorrow, token?.decimal]
  );

  const myMaxAvailable = useMemo(() => {
    const max = !token
      ? formatUnits(convertEstimatedPitRemaining[usdcToken], tokenDecimal[usdcToken])
      : formatUnits(convertEstimatedPitRemaining[token.address], tokenDecimal[token.address]);

    return max;
  }, [token, convertEstimatedPitRemaining, tokenDecimal, usdcToken]);
  const myMaxAvailableBN = useMemo(
    () => parseUnits(myMaxAvailable, token?.decimal || 0),
    [myMaxAvailable, token?.decimal]
  );

  const maxValueBN = minBigNumber([
    borrowAvailableRemaningBN,
    totalAvailableToBorrowBN,
    myMaxAvailableBN,
  ]);
  const maxValue = formatUnits(maxValueBN, token?.decimal || 0);

  const { isLoading, borrow } = useLendingTokenMutations({
    amount: inputValue,
    kind: EVENT_TYPES.borrow,
  });

  const isDisabled = !inputValue || Number(inputValue) === 0 || +inputValue - 0.0001 > maxValue;
  const isFully = Math.abs(Number(inputValue) - Number(myMaxAvailable)) < 1e-6;

  const resetInputValue = () => setInputValue("");
  const handleBorrow = async () => {
    try {
      const paramBorrowAsset = {
        lendingTokenAmount: isFully
          ? constants.MaxUint256.toString()
          : parseUnits(convertNumberHex(inputValue), _get(token, "decimal", 0)),
        lendingToken: _get(token, "address", constants.AddressZero),
      };
      await borrow(paramBorrowAsset);

      setShareData({
        token: token.symbol,
        amount: inputValue,
        apy: borrowingApy?.amount || 0,
      });
      onClose();
      openShare();
    } finally {
      resetInputValue();
    }
  };

  return (
    <>
      <DialogLogo
        logoUrl={token?.logo || "./assets/coins_list/usd-coin.svg"}
        name={token?.symbol}
      />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputValue}
          onChange={setInputValue}
          maxValue={maxValue}
          decimalScale={DECIMAL_SCALE}
        />

        <Box px={2} pb={2} mt={2} className={classes.contentInner}>
          <Box className={classes.supplyBox} style={{ paddingTop: "10px" }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                <Typography>Lend APY</Typography>
              </Grid>
              <Grid item>
                <Typography color="primary">
                  <NumericText value={lendingApy?.amount || 0} precision={2} suffix="%" />
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.supplyBox}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                <Typography>Borrow APY</Typography>
              </Grid>
              <Grid item>
                <Typography color="primary">
                  <NumericText value={borrowingApy?.amount || 0} precision={2} suffix="%" />
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <HealthFactorProgressBar value={healthFactor} />
        </Box>

        <Box className={classes.borrowInfo}>
          <DialogApplyButton disabled={isDisabled} onClick={handleBorrow}>
            Borrow
          </DialogApplyButton>
          <DialogTotal>
            <Grid container justifyContent="space-between">
              {/* {currentBorrowingLevel && (
                <>
                  <Grid item>
                    <Typography color="secondary">Borrowing limit for this collateral</Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="secondary">
                      <NumericText value={currentBorrowingLevel.decimal} moneyValue />
                    </Typography>
                  </Grid>
                </>
              )} */}
            </Grid>
          </DialogTotal>
          <DialogTotal>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography color="secondary">Your Available Borrow: </Typography>
              </Grid>
              <Grid item>
                <Typography color="secondary">
                  <NumericText value={myMaxAvailable} suffix={token.symbol} />
                </Typography>
              </Grid>
            </Grid>
          </DialogTotal>
          <DialogTotal>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography color="secondary">Total Available Borrow: </Typography>
              </Grid>
              <Grid item>
                <Typography color="secondary">
                  <NumericText value={totalAvailableToBorrow} suffix={token.symbol} />
                </Typography>
              </Grid>
            </Grid>
          </DialogTotal>
        </Box>
      </Box>
    </>
  );
};

export default BorrowModal;
