import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { EVENT_TYPES } from "app/constants";
import logoImage from "assets/svg/footer-icon.svg";
import { useWallet } from "hooks";
import { useState } from "react";
import {
  CoinInfo,
  DialogApplyButton,
  DialogLogo,
  DialogTotal,
  NumericText,
  NumericTextField,
  Spinner,
} from "components";
import { useLendingTokenMutations } from "hooks/mutation";
import { roundedNumber } from "utils/roundedNumber";
import { parseUnits } from "ethers/lib/utils.js";
import { convertNumberHex } from "utils/utils";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },
  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
}));

const SupplyModal = ({ data, onClose, openShare, setShareData }) => {
  const {
    name,
    balance,
    allowance,
    allowanceBN,
    lendingAPY,
    symbol,
    logo,
    address,
    fToken: ftoken,
    decimal,
  } = data;

  const classes = useStyles();
  const { connected } = useWallet();
  const [inputValue, setInputValue] = useState("");

  const { isLoading, approve, supply } = useLendingTokenMutations({
    name,
    amount: inputValue,
    kind: EVENT_TYPES.deposit,
  });

  const maxValue = roundedNumber(balance, 2);
  const isDisabled =
    !connected || !inputValue || Number(inputValue) === 0 || Number(inputValue) > Number(maxValue);

  const resetInputValue = () => setInputValue("");

  const handleEnable = () => {
    approve({ address, ftoken });
  };

  const handleSubmit = async () => {
    try {
      const amount = parseUnits(convertNumberHex(inputValue), decimal).toString();
      await supply({
        lendingTokenAmount: amount,
        address,
      });
      onClose();
      setShareData({
        token: data,
        amount: inputValue,
        apy: lendingAPY?.amount,
        colToken: name,
      });
      openShare();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      resetInputValue();
    }
  };

  return (
    <>
      <DialogLogo logoUrl={logo} name={symbol} />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        {allowance ? (
          <NumericTextField
            value={inputValue}
            onChange={setInputValue}
            maxValue={maxValue}
            decimalScale={2}
          />
        ) : (
          <Box m={3}>
            <Typography color="primary">
              To Supply {symbol}, you need to enable it first.
            </Typography>
          </Box>
        )}

        <Box className={classes.contentInner} mt={2}>
          <Box py={2} px={2}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                <CoinInfo logoUrl={logoImage}>Supply APY</CoinInfo>
              </Grid>
              <Grid item>
                <Typography color="primary">
                  <NumericText value={lendingAPY?.amount} suffix="%" />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box>
          {!allowanceBN.isZero() &&
          allowanceBN.gte(parseUnits(convertNumberHex(inputValue), decimal)) ? (
            <DialogApplyButton disabled={isDisabled} onClick={handleSubmit}>
              Deposit
            </DialogApplyButton>
          ) : (
            <DialogApplyButton disabled={!connected} onClick={handleEnable}>
              Enable
            </DialogApplyButton>
          )}
          <DialogTotal title="Wallet Balance" value={balance} currency={symbol} />
        </Box>
      </Box>
    </>
  );
};

export default SupplyModal;
