import { Box, makeStyles } from "@material-ui/core";
import { AmplifyMarginLeverage } from "components/MarginLeverage/AmplifyMarginLeverage";
import { MarginTradeMarginLeverage } from "components/MarginLeverage/MarginTradeMarginLeverage";
import { SafetyBuffer } from "components/SafetyBuffer/SafetyBuffer";
import { TotalExposure } from "components/TotalExposure/TotalExposure";
import { Amplification } from "pages/AmplifyDashboard/components/Amlification";

const useStyles = makeStyles((theme) => ({
  BoxStyle: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    background: "#212222",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",

    "&.amplifyFormItem": {
      padding: "15px 24px",
      paddingBottom: "20px",
      paddingRight: "60px",

      [theme.breakpoints.down("md")]: {
        paddingRight: 24,
      },
    },

    "&.marginTradeFormItem": {
      padding: "15px 10px",
      paddingBottom: "20px",
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export const SpecifyMargin = () => {
  const classes = useStyles();
  const { pathname } = window.location;
  const isAmplifyPage = pathname === "/amplify";

  return (
    <Box className={[classes.BoxStyle, isAmplifyPage ? "amplifyFormItem" : "marginTradeFormItem"]}>
      {isAmplifyPage ? (
        <>
          <AmplifyMarginLeverage />
          <Amplification />
          <TotalExposure />
        </>
      ) : (
        <>
          <MarginTradeMarginLeverage />
          <SafetyBuffer />
        </>
      )}
    </Box>
  );
};
