import { success, info } from "theme/palette";

const MuiTabs = {
  props: {},
  overrides: {
    root: {
      position: "relative",
      "&:after": {
        content: "''",
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "2px",
        backgroundColor: info.light,
        zIndex: 1,
      },
    },

    indicator: {
      backgroundColor: success.main,
      zIndex: 2,
    },
  },
};

export default MuiTabs;
