import { useEffect } from "react";
import ReactGa from "react-ga";

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

const Analytics = () => {
  useEffect(() => {
    if (GOOGLE_KEY) {
      const {
        location: { pathname, search },
      } = window;

      ReactGa.initialize(GOOGLE_KEY);
      ReactGa.pageview(`${pathname}${search}`);
    }
  }, []);

  return null;
};
export default Analytics;
