import BigNumber from "bignumber.js";
import { formatUnits } from "utils/number";
import { get, isNaN, pick } from "lodash";
import { createContext, useEffect, useMemo, useReducer, useState } from "react";

import { BorrowContextProvider } from "context/contracts/BorrowContextProvider";
import useAllBorrowData from "hooks/contexts/BorrowContext/useAllBorrowData";
import usePriceLendingToken from "hooks/contexts/LendingAssetContext/usePriceLendingToken";
import loadingReducer, { LoadingStatus } from "utils/reducers/loadingReducer";
import { useFetchLeverageBorrowData } from "hooks/query/graphQL/useFetchLeveragedBorrowData";
import { MIN_AMPLIFY } from "constants/contract";
import { useWallet } from "hooks";
import { getExposureLimit } from "utils/ethereum/getExposureLimit";

function getBaseLog(x, y) {
  return Math.log10(y) / Math.log10(x);
}

export const LeverageContext = createContext();

const useLeveragePositions = (collaterals = []) =>
  useMemo(
    () =>
      collaterals.map((collateral) => {
        const collateralData = pick(collateral, ["symbol", "balance", "address", "decimal"]);

        const notionalExp = new BigNumber(
          formatUnits(
            get(collateral, "loanBodyBN", "0"),
            get(collateral, ["lendingTokenData", "decimal"], "0")
          )
        )
          .multipliedBy(get(collateral, ["lendingTokenData", "price"], "0"))
          .toString();

        const margin = new BigNumber(
          formatUnits(get(collateral, "depositedAmountBN", 0), get(collateral, ["decimal"], "0"))
        )
          .multipliedBy(get(collateral, ["price"], "0"))
          .minus(notionalExp)
          .toString();

        return { ...collateralData, margin, notionalExp };
      }),
    [collaterals]
  );

const ContextProvider = ({ children }) => {
  const [shortAssetAddress, setShortAssetAddress] = useState();
  const [longAssetAddress, setLongAssetAddress] = useState();
  const hideZero = useState(true);
  const [margin, setMargin] = useState("");
  const [notionalExp, setNotionalExp] = useState("");
  const [safeBuffer, setSafeBuffer] = useState("");
  const [amplification, setAmplification] = useState(MIN_AMPLIFY);
  const [estDayLiquidation, dispatchEstDayLiquidation] = useReducer(loadingReducer, {
    data: null,
    isLoading: false,
    error: null,
  });
  const [resetForm, setResetForm] = useState(false);
  const { laveragedBorrowList, refetch: refetchLaveragedBorrowList } = useFetchLeverageBorrowData();
  const [exposureLimit, setExposureLimit] = useState(null);
  const { chainId, signer } = useWallet();

  const resetStates = () => {
    setResetForm(!resetForm);
    setAmplification(MIN_AMPLIFY);
    setMargin("");
    setNotionalExp("");
    setSafeBuffer("");
  };

  const tokenAllData = useAllBorrowData();
  const priceOfLendingToken = usePriceLendingToken();

  // #region Calculated values, using for both Amplify page and Margin Trade page
  const lendingList = useMemo(() => {
    const tokenList = get(tokenAllData, ["lendingList"], []).map((o) => ({
      ...o,
      price: priceOfLendingToken[o.address],
    }));
    return tokenList;
  }, [priceOfLendingToken, tokenAllData]);

  const leveragePositions = useLeveragePositions(
    get(tokenAllData, ["collateralList"], [])
      .filter((token) => token?.isLeverage)
      .map((p) => ({
        ...p,
        lendingTokenData: {
          ...p.lendingTokenData,
          price: get(priceOfLendingToken, [p.lendingToken], 0),
        },
      }))
  );

  const collateralList = useMemo(() => {
    const tokenList = get(tokenAllData, ["collateralList"], []).filter(
      (token) => token?.isLeverage || (!token?.isLeverage && !+token?.outstanding)
    );
    return !hideZero[0] ? tokenList : tokenList.filter((item) => +item.balance > 0);
  }, [hideZero, tokenAllData]);

  const longAssetSelected = useMemo(() => {
    if (!collateralList || collateralList?.length === 0) {
      return collateralList[0];
    }

    const selectedLongAsset = collateralList.find((x) => x.address === longAssetAddress);

    if (hideZero[0]) {
      return selectedLongAsset?.balance ? selectedLongAsset : collateralList[0];
    }

    return selectedLongAsset || collateralList[0];
  }, [collateralList, longAssetAddress, hideZero]);

  const shortAssetSelected = useMemo(() => {
    if (!lendingList || lendingList?.length === 0) {
      return lendingList[0];
    }

    const selectedShortAsset = lendingList.find((x) => x.address === shortAssetAddress);

    return selectedShortAsset || lendingList[0];
  }, [shortAssetAddress, lendingList]);

  const [minMargin, maxMargin] = useMemo(() => {
    const deposited = get(longAssetSelected, ["depositedAmount"], 0);
    const balance = get(longAssetSelected, ["balance"], 0);
    const price = get(longAssetSelected, ["price"], 0);

    const minMarginBN = new BigNumber(deposited).multipliedBy(price);
    const maxMarginBN = new BigNumber(deposited).plus(balance).multipliedBy(price);

    return [
      (minMarginBN.multipliedBy(100).toNumber() / 100).toFixed(6),
      (maxMarginBN.multipliedBy(100).toNumber() / 100).toFixed(6),
    ];
  }, [longAssetSelected]);

  const maxAmplification = useMemo(() => {
    const lvr = get(longAssetSelected, "lvr", 0);
    return new BigNumber(1).dividedBy(new BigNumber(1).minus(lvr)).toString();
  }, [longAssetSelected]);
  // #endregion

  /**
   * Calculate estimated day liquidation on worker
   */
  useEffect(() => {
    const borrowRate = shortAssetSelected?.borrowRate || 0;

    if (!safeBuffer || !+safeBuffer || !+borrowRate) {
      dispatchEstDayLiquidation({
        type: LoadingStatus.SUCCESS,
        payload: 0,
      });
      return;
    }

    const borrowRatePerBlock = formatUnits(borrowRate, 18);

    const worker = new Worker("workers/estimateDayLiquidationWorker.js");

    worker.onmessage = (event) => {
      const baseX = event.data;
      let est = getBaseLog(+baseX, 1 + (isNaN(+safeBuffer) ? 0 : +safeBuffer));

      if (!safeBuffer || !+safeBuffer || !+borrowRate || new BigNumber(est).lt(0)) {
        est = 0;
      }

      dispatchEstDayLiquidation({
        type: LoadingStatus.SUCCESS,
        payload: est,
      });

      worker.terminate();
    };

    dispatchEstDayLiquidation({
      type: LoadingStatus.START,
    });
    worker.postMessage({ borrowRatePerBlock });
  }, [shortAssetSelected?.borrowRate, safeBuffer]);

  const isMarginInvalid = useMemo(() => {
    if (margin === "") return true;
    const marginBN = new BigNumber(margin);
    return marginBN.lt(minMargin) || marginBN.gt(maxMargin);
  }, [maxMargin, minMargin, margin]);

  useEffect(() => {
    if (longAssetSelected?.address && shortAssetSelected?.address) {
      (async () => {
        const result = await getExposureLimit(
          longAssetSelected.address,
          shortAssetSelected.address,
          {
            chainId,
            signer,
          }
        );
        if (result) {
          const parsedExposureLimit = formatUnits(result.toString(), shortAssetSelected?.decimal);
          setExposureLimit(parsedExposureLimit);
        } else {
          setExposureLimit(null);
        }
      })();
    }
  }, [
    chainId,
    longAssetSelected?.address,
    shortAssetSelected?.address,
    shortAssetSelected?.decimal,
    signer,
  ]);

  const contextValue = {
    margin: [margin, setMargin],
    hideZero,
    setShortAssetAddress,
    amplification: [amplification, setAmplification],
    setLongAssetAddress,
    collateralList,
    longAssetSelected,
    notionalExp,
    safeBuffer,
    shortAssetSelected,
    lendingList,
    minMargin,
    maxMargin,
    maxAmplification,
    estDayLiquidation,
    leveragePositions,
    isMarginInvalid,
    resetStates,
    resetForm,
    laveragedBorrowList,
    refetchLaveragedBorrowList,
    setNotionalExp,
    setSafeBuffer,
    exposureLimit,
  };

  return <LeverageContext.Provider value={contextValue}>{children}</LeverageContext.Provider>;
};

export const LeverageContextProvider = ({ children }) => (
  <BorrowContextProvider>
    <ContextProvider>{children}</ContextProvider>
  </BorrowContextProvider>
);
