import { Grid, makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";
import { NumericText } from "components";
import { IconInfo } from "components/Leveraged/IconInfo";
import { Text } from "components/Title/Text";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import usePriceLendingToken from "hooks/contexts/LendingAssetContext/usePriceLendingToken";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: "14px",
    color: "#fff",
    fontWeight: 500,
  },
  rowStyle: {
    flexWrap: "wrap",
    marginTop: "10px",
  },
}));
export const ShortAssetDash = () => {
  const classes = useStyles();
  const { shortAssetSelected } = useLeverageContext();
  const lendingTokenPrice = usePriceLendingToken();

  return (
    <Box marginBottom={2} className={classes.text}>
      <Grid container display="flex" className={classes.rowStyle}>
        <Grid item xs={12}>
          <Box display="flex" gridGap={2}>
            <Text>Price</Text>
            <IconInfo title="Current price of the long asset / short asset." />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <NumericText moneyValue value={lendingTokenPrice[shortAssetSelected?.address]} />
        </Grid>
      </Grid>
    </Box>
  );
};
