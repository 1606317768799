import PropTypes from "prop-types";

import { Box, Grid, LinearProgress, Typography } from "@material-ui/core";

import { NumericText } from "components";

const ProgressBar = ({ value, label, color }) => (
  <Box>
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography color="primary">{label}</Typography>
      </Grid>
      <Grid item>
        <Typography color="primary">
          <NumericText value={value} suffix="%" />
        </Typography>
      </Grid>
    </Grid>
    <Box mt={1}>
      <LinearProgress value={value} color={color} />
    </Box>
  </Box>
);

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
};

ProgressBar.defaultProps = {
  color: "primary",
};

export default ProgressBar;
