import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",

    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
}));

const Title = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography variant="h4" color="secondary" className={classes.title}>
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Title;
