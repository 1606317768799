import { DEFAULT_GAS_LIMIT } from "constants/contract";
import { BigNumber, Contract } from "ethers";
import useWallet from "hooks/useWallet";
import React, { useCallback } from "react";
import { getPrimaryIndexToken } from "utils/ethereum/contracts";

export const usePITContractAction = () => {
  const { chainId, account, signer } = useWallet();

  const PITContract = React.useMemo(() => {
    if (!signer) return null;
    const contractInfo = getPrimaryIndexToken(chainId);

    const smc = new Contract(contractInfo.address, contractInfo.abi, signer);
    return smc;
  }, [chainId, signer]);

  const repayByLendingToken = useCallback(
    async ({ borrowToken, prjAmount }) => {
      if (!PITContract?.address || !account) {
        return;
      }

      const estimatedGasLimit = await PITContract.estimateGas.repay(
        borrowToken,
        prjAmount.toString()
      );

      const gasLimit = estimatedGasLimit.gte(DEFAULT_GAS_LIMIT)
        ? estimatedGasLimit
        : BigNumber.from(DEFAULT_GAS_LIMIT);

      const tx = await PITContract.functions.repay(borrowToken, prjAmount.toString(), {
        gasLimit,
      });
      await tx.wait();
    },
    [PITContract, account]
  );

  return { PITContract, repayByLendingToken };
};
