export const primary = {
  main: "#000",
  dark: "#000",
  light: "#6E747B",
};

export const secondary = {
  main: "#ec333b",
  dark: "#C64248",
};

export const warning = {
  main: "#F2AC57",
};

export const info = {
  main: "#DBE2EA",
  light: "#EBF4F8",
};

export const success = {
  main: "#14A38B",
  light: "#27AE60",
  dark: "#0e7261",
};

export const error = {
  main: "#FF7171",
};

export const common = {
  white: "#ffffff",
  black: "#000000",
};

export const text = {
  primary: "#2C2738",
};

export const grey = {
  100: "#f2f2f2",
};

const palette = {
  primary,
  secondary,
  warning,
  info,
  common,
  success,
  error,
  text,
  grey,
};

export default palette;
