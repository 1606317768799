import { Grid, makeStyles } from "@material-ui/core";
import BigNumber from "bignumber.js";
import { TitleLeverage } from "components/Leveraged/TitleLeverage";
import { Text } from "components/Title/Text";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import numeral from "numeral";
import { useGetLongInfoAsset } from "pages/AmplifyDashboard/hook/useGetInfoAsset";
import { useMemo } from "react";

const useStyles = makeStyles(() => ({
  BoxStyle: {
    marginTop: "8px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "start",
    rowGap: "10px",
  },
  NumberRow: {
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between",
  },
  ExposureTextRowRight: {
    display: "flex",
    maxWidth: "100%",
    alignItems: "center",
    justifyContent: "right",
  },
  ExposureTextRowLeft: {
    display: "flex",
    maxWidth: "100%",
    alignItems: "center",
    justifyContent: "left",
    paddingRight: "10px",
  },
}));
export const TotalExposure = () => {
  const classes = useStyles();
  const { symbol, price } = useGetLongInfoAsset();
  const {
    amplification: [amplification],
    margin: [margin],
    longAssetSelected,
  } = useLeverageContext();

  const exposureUsdBN = useMemo(
    () => new BigNumber(amplification || 0).multipliedBy(margin || 0),
    [amplification, margin]
  );

  const formatedExposureUsd = useMemo(
    () => numeral(exposureUsdBN).format("0,0.[00]"),
    [exposureUsdBN]
  );

  const exposureAmountUsd = useMemo(() => {
    const exposureAmountUsdBN = exposureUsdBN.dividedBy(price);
    return numeral(exposureAmountUsdBN).format("0,0.[0000]");
  }, [exposureUsdBN, price]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={7} md={12} className={classes.BoxStyle}>
        <TitleLeverage
          title={`Total ${symbol} exposure`}
          helptext="Total exposure to the long asset resulting from opening this trade."
        />
      </Grid>
      <Grid
        xs={12}
        sm={7}
        md={12}
        item
        container
        alignItems="center"
        justifyContent="center"
        className={classes.BoxStyle}
      >
        <Grid item xs={6} className={classes.ExposureTextRowLeft}>
          <Text>{longAssetSelected ? `$${formatedExposureUsd}` : "N/A"}</Text>
        </Grid>
        <Grid item xs={6} className={classes.ExposureTextRowRight}>
          <Text>{longAssetSelected ? `${exposureAmountUsd} ${symbol}` : "N/A"}</Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
