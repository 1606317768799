import { get } from "lodash";
import { useBorrowContext } from "context/contracts/BorrowContext";

const useDecimalToken = () => {
  const context = useBorrowContext();

  const decimalToken = get(context, "decimalOfContractToken", {});

  return decimalToken;
};

export default useDecimalToken;
