import { useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Table, CoinInfo, NumericText } from "components";
import tokenIcon from "assets/prj-coin.png";
import { logoBySymbol } from "constants/TokenListGoerliNetwork";

const useStyles = makeStyles(() => ({
  table: {
    marginLeft: 30,
    marginRight: 30,
    width: "100%",
    "& tr": { height: 60 },
    "& thead > tr": {
      verticalAlign: "middle",

      "& > th": {
        borderTop: "none",
      },
    },
    "& tbody td": {
      borderBottom: "none",
      fontWeight: 500,

      "&:nth-child(1), &:nth-child(2)": {
        minWidth: 130,
      },
    },
  },
  values: {
    fontWeight: 500,
  },
}));
const TokensTable = ({ data }) => {
  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <Box>
            <Tooltip title="List of collateral assets accepted." arrow placement="top">
              <Box display="inline">Token</Box>
            </Tooltip>
          </Box>
        ),
        accessor: "name",
        Cell: ({
          // value,
          row: {
            original: { logo, underlyingTokens, symbol },
          },
        }) => (
          <CoinInfo
            logoUrl={underlyingTokens.length ? "" : logo ?? tokenIcon}
            isLPToken={underlyingTokens.length > 0}
            logoLPOne={underlyingTokens.length ? logoBySymbol[underlyingTokens[0].symbol] : ""}
            logoLPTwo={underlyingTokens.length ? logoBySymbol[underlyingTokens[1].symbol] : ""}
          >
            {underlyingTokens.length
              ? `${underlyingTokens[0].symbol}/${underlyingTokens[1].symbol}`
              : symbol}
          </CoinInfo>
        ),
      },
      {
        Header: () => (
          <Box>
            <Tooltip
              title="Total platform deposited for this collateral asset."
              arrow
              placement="top"
            >
              <Box display="inline">Deposited</Box>
            </Tooltip>
          </Box>
        ),
        accessor: "depositedAmount",
        Cell: ({
          value,
          row: {
            original: { symbol },
          },
        }) => (
          <Typography color="secondary" className={classes.values}>
            <NumericText value={value} suffix={symbol} />
          </Typography>
        ),
      },
      {
        Header: () => (
          <Box>
            <Tooltip
              title="Loan to Value Ratio for the collateral asset. The maximum borrowing capacity for a given collateral deposit = collateral value * LVR. Each collateral asset has its own LVR."
              arrow
              placement="top"
            >
              <Box display="inline">LVR</Box>
            </Tooltip>
          </Box>
        ),
        accessor: "lvr",
        Cell: ({ value }) => (
          <Typography color="secondary" className={classes.values}>
            <NumericText value={value} precision={1} suffix="%" />
          </Typography>
        ),
      },
      {
        Header: () => (
          <Box>
            <Tooltip
              title={
                <div>
                  <p>
                    Total platform value of borrowing capacity of all deposits of this collateral
                    asset. The maximum borrowing capacity for a given collateral deposit =
                    collateral value * LVR.
                  </p>
                  <p>
                    One PIT (Project Index Token) = one US dollar. PIT tokens are not transferrable.
                  </p>
                </div>
              }
              arrow
              placement="top"
            >
              <Box display="inline">PIT</Box>
            </Tooltip>
          </Box>
        ),
        accessor: "pitAmount",
        Cell: ({ value }) => (
          <Typography color="secondary" className={classes.values}>
            <NumericText value={value} />
          </Typography>
        ),
      },
      {
        Header: () => (
          <Box>
            <Tooltip
              title="Total platform value of borrow for this asset. (excludes accrued interest)"
              arrow
              placement="top"
            >
              <Box display="inline">Borrowed</Box>
            </Tooltip>
          </Box>
        ),
        accessor: "borrowedAmount",
        Cell: ({ value }) => (
          <Typography color="secondary" className={classes.values}>
            <NumericText value={value} moneyValue />
          </Typography>
        ),
      },
      {
        Header: () => (
          <Box>
            <Tooltip
              title="Total platform value of outstanding loans for this asset. (includes accrued interest)"
              arrow
              placement="top"
            >
              <Box display="inline">Outstanding</Box>
            </Tooltip>
          </Box>
        ),
        accessor: "outstandingAmount",
        Cell: ({ value }) => (
          <Typography color="secondary" className={classes.values}>
            <NumericText value={value} moneyValue />
          </Typography>
        ),
      },
      {
        Header: () => (
          <Box>
            <Tooltip
              title="Platform-wide borrowing of this asset capped to this amount. Also shows percentage of this limit consumed by current outstanding loans. i.e. Outstanding / Cap."
              arrow
              placement="top"
            >
              <Box display="inline">Borrowing level</Box>
            </Tooltip>
          </Box>
        ),
        accessor: "borrowingLevelAmount",
        Cell: ({
          value,
          row: {
            original: { currentBorrowingLevel },
          },
        }) => (
          <>
            <Typography color="secondary" className={classes.values}>
              <NumericText value={value} moneyValue />
            </Typography>
            <Typography color="secondary" className={classes.values}>
              <NumericText value={currentBorrowingLevel * 100} suffix="%" />
            </Typography>
          </>
        ),
      },
    ],
    [classes.values]
  );

  return <Table columns={columns} data={data} className={classes.table} />;
};

export default TokensTable;
