import { gql, useQuery as useClientQuery } from "@apollo/client";
import { logoBySymbol } from "constants/TokenListGoerliNetwork";
import { useWallet } from "hooks";
import { get, groupBy } from "lodash";

const GET_PRJ_TOKEN = gql`
  query ExampleQuery($account: String!) {
    projectTokens {
      name
      symbol
      address
      underlyingTokens {
        id
        name
        symbol
        address
        linksNumber
      }
    }
    lendingTokens {
      name
      symbol
      address
    }
    borrowLogs(where: { userAddress: $account }) {
      id
      amount
      asset
      prjToken
      type
      date
      userAddress
      prjTokenAddress
    }
  }
`;

export const useGetTokens = () => {
  const { account } = useWallet();

  const { data, refetch } = useClientQuery(GET_PRJ_TOKEN, {
    variables: { account },
  });
  return {
    projectTokenList: get(data, ["projectTokens"], []).map((o) => ({
      ...o,
      type: "projectToken",
      logo:
        logoBySymbol[o.symbol] || "https://s2.coinmarketcap.com/static/img/coins/64x64/3267.png",
      underlyingTokens: o.underlyingTokens,
    })),
    updateDataGraph: refetch,
    borrowLogs: groupBy(get(data, ["borrowLogs"]), "prjTokenAddress"),
    availableBorrowTokens: get(data, ["lendingTokens"], []).map((o) => ({
      ...o,
      type: "lendingToken",
      logo: logoBySymbol[o.symbol],
    })),
  };
};
