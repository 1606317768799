import { secondary, common } from "theme/palette";

const MuiBreadcrumbs = {
  props: {},
  overrides: {
    root: {
      color: secondary.main,
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: 18,
    },
    separator: {
      color: common.white,
    },
  },
};

export default MuiBreadcrumbs;
