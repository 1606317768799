import { EVENT_TYPES } from "app/constants";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { coinPropType } from "types/coin";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  DialogApplyButton,
  DialogLogo,
  DialogTotal,
  HealthFactorProgressBar,
  NumericTextField,
  Spinner,
} from "components";

import { parseUnits } from "ethers/lib/utils";
import { useWallet } from "hooks";
import { useCoinMutations } from "hooks/mutation";
import get from "lodash/get";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { convertNumberHex } from "utils/utils";
import { constants } from "ethers";
import { formatUnits } from "utils/number";
import useDecimalToken from "hooks/contexts/BorrowContext/useDecimalToken";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },
  dialogStyled: {
    width: "100%",
    backgroundColor: "#333333",
    padding: "10px 0px",
  },
  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  dialogTotal: {
    padding: "0px !important",
  },
  withdrawInfo: {
    [theme.breakpoints.down("xs")]: {
      "& p, & span": {
        fontSize: 14,
      },
    },
  },
}));

const WithdrawModal = ({ data, onClose }) => {
  const {
    name,
    logo,
    allowance,
    address: prjTokenAddress,
    symbol,
    prjAmount,
    healthFactor,
    lendingToken,
  } = data;

  const ctx = useBorrowContext();
  const collateralAvailableToWithdraw = get(ctx, "collateralAvailableToWithdraw", {});

  const tokenDecimal = useDecimalToken();

  const decimalToken = useMemo(
    () => get(ctx, ["decimalOfContractToken", prjTokenAddress], 0),
    [ctx, prjTokenAddress]
  );

  const classes = useStyles();
  const { connected } = useWallet();
  const [inputValue, setInputValue] = useState("");

  const { isLoading, withdraw } = useCoinMutations({
    projectTokenAddress: prjTokenAddress,
    name,
    amount: inputValue,
    kind: EVENT_TYPES.withdraw,
  });

  const maxValue = useMemo(() => {
    const value = !lendingToken
      ? Object.values(collateralAvailableToWithdraw[prjTokenAddress])[0]
      : collateralAvailableToWithdraw[prjTokenAddress][lendingToken.address];

    return formatUnits(value, tokenDecimal[prjTokenAddress]);
  }, [lendingToken, collateralAvailableToWithdraw, prjTokenAddress, tokenDecimal]);

  const isDisabled =
    !connected ||
    !inputValue.toString().length ||
    !Number(inputValue) ||
    Number(inputValue) > Number(maxValue);

  const resetInputValue = () => {
    setInputValue("");
  };

  const isWithdrawAll = useMemo(
    () => Math.abs(+inputValue - maxValue) < 1e-5,
    [inputValue, maxValue]
  );

  const handleWithdraw = async () => {
    const paramWithdraw = {
      value: isWithdrawAll
        ? constants.MaxUint256.toString()
        : parseUnits(convertNumberHex(inputValue), decimalToken),
      _projectTokenAddress: prjTokenAddress,
    };

    await withdraw(paramWithdraw);
    onClose();
    resetInputValue();
  };
  const roundDown = (number, decimals) => Math.floor(number * 10 ** decimals) / 10 ** decimals;

  return (
    <>
      <DialogLogo logoUrl={logo} name={name} />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField value={inputValue} onChange={setInputValue} maxValue={maxValue} />

        {allowance && (
          <Box px={2} py={2} mt={2} className={classes.contentInner}>
            <HealthFactorProgressBar value={healthFactor} />
          </Box>
        )}

        <Box className={classes.withdrawInfo}>
          {Number(maxValue) > 0 ? (
            <DialogApplyButton disabled={isDisabled} onClick={handleWithdraw}>
              Withdraw
            </DialogApplyButton>
          ) : (
            <DialogApplyButton disabled>No balance to withdraw</DialogApplyButton>
          )}
          <Box py={2} className={classes.dialogStyled}>
            <DialogTotal
              title="Available To Withdraw"
              value={roundDown(maxValue, 2)}
              currency={symbol}
              type="withdraw"
            />
            <DialogTotal
              title="Currently Supplying"
              value={prjAmount}
              currency={symbol}
              type="withdraw"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

WithdrawModal.propTypes = {
  data: coinPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WithdrawModal;
