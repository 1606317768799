/* eslint-disable no-unused-vars */
import { parseUnits } from "ethers/lib/utils.js";
import methodOnPITContract from "./methodOfPitContract";

const {
  lendingTokenInfoMethod,
  limitBorrowInCollateralMethod,
  pitCollateralMethod,
  pitRemainingMethod,
  convertPitRemainingMethod,
  positionMethod,
  totalBorrowedInCollateralMethod,
  totalBorrowedInLendingTokenMethod,
  lvrLendingTokenMethod,
  tokenEvaluationMethod,
  getCollateralAvailableToWithdrawMethod,
  convertEstimatedPitRemainingMethod,
  depositedAmountMethod,
  totalEstimatedPITRemainingMethod,
  totalEstimatedOutstandingInUSDMethod,
  totalEstimatedWeightedLoanInUSDMethod,
  borrowPositionMethod,
  getEstimatedOutstandingInUSDMethod,
  limitBorrowInLendingTokenMethod,
  totalBorrowedPerLendingTokenMethod,
} = methodOnPITContract;

export function prepareRequestForMulticall({
  PITToken,
  listToken: collateralTokens,
  availableBorrowTokens: lendingTokens,
  decimalTokens,
  account,
}) {
  const listRequestToPITContract = {
    contractAddress: PITToken.address,
    abi: [...PITToken.abi].filter(
      (a) =>
        [
          lendingTokenInfoMethod,
          // limitBorrowInCollateralMethod,
          // pitCollateralMethod,
          // pitRemainingMethod,
          // convertPitRemainingMethod,
          // positionMethod,
          // totalBorrowedInCollateralMethod,
          // totalBorrowedInLendingTokenMethod,
          // lvrLendingTokenMethod,
          tokenEvaluationMethod,
          getCollateralAvailableToWithdrawMethod,
          convertEstimatedPitRemainingMethod,
          depositedAmountMethod,
          totalEstimatedPITRemainingMethod,
          totalEstimatedOutstandingInUSDMethod,
          totalEstimatedWeightedLoanInUSDMethod,
          borrowPositionMethod,
          getEstimatedOutstandingInUSDMethod,
          limitBorrowInLendingTokenMethod,
          totalBorrowedPerLendingTokenMethod,
        ].includes(a?.name) && a?.type === "function"
    ),
    reference: "PITContract",
    calls: [
      {
        methodParameters: [account],
        methodName: totalEstimatedPITRemainingMethod,
        reference: totalEstimatedPITRemainingMethod,
      },
      {
        methodParameters: [account],
        methodName: totalEstimatedOutstandingInUSDMethod,
        reference: totalEstimatedOutstandingInUSDMethod,
      },
      {
        methodParameters: [account],
        methodName: totalEstimatedWeightedLoanInUSDMethod,
        reference: totalEstimatedWeightedLoanInUSDMethod,
      },
    ],
  };

  collateralTokens.forEach((prjToken) => {
    listRequestToPITContract.calls.push(
      {
        methodParameters: [account, prjToken.address],
        methodName: depositedAmountMethod,
        reference: prjToken.address,
      },

      ...lendingTokens.map((o) => ({
        methodParameters: [account, prjToken.address],
        methodName: getCollateralAvailableToWithdrawMethod,
        reference: o.symbol,
      })),

      {
        methodParameters: [prjToken.address, parseUnits("1.0", decimalTokens[prjToken.address])],
        methodName: tokenEvaluationMethod,
        reference: prjToken.address,
      }

      //   ...lendingTokens.map((o) => ({
      //     methodParameters: [account, o.address],
      //     methodName: convertPitRemainingMethod,
      //     reference: o.symbol,
      //   })),

      //   ...lendingTokens.map((o) => ({
      //     methodParameters: [account, prjToken.address, o.address],
      //     methodName: positionMethod,
      //     reference: o.symbol,
      //   })),

      //   ...lendingTokens.map((o) => ({
      //     methodParameters: [account, prjToken.address, o.address],
      //     methodName: pitRemainingMethod,
      //     reference: o.symbol,
      //   })),

      //   {
      //     methodParameters: [account, prjToken.address],
      //     methodName: pitCollateralMethod,
      //     reference: prjToken.address,
      //   },
      //   {
      //     methodParameters: [prjToken.address],
      //     methodName: totalBorrowedInCollateralMethod,
      //     reference: prjToken.address,
      //   },
      //   {
      //     methodParameters: [prjToken.address],
      //     methodName: limitBorrowInCollateralMethod,
      //     reference: prjToken.address,
      //   },
    );
  });

  lendingTokens.forEach((token) => {
    listRequestToPITContract.calls.push(
      {
        methodParameters: [token.address],
        methodName: lendingTokenInfoMethod,
        reference: token.address,
      },
      {
        methodParameters: [account, token.address],
        methodName: convertEstimatedPitRemainingMethod,
        reference: token.symbol,
      },
      {
        reference: `${token.symbol}`,
        methodName: borrowPositionMethod,
        methodParameters: [account, token.address],
      },
      {
        reference: `${token.symbol}`,
        methodName: getEstimatedOutstandingInUSDMethod,
        methodParameters: [account, token.address],
      },
      {
        reference: token.address,
        methodName: limitBorrowInLendingTokenMethod,
        methodParameters: [token.address],
      },
      {
        reference: token.address,
        methodName: totalBorrowedPerLendingTokenMethod,
        methodParameters: [token.address],
      },
      {
        methodParameters: [token.address, parseUnits("1.0", decimalTokens[token.address])],
        methodName: tokenEvaluationMethod,
        reference: token.address,
      }
    );
    // {
    //   methodParameters: [token.address],
    //   methodName: totalBorrowedInLendingTokenMethod,
    //   reference: token.address,
    // },
    // {
    //   methodParameters: [token.address],
    //   methodName: lendingTokenInfoMethod,
    //   reference: token.address,
    // },
    // {
    //   methodParameters: [token.address],
    //   methodName: lvrLendingTokenMethod,
    //   reference: token.address,
    // },
  });

  return listRequestToPITContract;
}
