import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function debounce(fn, ms) {
  let timer;
  // eslint-disable-next-line no-unused-vars
  return (_) => {
    clearTimeout(timer);
    // eslint-disable-next-line no-shadow, no-unused-vars
    timer = setTimeout((_) => {
      timer = null;
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
    }, ms);
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    const debounceHandleResize = debounce(handleResize, 1000);

    window.addEventListener("resize", debounceHandleResize);
    return () => window.removeEventListener("resize", debounceHandleResize);
  }, []);

  return windowDimensions;
}
