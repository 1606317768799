import { TokenProvider } from "context/tokens";
import { withWalletLoader } from "HOCFunction";
import { AmplifyContextProvider } from "context/InstantsLeverage/AmplifyContext/AmplifyContext";
import { AmplifyDashboard } from "./AmplifyDashboard";

export const Amplify = withWalletLoader(() => (
  <TokenProvider>
    <AmplifyContextProvider>
      <AmplifyDashboard />
    </AmplifyContextProvider>
  </TokenProvider>
));
