import { logoBySymbol } from "constants/TokenListGoerliNetwork";
import { get as _get, groupBy as _groupBy } from "lodash";

export const mappingToGetProjectList = (data) =>
  _get(data, ["projectTokens"], []).map((o) => ({
    ...o,
    type: "projectToken",
    logo: logoBySymbol[o.symbol] || "/assets/coins_list/prjIcon.png",
  }));

export const groupingBorrowLogs = (data) => _groupBy(_get(data, ["borrowLogs"]), "prjTokenAddress");

export const groupingTotalStates = (data) => _groupBy(_get(data, ["totalStates"]), "type");

export const mappingToGetLendingToken = (data) =>
  _get(data, ["lendingTokens"], []).map((o) => ({
    ...o,
    type: "lendingToken",
    logo: logoBySymbol[o.symbol],
  }));
