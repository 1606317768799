import { EVENT_TYPES } from "app/constants";
import { BigNumber } from "ethers";
import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { coinPropType } from "types/coin";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  DialogApplyButton,
  DialogLogo,
  DialogTotal,
  HealthFactorProgressBar,
  NumericTextField,
  Spinner,
} from "components";

import { parseUnits } from "ethers/lib/utils";
import { useWallet } from "hooks";
import { useCoinMutations } from "hooks/mutation";
import { convertNumberHex } from "utils/utils";
import { formatUnits, minBigNumber, toHex } from "utils/number";
import numeral from "numeral";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },
  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  depositInfo: {
    [theme.breakpoints.down("xs")]: {
      "& p, & span": {
        fontSize: 14,
      },
    },
  },
}));

const DepositModal = ({ data, onClose }) => {
  const {
    name = "",
    balance = 0,
    logo = "",
    allowance = 0,
    address: prjTokenAddress = "",
    symbol = "",
    healthFactor = "0",
    decimal = 0,
    remaining,
  } = data;
  const allowanceBN = BigNumber.from(toHex(allowance));
  const classes = useStyles();
  const { connected } = useWallet();
  const [inputValue, setInputValue] = useState("");
  const { isLoading, approve, deposit } = useCoinMutations({
    projectTokenAddress: prjTokenAddress,
    name,
    amount: inputValue,
    kind: EVENT_TYPES.deposit,
  });

  const balanceBN = useMemo(() => parseUnits(balance, decimal), [balance, decimal]);
  const remainingBN = useMemo(() => parseUnits(remaining, decimal), [remaining, decimal]);

  const maxValueBN = minBigNumber([balanceBN, remainingBN]);
  const maxValue = formatUnits(maxValueBN, decimal);

  const isDisabled =
    !connected || !inputValue || Number(inputValue) === 0 || Number(inputValue) > Number(maxValue);

  const resetInputValue = () => setInputValue("");

  const handleEnable = () => {
    approve();
  };
  const handleDeposit = async () => {
    try {
      if (!prjTokenAddress) return;

      const amount = parseUnits(convertNumberHex(inputValue), decimal).toString();

      await deposit(amount);
    } finally {
      onClose();
      resetInputValue();
    }
  };

  return (
    <>
      <DialogLogo logoUrl={logo} name={name} />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        {!allowanceBN.isZero() ? (
          <NumericTextField value={inputValue} onChange={setInputValue} maxValue={maxValue} />
        ) : (
          <Box m={3}>
            <Typography color="primary">To Deposit {name}, you need to enable it first.</Typography>
          </Box>
        )}

        {!allowanceBN.isZero() && (
          <Box px={2} py={2} mt={2} className={classes.contentInner}>
            <HealthFactorProgressBar value={healthFactor} />
          </Box>
        )}

        <Box className={classes.depositInfo}>
          {numeral(inputValue).format() !== "NaN" &&
          allowanceBN.gte(parseUnits(convertNumberHex(inputValue), decimal)) &&
          !allowanceBN.isZero() ? (
            <DialogApplyButton disabled={isDisabled} onClick={handleDeposit}>
              Deposit
            </DialogApplyButton>
          ) : (
            <DialogApplyButton disabled={!connected} onClick={handleEnable}>
              Enable
            </DialogApplyButton>
          )}
          <DialogTotal title="Wallet Balance" value={balance} currency={symbol} />
          <DialogTotal
            title="Supply Cap Remaining"
            value={remaining.toString()}
            currency={symbol}
          />
        </Box>
      </Box>
    </>
  );
};

DepositModal.propTypes = {
  data: coinPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DepositModal;
