import MultiCallV2 from "./json/multicallABI.json";
import PITContractABI from "./json/PITContractABI.json";
import PriceABI from "./json/PriceContractABI.json";
import FTokenAbi from "./json/FTokenABI.json";
import AtomicRepayment from "./json/PrimaryIndexTokenAtomicRepayment.json";
import UniswapV2Factory from "./json/UniswapV2Factory.json";
import UniswapV2Router from "./json/UniswapV2Router.json";
import InstantLeverage from "./json/instantLeverage.json";

export const MultiCallABI = MultiCallV2;

export const PrimaryIndexTokenABI = PITContractABI;

export const ERC20TokenABI = [
  "function balanceOf(address _owner) public view returns (uint256 balance)",
  "function decimals() public view returns (uint8)",
  "function symbol() public view returns (string)",
  "function name() public view returns (string)",
  "function allowance(address _owner, address _spender) public view returns (uint256 remaining)",
  "function approve(address _spender, uint256 _value) public returns (bool success)",
  "function getCash() public view returns (uint256)",
  "function balanceOfUnderlyingView(address _owner) public returns (uint256)",
];

export const bUSDCContractABI = FTokenAbi;

export const PriceProviderAggregatorABI = PriceABI;

export const FTokenABI = FTokenAbi;

export const AtomicRepaymentAbi = AtomicRepayment;

export const UniswapV2FactoryAbi = UniswapV2Factory;

export const InstantLeverageAbi = InstantLeverage;

export const UniswapV2RouterAbi = UniswapV2Router;
