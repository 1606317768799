import { Contract } from "ethers";
import React from "react";

import useWallet from "hooks/useWallet";
import { getUniswapV2Router } from "utils/ethereum/contracts";

export const useUniswapV2RouterContract = () => {
  const { chainId, provider } = useWallet();

  const useUniswapV2Router = React.useMemo(() => {
    const contractInfo = getUniswapV2Router(chainId);
    if (!contractInfo.address) {
      return null;
    }
    const smc = new Contract(contractInfo.address, contractInfo.abi, provider);
    return smc;
  }, [chainId, provider]);

  return { useUniswapV2Router };
};
