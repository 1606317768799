import { BorrowContextProvider } from "context/contracts/BorrowContextProvider";
import { LenderContextProvider } from "pages/MultiAssetDashboard/providers/lenderContext";
import { withWalletLoader } from "HOCFunction";
// import Dashboard from "./BorrowerDashboard";
import BorrowNewDashboard from "./BorrowNewDashboard";

const BorrowerDashboard = () => (
  <BorrowContextProvider>
    <LenderContextProvider>
      <BorrowNewDashboard />
      {/* <Dashboard /> */}
    </LenderContextProvider>
  </BorrowContextProvider>
);

export const BorrowerNewDashboard = withWalletLoader(BorrowerDashboard);
