import { Box } from "@material-ui/core";
import { InputAdornment, TextField } from "@mui/material";
import BigNumber from "bignumber.js";
import { useCallback, useMemo } from "react";

export const ShowNumber = ({
  inputPercent,
  min,
  max,
  setValueInput,
  valueInput,
  disabled,
  onMouseLeave,
  onMouseEnter,
}) => {
  const handleChangeMargin = (e) => {
    setValueInput(e?.target?.value);
  };

  const isError = useMemo(() => {
    const valueInputBN = new BigNumber(valueInput);
    return valueInputBN.lt(min) || valueInputBN.gt(max);
  }, [max, min, valueInput]);

  const onBlur = useCallback(() => {
    if (onMouseLeave) {
      onMouseLeave();
    }
  }, [onMouseLeave]);

  const onFocus = useCallback(() => {
    if (onMouseEnter) {
      onMouseEnter();
    }
  }, [onMouseEnter]);

  return (
    <Box width="100%">
      <TextField
        disabled={disabled}
        type="number"
        sx={{
          maxHeight: "44px",
          width: "100%",
          color: "#000",
          background: "#FFFFFF",
          borderRadius: "6px",
          input: {
            height: "10px",
          },
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        error={isError}
        placeholder="0"
        onChange={handleChangeMargin}
        value={valueInput}
        InputProps={
          !inputPercent
            ? {
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: {
                  max,
                  min,
                },
              }
            : {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }
        }
      />
    </Box>
  );
};
