import { Box, Grid } from "@material-ui/core";
import { MarginButton } from "components/Button/MarginButton";
import { IconInfo } from "components/Leveraged/IconInfo";
import { Text } from "components/Title/Text";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import numeral from "numeral";
import { ShowNumber } from "pages/AmplifyDashboard/components/ShowNumber";
import { useGetLongInfoAsset } from "pages/AmplifyDashboard/hook/useGetInfoAsset";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMarginTradeContext } from "context/InstantsLeverage/MarginTradeContext/useMarginTradeContext";
import { MARGIN_TRADE_FIELD } from "context/InstantsLeverage/MarginTradeContext/MarginTradeContext";
import { useStyles } from "./useStyles";

export const MarginTradeMarginLeverage = () => {
  const classes = useStyles();
  const {
    margin: [leverageMargin],
    maxMargin,
    minMargin,
    longAssetSelected,
    isMarginInvalid,
    resetForm,
  } = useLeverageContext();
  const { margin, setMargin, setFocussedField } = useMarginTradeContext();
  const { price, symbol } = useGetLongInfoAsset();
  const marginConvert = price ? leverageMargin / price : "0";
  const marginConvertFormatted =
    numeral(marginConvert).format("0,0.[0000]") !== "NaN"
      ? numeral(marginConvert).format("0,0.[0000]")
      : "0";
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setIsFocused(false);
  }, [resetForm]);

  const invalidMarginErrorMessage = useMemo(
    () =>
      isMarginInvalid && isFocused
        ? `Margin must be between $${numeral(minMargin).format("0,0.[00]")} and $${numeral(
            maxMargin
          ).format("0,0.[00]")}`
        : "",
    [maxMargin, minMargin, isMarginInvalid, isFocused]
  );

  const disabled = !longAssetSelected;

  const onMouseEnter = useCallback(() => {
    setFocussedField(MARGIN_TRADE_FIELD.MARGIN);
  }, [setFocussedField]);

  const onSetMarginMax = useCallback(() => {
    setFocussedField(MARGIN_TRADE_FIELD.MARGIN);
    setMargin(maxMargin);
  }, [maxMargin, setFocussedField, setMargin]);

  const onSetMarginMin = useCallback(() => {
    setFocussedField(MARGIN_TRADE_FIELD.MARGIN);
    setMargin(minMargin);
  }, [minMargin, setFocussedField, setMargin]);

  return (
    <Grid container alignItems="center" className={[classes.boxStyle]}>
      <Grid item xs={12} lg={12}>
        <Box display="flex" alignItems="center" marginBottom="10px">
          <Box display="flex" gridGap={2} textAlign="center" justifyContent="center" width="100%">
            <IconInfo
              title={
                <Box>
                  <p>
                    The amount of margin collateral supplied by the user that will be used in
                    addition to the swapped collateral to collateralize the initial leveraged short
                    position.
                  </p>
                  <p>
                    Maximum amount will be dependent on your deposited and available wallet funds.
                  </p>
                  <p>
                    “Min” button resets the Exposure amount to your already-deposited amount for the
                    long asset type.
                  </p>
                  <p>
                    “Max” button sets the Exposure amount to your already-deposited amount plus your
                    wallet balance for the long asset type.
                  </p>
                </Box>
              }
            />
            <Text>Margin</Text>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={12} className={classes.marginInputRow}>
        <Box>
          <MarginButton onClickButton={onSetMarginMin} padding="4px 5px" disabled={disabled}>
            Min
          </MarginButton>
        </Box>

        <Box className={classes.marginInputWrapper}>
          <ShowNumber
            min={minMargin}
            max={maxMargin}
            setValueInput={setMargin}
            valueInput={margin}
            disabled={disabled}
            onMouseLeave={() => setIsFocused(true)}
            onMouseEnter={onMouseEnter}
          />
        </Box>
        <Box className={classes.marginActionButtonGroup}>
          <MarginButton onClickButton={onSetMarginMax} disabled={disabled}>
            Max
          </MarginButton>
        </Box>
      </Grid>

      <p className={classes.marginErrorMessage}>{invalidMarginErrorMessage}</p>

      <Grid item xs={12} className={classes.textStyle}>
        {marginConvertFormatted} {symbol}
      </Grid>
    </Grid>
  );
};
