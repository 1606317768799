import { get } from "lodash";
import { createContext, useContext, useMemo } from "react";
import { useUserFToken } from "../hooks/useLender";

const lenderContext = createContext(null);

export const LenderContextProvider = ({ children }) => {
  const userFToken = useUserFToken();

  const isError = useMemo(() => userFToken.isError, [userFToken.isError]);
  const isLoading = useMemo(() => userFToken.isLoading, [userFToken.isLoading]);

  return (
    <lenderContext.Provider
      value={{
        userFToken: get(userFToken, "data.fWallet", []),
        refetch: get(userFToken, "refetch", () => {}),
        isError,
        isLoading,
      }}
    >
      {children}
    </lenderContext.Provider>
  );
};

export const useLenderContext = () => useContext(lenderContext);
