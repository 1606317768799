import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import { useChartContext } from "pages/IndexMarket/ChartContext";

const ALL_LENDING_TOKENS = "all";

const SelectAsset = () => {
  const { availableBorrowTokens } = useFetchGraphData();
  const { setLendingToken } = useChartContext();
  const useStyles = makeStyles((theme) => ({
    SelectItem: {
      color: "#fff",
      background: "#2E2E2E",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      padding: "8px 16px",
      minHeight: "70px",
    },
    SelectStyled: {
      minWidth: "240px",
      minHeight: "58px",
      background: "#2E2E2E",
      color: "#fff",
      fontSize: "14px",
      position: "relative",
      marginTop: "20px",
      display: "flex",
      borderRadius: "4px",
      "& div": {
        display: "flex",
        alignItems: "center",
        padding: "10px 16px",
      },
      "& img": {
        marginRight: "16px",
        width: "42px",
        height: "39px",
      },
      "& .MuiSelect-icon": {
        color: "#FFFFFF",
        marginRight: "10px",
        // right: "25%",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        paddingRight: "42px",
      },
    },
    imgIcon: {
      width: "42px",
      height: "39px",
      marginRight: "8px",
      // background: "#fff",
    },
    FormStyled: {
      [theme.breakpoints.down("sm")]: {
        alignSelf: "flex-start",
      },
    },
  }));
  const classes = useStyles();
  const handleChangeLendingToken = (e) => {
    if (e.target.value === ALL_LENDING_TOKENS) {
      setLendingToken(null);
    } else {
      setLendingToken(e.target.value);
    }
  };
  return (
    <FormControl className={classes.FormStyled}>
      <Select
        onChange={handleChangeLendingToken}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        className={classes.SelectStyled}
        defaultValue={ALL_LENDING_TOKENS}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          MenuListProps: {
            sx: {
              backgroundColor: "red",
            },
          },
        }}
      >
        <MenuItem className={classes.SelectItem} value={ALL_LENDING_TOKENS}>
          ALL Lending tokens
        </MenuItem>
        {availableBorrowTokens.map((item) => (
          <MenuItem
            key={item?.address}
            className={classes.SelectItem}
            value={item?.address}
            name={item?.name}
          >
            <img src={item?.logo} alt="" className={classes.imgIcon} />
            {item?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectAsset;
