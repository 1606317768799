import { USD_DECIMAL } from "constants/contract";
import { constants } from "ethers";
import { formatUnits } from "utils/number";
import { get } from "lodash";
import methodOfPITContract from "./methodOfPitContract";

const { limitBorrowInLendingTokenMethod } = methodOfPITContract;

export function getLimitBorrowingOfLendingTokenFromMultiData(methodDataOnPitContract) {
  return get(methodDataOnPitContract, [limitBorrowInLendingTokenMethod])?.reduce(
    (res, resultCall) => ({
      ...res,
      [get(resultCall, ["reference"], constants.AddressZero)]: formatUnits(
        get(resultCall, ["returnValues", 0, "hex"], "0"),
        USD_DECIMAL
      ),
    }),
    {}
  );
}

export function handleBorrowLimitPerLendingToken(borrowLimitList) {
  const result = {};
  borrowLimitList.forEach((o) => {
    const key = get(o, ["methodParameters", 0]);
    const borrowLimit = get(o, ["returnValues", 0]);
    result[key] = borrowLimit;
  });
  return result;
}

export function handleTotalBorrowedPerLendingToken(totalBorrowedList) {
  const result = {};
  totalBorrowedList.forEach((o) => {
    const key = get(o, ["methodParameters", 0]);
    const totalBorrow = get(o, ["returnValues", 0]);
    result[key] = totalBorrow;
  });
  return result;
}
