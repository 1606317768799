import { CircularProgress } from "@material-ui/core";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import { Contract } from "ethers";
import useWallet from "hooks/useWallet";
import { get } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useMutation as useRTKMutation,
  useQuery as useRTKQuery,
  useQueryClient as useRTKQueryClient,
} from "react-query";

import { getInstantLeverageContract } from "utils/ethereum/contracts";
import { createSellCallData } from "utils/ethereum/createSellData";
import { useAmplifyContext } from "context/InstantsLeverage/AmplifyContext/useAmplifyContext";
import { useMarginTradeContext } from "context/InstantsLeverage/MarginTradeContext/useMarginTradeContext";
import { parseUnits } from "ethers/lib/utils.js";
import { useMultiCallContractInstance } from "./multicall/useMultiCallContract";

const usePITLeverageContract = (chainId, signer) =>
  useMemo(() => {
    const contractInfo = getInstantLeverageContract(chainId);
    return new Contract(contractInfo.address, contractInfo.abi, signer);
  }, [chainId, signer]);

export const useLeverageContract = () => {
  const queryRTKClient = useRTKQueryClient();
  const { account, chainId, signer } = useWallet();

  const leverageContract = usePITLeverageContract(chainId, signer);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    refetchLaveragedBorrowList,
    shortAssetSelected,
    notionalExp: NEXP,
  } = useLeverageContext();

  const { resetStates: resetAmplifyContextStates } = useAmplifyContext();
  const { resetStates: resetMarginTradeContextStates } = useMarginTradeContext();

  const [shortAmountt, setShortAmount] = useState();

  const getShortAmount = useCallback(async () => {
    if (!shortAssetSelected || !NEXP || NEXP === "NaN" || !leverageContract) return;

    const res = await leverageContract.calculateLendingTokenCount(
      shortAssetSelected.address,
      parseUnits(NEXP, 6).toString()
    );
    setShortAmount(res);
  }, [leverageContract, shortAssetSelected, NEXP]);

  useEffect(() => {
    getShortAmount();
  }, [getShortAmount]);

  const { mutateAsync: openLeveragePosition, isLoading } = useRTKMutation(
    async (variable) => {
      const { longAddress, shortAddress, notionalExp, marginLong, leverageType } = variable;

      const shortAmount = await leverageContract.calculateLendingTokenCount(
        shortAddress,
        notionalExp
      );

      const sellCalldata = await createSellCallData({
        tokenCollateral: longAddress,
        lendingToken: shortAddress,
        amountIn: shortAmount.toString(),
        amountOutMin: "0",
        chainId,
        account,
        signer,
      });

      const tx = await leverageContract.leveragedBorrow(
        longAddress,
        shortAddress,
        notionalExp,
        marginLong,
        sellCalldata,
        leverageType
      );
      await tx.wait(1);
    },
    {
      onSettled: async (_data, err) => {
        closeSnackbar();
        if (err) {
          const message =
            JSON.stringify(err)
              .split('"')
              .find((mes) => mes.includes("reverted")) ||
            get(err, "reason") ||
            get(err, "message");
          enqueueSnackbar(`Error Tx - ${message}!`, { variant: "error", autoHideDuration: 5000 });
        } else {
          await queryRTKClient.invalidateQueries(["available-multicall", account]);
          await queryRTKClient.invalidateQueries(["borrowed-pit-multicall", account]);
          enqueueSnackbar("Success Tx:Leverage Position Opened!", {
            variant: "success",
            autoHideDuration: 5000,
          });

          if (resetAmplifyContextStates) {
            resetAmplifyContextStates();
          }

          if (resetMarginTradeContextStates) {
            resetMarginTradeContextStates();
          }

          await refetchLaveragedBorrowList();
        }
      },
      onMutate: () => {
        enqueueSnackbar(
          <>
            <CircularProgress size={16} color="inherit" style={{ marginRight: "8px" }} /> Waiting
            Transaction: Open Leverage Position!
          </>,
          {
            persist: true,
            autoHideDuration: 5000,
          }
        );
      },
    }
  );

  return {
    openLeveragePosition: isLoading ? () => {} : openLeveragePosition,
    shortAmount: shortAmountt,
  };
};

export const useCheckLeveragePositions = (collaterals = []) => {
  const multicallInstance = useMultiCallContractInstance();
  const { account, chainId } = useWallet();

  const {
    refetch: checkLeveragePosition,
    data: leverageData,
    isFetched,
  } = useRTKQuery(
    [collaterals, chainId, account, "is-leverage-position"],
    async ({ queryKey }) => {
      const [collateralList, networkID] = queryKey;

      const contractPITLeverage = getInstantLeverageContract(networkID);

      const requests = {
        contractAddress: contractPITLeverage.address,
        abi: contractPITLeverage.abi,
        reference: contractPITLeverage.address,
        calls: collateralList.map((collateralAddress) => ({
          methodParameters: [account, collateralAddress],
          methodName: "isLeveragePosition",
          reference: "isLeveragePosition",
        })),
      };

      const { results } = await multicallInstance.call(requests);

      const res = results[contractPITLeverage.address].callsReturnContext.map(
        (call) => call.returnValues[0]
      );

      const dataRes = new Map();

      collateralList.forEach((collateralAddress, index) => {
        dataRes.set(collateralAddress, res[index]);
      });

      return dataRes;
    },
    {
      enabled: false,
      onError: (err) => new Map().set("error", err),
    }
  );

  return { checkLeveragePosition, leverageData, isFetched };
};

export const useGetLeverageTypes = (collaterals = []) => {
  const multicallInstance = useMultiCallContractInstance();
  const { account, chainId } = useWallet();

  const {
    refetch: getLeverageTypes,
    data: leverageData,
    isFetched,
  } = useRTKQuery(
    [collaterals, chainId, account, "leverage-type"],
    async ({ queryKey }) => {
      const [collateralList, networkID] = queryKey;

      const contractPITLeverage = getInstantLeverageContract(networkID);

      const requests = {
        contractAddress: contractPITLeverage.address,
        abi: contractPITLeverage.abi,
        reference: contractPITLeverage.address,
        calls: collateralList.map((collateralAddress) => ({
          methodParameters: [account, collateralAddress],
          methodName: "getLeverageType",
          reference: "getLeverageType",
        })),
      };

      const { results } = await multicallInstance.call(requests);

      const res = results[contractPITLeverage.address].callsReturnContext.map(
        (call) => call.returnValues[0]
      );

      const dataRes = new Map();

      collateralList.forEach((collateralAddress, index) => {
        dataRes.set(collateralAddress, res[index]);
      });

      return dataRes;
    },
    {
      enabled: false,
      onError: (err) => new Map().set("error", err),
    }
  );

  return { getLeverageTypes, leverageData, isFetched };
};
