import { subHours, subDays, subWeeks, subMonths, subYears } from "date-fns";

const HOURS = 1;
const DAY = 1;
const WEEK = 1;
const MONTH = 1;
const YEAR = 1;
const YEARS = 5;

export const getDateRange = (interval) => {
  const currentDate = new Date();

  switch (interval) {
    case "day":
      return subDays(currentDate, DAY);
    case "week":
      return subWeeks(currentDate, WEEK);
    case "month":
      return subMonths(currentDate, MONTH);
    case "year":
      return subYears(currentDate, YEAR);
    case "5years":
      return subYears(currentDate, YEARS);
    default:
      return subHours(currentDate, HOURS);
  }
};
