import { success, error } from "../palette";

const MuiLinearProgress = {
  props: {
    color: "primary",
    variant: "determinate",
  },
  overrides: {
    root: {
      height: 4,
      borderRadius: 4,
    },

    colorPrimary: {
      backgroundColor: "#E0E0E0",
    },

    colorSecondary: {
      backgroundColor: "#E0E0E0",
    },

    barColorPrimary: {
      backgroundColor: success.main,
    },

    barColorSecondary: {
      backgroundColor: error.main,
    },

    bar: {
      borderRadius: 4,
    },
  },
};

export default MuiLinearProgress;
