import PropTypes from "prop-types";

export const lendingTokenPropType = PropTypes.shape({
  token: PropTypes.string,
  allowance: PropTypes.bool,
  balance: PropTypes.string,
  lendingTokenId: PropTypes.number,
  apy: PropTypes.number,
  interest: PropTypes.string,
});
