import { MIN_AMPLIFY } from "constants/contract";
import { createContext, useEffect, useMemo, useState } from "react";
import BigNumber from "bignumber.js";
import { useDebounce } from "hooks/common/useDebounce";
import { getSafeBuffer } from "utils/formula/geLeverageFields";
import { get } from "lodash";
import { LeverageContextProvider } from "../LeverageContext/LeverageContext";
import { useLeverageContext } from "../LeverageContext/useLeverageContext";

export const AmplifyContext = createContext();

const ContextProvider = ({ children }) => {
  const {
    margin: [, setLeverageMargin],
    amplification: [, setLeverageAmplification],
    setNotionalExp,
    setSafeBuffer,
    longAssetSelected,
    resetStates: resetLeverageContextStates,
  } = useLeverageContext();
  const [margin, setMargin] = useState("");
  const [amplification, setAmplification] = useState(MIN_AMPLIFY);

  const debouncedAmplify = useDebounce(amplification, 1000);

  const notionalExp = useMemo(
    () => new BigNumber(debouncedAmplify).minus(1).multipliedBy(margin).toFixed(6).toString(),
    [debouncedAmplify, margin]
  );

  const resetStates = () => {
    setMargin("");
    setAmplification(MIN_AMPLIFY);
    resetLeverageContextStates();
  };

  useEffect(() => {
    const safeBuffer = getSafeBuffer({
      margin,
      notionalExp,
      lvr: get(longAssetSelected, "lvr", 0),
    });
    setSafeBuffer(safeBuffer);
  }, [margin, longAssetSelected, notionalExp, setSafeBuffer]);

  useEffect(() => {
    setNotionalExp(notionalExp);
  }, [notionalExp, setNotionalExp]);

  useEffect(() => {
    setLeverageMargin(margin);
  }, [margin, setLeverageMargin]);

  useEffect(() => {
    setLeverageAmplification(amplification);
  }, [amplification, setLeverageAmplification]);

  const contextValue = {
    setMargin,
    setAmplification,
    resetStates,
  };

  return <AmplifyContext.Provider value={contextValue}>{children}</AmplifyContext.Provider>;
};

export const AmplifyContextProvider = ({ children }) => (
  <LeverageContextProvider>
    <ContextProvider>{children}</ContextProvider>
  </LeverageContextProvider>
);
