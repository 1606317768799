import BigNumber from "bignumber.js";
import { useLendingAssetContext } from "context/tokens/BorrowTokenContext";
import { TokenMethod } from "context/tokens/methodName";
import { formatUnits } from "utils/number";
import { useWallet } from "hooks";
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import { useMultiCallContractInstance } from "hooks/contract/multicall/useMultiCallContract";
import { get, isEmpty, map } from "lodash";
import { useQuery } from "react-query";
import { bUSDCContractABI, ERC20TokenABI } from "utils/ethereum/abi";

export const useUserFToken = () => {
  const tokenInfo = useLendingAssetContext();

  const { availableBorrowTokens, APY } = useFetchGraphData();
  const { account, chainId } = useWallet();
  const multicall3 = useMultiCallContractInstance();

  return useQuery(
    ["#User-F-Token-Balance-lending", account, chainId],
    async () => {
      const tokenData = await tokenInfo.refetch();

      const fTokens = [...get(tokenData, ["data", "fTokens"], [])];
      const lendingTokens = [...get(tokenData, ["data", "balanceOfLendingToken"], [])];

      const handleGetFTokenInfo = async () => {
        const listERC20 = fTokens.map((o) => ({
          contractAddress: o.lendingToken,
          reference: o.lendingToken,
          abi: ERC20TokenABI,
          calls: [
            {
              methodName: TokenMethod.allowance,
              methodParameters: [account, o.ftoken],
              reference: "",
            },
          ],
        }));

        const list = fTokens.map((o) => ({
          contractAddress: o.ftoken,
          reference: o.ftoken,
          abi: bUSDCContractABI,
          calls: [
            {
              methodName: TokenMethod.decimals,
              methodParameters: [],
              reference: "",
            },
            {
              methodName: TokenMethod.balanceOfUnderlyingView,
              methodParameters: [account],
              reference: "",
            },
            {
              methodName: TokenMethod.balanceOf,
              methodParameters: [account],
              reference: "",
            },

            {
              methodName: TokenMethod.supplyRatePerBlock,
              methodParameters: [],
              reference: "",
            },
          ],
        }));

        const { results } = await multicall3.call([...list, ...listERC20]);

        const mapData = map(fTokens, (o) => {
          const val = get(results, [o.ftoken]);
          const allowance = get(
            results,
            [o.lendingToken, "callsReturnContext", 0, "returnValues"],
            0
          );

          const returnValues = val.callsReturnContext.reduce(
            (pre, rv) => ({
              ...pre,
              [get(rv, "methodName", "")]: formatUnits(get(rv, ["returnValues", 0], 0), 0),
            }),
            {}
          );

          return {
            ...returnValues,
            ...o,
            [TokenMethod.balanceOf]: formatUnits(
              returnValues[TokenMethod.balanceOf] || 0,
              returnValues[TokenMethod.decimals] || 0
            ),
            [TokenMethod.balanceOfUnderlyingView]: formatUnits(
              returnValues[TokenMethod.balanceOfUnderlyingView] || 0,
              returnValues[TokenMethod.decimals] || 0
            ),
            apy:
              [...get(APY, "lender_apy", [])].find((x) => x.lendingTokenAddress === o.lendingToken)
                ?.amount || 0,
            allowance: !new BigNumber(allowance).isZero(),
          };
        });
        return mapData;
      };

      const fWallet = await handleGetFTokenInfo();
      return {
        fWallet: fWallet.map((f) => ({
          ...f,
          ...lendingTokens.find((token) => token.token === f.lendingToken),
        })),
      };
    },
    {
      enabled: !isEmpty(availableBorrowTokens) && !isEmpty(account),
    }
  );
};
