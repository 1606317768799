// import BigNumber from "bignumber.js";
import { formatUnits } from "utils/number";
import { get, groupBy } from "lodash";

import { useBorrowContext } from "context/contracts/BorrowContext";
// import { USD_DECIMAL } from "constants/contract";

const useAllBorrowData = () => {
  const context = useBorrowContext();

  // if (!context?.isFetched)
  //   return {
  //     collateralTokenObj: {},
  //     lendingTokensObj: {},
  //     collateralList: [],
  //     lendingList: [],
  //   };

  const priceCollateralTokens = get(context, "priceOfTokens", {});
  // const currentLimitLendingToken = get(context, "currentLimitLendingToken", {});
  // const currentLimitPrjToken = get(context, "currentLimitPrjToken", {});
  const fTokenAddresses = get(context, "fTokenAddresses", []);
  // const pitCollateralBalance = get(context, "pitCollateralBalance", {});
  // const pitRemaining = get(context, "pitRemaining", {});
  // const totalOutstandingAssets = get(context, "totalOutstandingAssets", {});
  // const accrualAssets = get(context, "accrualAssets", {});
  // const healthFactor = get(context, "healthFactor", {});
  // const liquidationThresholdList = get(context, "liquidationThresholdList", []);
  const fTokenRate = get(context, "fTokenRate", {});
  // const allowanceForFToken = get(context, "allowanceForFToken", {});
  // const cashFToken = get(context, "cashFToken", {});
  // const lvrLending = get(context, "lvrLending", {});
  // const evaluations = get(context, "evaluations", {});
  const userTokenInfo = get(context, "userTokenInfo", []);

  // ============== V3 =================
  const collateralTokens = get(context, "availableDepositTokens", []);
  const lvrCollateralTokens = get(context, "lvrByProjectTokens", {});
  const lendingTokens = get(context, "availableBorrowTokens", []);
  const decimalToken = get(context, "decimalOfContractToken", {});
  const depositedAssets = get(context, "depositedAmount", {});

  const lending = [...lendingTokens].map((token) => ({
    ...token,
    // ...currentLimitLendingToken[token.address],
    ...userTokenInfo.find((o) => o.address === token.address),
    type: "lending",
    decimal: decimalToken[token.address],
    fLendingToken: fTokenAddresses.find((o) => o.token === token.address && !o.isPaused)?.ftoken,
    supplyApy: fTokenRate[token.address]?.lenderApy || "0",
    supplyRate: fTokenRate[token.address]?.lenderRate || "0",
    borrowApy: fTokenRate[token.address]?.borrowApy || "0",
    borrowRate: fTokenRate[token.address]?.borrowRate || "0",
    // isAllowFToken: allowanceForFToken[token.address]?.allowance || false,
    // totalAvailableBorrow: cashFToken[token.address]?.cash || "0",
    // lvr: lvrLending[token.address] || 0,
    // evaluation: formatUnits(evaluations[token.address], USD_DECIMAL),
  }));

  const lendingTokensObj = groupBy(lending, "address");

  const collateral = [...collateralTokens].map((token) => {
    const tokenDecimal = decimalToken[token.address];

    // const liquidationThresholdBN = [...liquidationThresholdList]
    //   .filter((o) => o.collateralToken === token.address)
    //   .reduce((acc, cur) => new BigNumber(cur?.value).plus(acc).toString(), "0");
    // const lendingTokenAddress = totalOutstandingAssets[token.address]?.lendingToken;
    // const lendingTokenDecimal = decimalToken[lendingTokenAddress];
    const underlyingTokens = get(token, "underlyingTokens", []);

    const lpSymbol = `${get(underlyingTokens, [0, "symbol"])}/${get(underlyingTokens, [
      1,
      "symbol",
    ])}`;

    if (underlyingTokens.length > 1) {
      token.symbol = lpSymbol;
    }
    return {
      ...token,
      // ...currentLimitPrjToken[token.address],
      type: "collateral",
      decimal: tokenDecimal,
      lvr: lvrCollateralTokens[token.address] || 0,
      price: priceCollateralTokens[token.address],
      // healthFactor: healthFactor[token.address],
      // lendingToken: lendingTokenAddress,
      // lendingTokenData: get(lendingTokensObj, [lendingTokenAddress, 0]),
      // listPitRemaining: pitRemaining,
      // pitRemaining: formatUnits(
      //   pitRemaining[token.address][lendingTokenAddress] || "0",
      //   decimalToken[lendingTokenAddress] || 18
      // ),
      // pitRemainingBN: pitRemaining[token.address][lendingTokenAddress] || "0",
      // pitCollateral: formatUnits(pitCollateralBalance[token.address] || "0", USD_DECIMAL),
      // pitCollateralBN: pitCollateralBalance[token.address] || "0",
      // outstanding: formatUnits(
      //   totalOutstandingAssets[token.address]?.value || "0",
      //   lendingTokenDecimal
      // ),
      // outstandingBN: totalOutstandingAssets[token.address]?.value,
      // loanBody: formatUnits(
      //   totalOutstandingAssets[token.address]?.loanBody || "0",
      //   lendingTokenDecimal
      // ),
      // loanBodyBN: totalOutstandingAssets[token.address]?.loanBody,
      // accrual: formatUnits(accrualAssets[token.address]?.value || "0", lendingTokenDecimal),
      // accrualBN: accrualAssets[token.address]?.value,
      // liquidationThreshold: formatUnits(liquidationThresholdBN, tokenDecimal),
      // liquidationThresholdBN,
      // evaluation: formatUnits(evaluations[token.address], USD_DECIMAL),

      // ===================== V3 ====================
      depositedAmount: +formatUnits(depositedAssets[token.address]?.value || "0", tokenDecimal),
      depositedAmountBN: depositedAssets[token.address]?.value,
    };
  });

  const collateralTokenObj = groupBy(collateral, "address");

  return {
    collateralTokenObj,
    lendingTokensObj,
    collateralList: collateral,
    lendingList: lending,
  };
};

export default useAllBorrowData;
