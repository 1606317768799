import { Contract, constants, ethers } from "ethers";
import { isEqualLowerString } from "utils/addressUtils";
import { getUniswapV2Factory } from "./contracts";
import UniSwapV2PairARTIFACT from "./json/UniswapV2Pair.json";

// TODO: get exposure limit from paraswap API
const getExposureLimitParaswap = async (tokenCollateral, lendingToken, chaindId) => {
  console.log("[DEBUG] - getLeverageExposureLimitParaswap", {
    tokenCollateral,
    lendingToken,
    chaindId,
  });
  return null;
};

const getExposureLimitOnTestnet = async (tokenCollateral, lendingToken, { chainId, signer }) => {
  const uniswapInfo = getUniswapV2Factory(chainId);
  const uniswapContract = new Contract(uniswapInfo.address, uniswapInfo.abi, signer);

  const usdcToken = localStorage.getItem("usdcToken");
  let pair = await uniswapContract.getPair(tokenCollateral, lendingToken);
  if (usdcToken && lendingToken !== usdcToken) {
    pair = await uniswapContract.getPair(lendingToken, usdcToken);
  }

  if (isEqualLowerString(pair, constants.AddressZero)) {
    return null;
  }

  const pairContract = new ethers.Contract(pair, UniSwapV2PairARTIFACT, signer);
  const [token0, reserves] = await Promise.all([pairContract.token0(), pairContract.getReserves()]);

  return token0.toLowerCase() === lendingToken.toLowerCase() ? reserves?.[0] : reserves?.[1];
};

export const getExposureLimit = (tokenCollateral, lendingToken, { chainId, signer }) => {
  if (+chainId === 1 || +chainId === 137) {
    return getExposureLimitParaswap(tokenCollateral, lendingToken, chainId);
  }

  return getExposureLimitOnTestnet(tokenCollateral, lendingToken, { chainId, signer });
};
