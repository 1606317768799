import { gql, useQuery as useClientQuery } from "@apollo/client";
import { get } from "lodash";
import { QueryGraphEntity, SELECT } from "pages/IndexMarket/constants";
import { useChartContext } from "pages/IndexMarket/ChartContext";

const GET_MARKET_STATE = gql`
  query MarketHistory($skip: Int!, $startAt: BigInt!, $lendingToken: String, $limit: Int = 1000) {
    collateralDepositedHistories(
      first: $limit
      orderBy: date
      orderDirection: asc
      skip: $skip
      where: { lendingTokenAddress: $lendingToken, date_gte: $startAt }
    ) {
      amount
      date
      id
      lendingTokenAddress
    }

    pittokenHistories(
      first: $limit
      orderBy: date
      orderDirection: asc
      skip: $skip
      where: { date_gte: $startAt }
    ) {
      amount
      date
      id
      lendingTokenAddress
    }

    outstandingHistories(
      first: $limit
      orderBy: date
      orderDirection: asc
      skip: $skip
      where: { lendingTokenAddress: $lendingToken, date_gte: $startAt }
    ) {
      amount
      date
      id
      lendingTokenAddress
    }

    collateralVSLoanRatioHistories(
      first: $limit
      orderBy: date
      orderDirection: asc
      skip: $skip
      where: { lendingTokenAddress: $lendingToken, date_gte: $startAt }
    ) {
      amount
      date
      id
      lendingTokenAddress
    }

    borrowingAPYHistories(
      first: $limit
      orderBy: date
      orderDirection: asc
      skip: $skip
      where: { lendingTokenAddress: $lendingToken, date_gte: $startAt }
    ) {
      amount
      date
      id
      lendingTokenAddress
    }

    lenderAPYHistories(
      first: $limit
      orderBy: date
      orderDirection: asc
      skip: $skip
      where: { lendingTokenAddress: $lendingToken, date_gte: $startAt }
    ) {
      amount
      date
      id
      lendingTokenAddress
    }

    lenderAggregateCapitalDepositedHistories(
      first: $limit
      orderBy: date
      orderDirection: asc
      skip: $skip
      where: { lendingTokenAddress: $lendingToken, date_gte: $startAt }
    ) {
      amount
      date
      id
      lendingTokenAddress
    }
  }
`;

const handleDataChartLine = (data = [], { color }) => {
  if (data.length <= 0) return [];
  let tmp = +get(data, [0, "date"], Number.MAX_VALUE);
  let startIndex = 0;
  const lines = [
    {
      kind: SELECT.totalAmount,
      dots: [],
    },
  ];

  while (tmp < Date.now() / 1000) {
    const val = get(data, [startIndex, "amount"], 0);
    lines[0].dots.push({
      time: tmp,
      value: val,
      color,
    });
    tmp += 1800;
    while (
      tmp > get(data, [startIndex, "date"], Number.MAX_VALUE) &&
      startIndex < data.length - 1
    ) {
      startIndex += 1;
    }
  }
  const val = get(data, [data.length - 1, "amount"], 0);

  lines[0].dots.push({
    time: Date.now() / 1000,
    value: val,
    color,
  });

  return [lines[0].dots];
};

const handleGetLastValue = (data) => {
  if (data) {
    const objectValueTab = {};
    Object.entries(data).forEach((e) => {
      const arrayItem = data[e[0]];
      objectValueTab[e[0]] = arrayItem[arrayItem.length - 1];
    });
    return objectValueTab;
  }
  return [];
};

const handleDataChart = (activeLines = [], data) => {
  const arrayData = activeLines.map((item) =>
    handleDataChartLine(get(data, QueryGraphEntity[item.value]), item)
  );
  return arrayData;
};

export const useMarketChart = () => {
  const { actLines: activeLines, activeTab, lendingToken } = useChartContext();

  const { data, refetch, loading } = useClientQuery(GET_MARKET_STATE, {
    variables: {
      lendingToken,
      skip: 0,
      startAt: 0,
    },
  });

  return {
    data: handleDataChartLine(get(data, QueryGraphEntity[activeTab.value]), activeTab),
    fullData: handleDataChart(activeLines, data),
    refetch,
    isLoading: loading,
    valueTab: handleGetLastValue(data),
  };
};
