import { Button, makeStyles, Box } from "@material-ui/core";
import { TwitterShareButton } from "react-share";

import TwitterIcon from "assets/twitter-icon.png";

const useStyles = makeStyles(() => ({
  button: {
    fontWeight: 600,
    marginTop: "20px",
    padding: "5px 10px 5px  10px",
    color: "#01a9f4",
    border: "#01a9f4 1px solid",
    backgroundColor: "white",
    "&:hover": {
      color: "#01a9f4",
      backgroundColor: "white",
    },
  },
  img: { width: 25 },
  buttonTw: {
    display: "inline-block",
  },
  buttonBox: {
    textAlign: "center",
  },
}));

const ShareTwitterButton = ({ title, url }) => {
  const classes = useStyles();

  return (
    <Box className={classes.buttonBox}>
      <TwitterShareButton title={title} url={url} className={classes.buttonTw}>
        <Button className={classes.button}>
          <img className={classes.img} src={TwitterIcon} alt="icon-twitter" />
          Tweet
        </Button>
      </TwitterShareButton>
    </Box>
  );
};

export default ShareTwitterButton;
