import { makeStyles, Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import { NumericText } from "components";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#FFF",
    background: "#212222",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: "10px 23px",
    borderRadius: "6px",
    height: "100%",
  },
  title: {
    fontWeight: "300",
    fontSize: "20px",

    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
  },
  info: {
    display: "flex",
    alignItems: "center",
    marginTop: "33px",
    justifyContent: "center",
    gap: "33px",
  },
  infoItem: {
    display: "flex",
    gap: "4px",
    alignItems: "center",

    "& *": {
      fontSize: "18px",
    },
  },
  line: {
    height: "33px",
    width: "1px",
    background: "#434849",
  },
}));

function MarginTradeOverview() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <Typography component="span" className={classes.title}>
          Your Margin Trading Summary
        </Typography>

        <Box className={classes.info}>
          <Box className={classes.infoItem}>
            <Typography>Total Exposure:</Typography>
            <NumericText value={Number(0)} moneyValue />
          </Box>
          <Box className={classes.line} />
          <Box className={classes.infoItem}>
            <Typography>Profit/Loss:</Typography>
            <NumericText value={Number(0)} moneyValue />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MarginTradeOverview;
