import PropTypes from "prop-types";

import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import { linePropType } from "types/market";

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: "#4F4F4F",
    borderRadius: 4,
    color: "#E2E2E2",
  },
  deleteIcon: {
    color: "#BDBDBD",
  },
  chipColor: {
    width: 4,
    height: 14,
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
}));

const ChartChip = ({ line, onRemove }) => {
  const classes = useStyles();

  const handleRemoveLine = (selectedLine) => () => {
    onRemove(selectedLine);
  };

  return (
    <Chip
      label={
        <Box display="flex" alignItems="center">
          <div
            style={{ backgroundColor: line.color }}
            className={classes.chipColor}
          />
          {line.title}
        </Box>
      }
      onDelete={handleRemoveLine(line)}
      deleteIcon={<CloseIcon />}
      className={classes.chip}
      classes={{
        deleteIcon: classes.deleteIcon,
      }}
    />
  );
};

ChartChip.propTypes = {
  line: linePropType.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ChartChip;
