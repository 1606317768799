const MuiAppBar = {
  props: {},
  overrides: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};

export default MuiAppBar;
