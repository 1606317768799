import {
  BUSDC_CONTRACT,
  LENDING_TOKEN_ADDRESS,
  PRICE_PROVIDER_AGGREGATOR_CONTRACT,
  PRIMARY_INDEX_TOKEN,
  REACT_APP_UNISWAPV2_FACTORY_ETHEREUM,
  REACT_APP_UNISWAPV2_ROUTER,
  ATOMIC_REPAYMENT_CONTRACT,
  REACT_APP_INSTANT_LEVERAGE,
} from "constants/NetworkChainId";
import {
  bUSDCContractABI,
  PriceProviderAggregatorABI,
  PrimaryIndexTokenABI,
  AtomicRepaymentAbi,
  UniswapV2FactoryAbi,
  UniswapV2RouterAbi,
  InstantLeverageAbi,
} from "./abi";

export const getPrimaryIndexToken = (networkId) => ({
  address: PRIMARY_INDEX_TOKEN[networkId],
  abi: PrimaryIndexTokenABI,
});

export const bUSDCContract = (networkId) => ({
  address: BUSDC_CONTRACT[networkId],
  abi: bUSDCContractABI,
});

export const getPriceProviderAggregatorContract = (networkId) => ({
  address: PRICE_PROVIDER_AGGREGATOR_CONTRACT[networkId],
  abi: PriceProviderAggregatorABI,
});

export const getPrimaryIndexTokenAtomicRepayment = (networkId) => ({
  address: ATOMIC_REPAYMENT_CONTRACT[networkId],
  abi: AtomicRepaymentAbi,
});
export const getUniswapV2Factory = (networkId) => ({
  address: REACT_APP_UNISWAPV2_FACTORY_ETHEREUM[networkId],
  abi: UniswapV2FactoryAbi,
});

export const getUniswapV2Router = (networkId) => ({
  address: REACT_APP_UNISWAPV2_ROUTER[networkId],
  abi: UniswapV2RouterAbi,
});

export const getInstantLeverageContract = (networkId) => ({
  address: REACT_APP_INSTANT_LEVERAGE[networkId],
  abi: InstantLeverageAbi,
});

export const lendingTokenAddress = (networkId) => LENDING_TOKEN_ADDRESS[networkId || 5];
