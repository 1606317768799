/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable new-cap */
import { useEffect, useMemo } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";

const useStyles = makeStyles(() => ({
  ContentWrapper: {
    background: "transparent",
    marginBottom: 10,
  },
  ChartBox: {
    width: "100%",
    minHeight: "380px",
    margin: "auto",
    "@media(min-width: 1024px)": {
      minHeight: "280px",
    },
  },
  FullViewChartWrapper: {
    width: "100% !important",
    "& > *": {
      width: "100% !important",
      height: "420px !important",
      "& > *": {
        width: "100% !important",
        height: "420px !important",
      },
    },
  },
}));

const TradingViewChart = ({ assetSymbol }) => {
  const classes = useStyles();
  const tradingviewSymbol = useMemo(
    () => (assetSymbol ? "COINBASE:ETHUSD" : "BINANCE:ETHUSD"),
    [assetSymbol]
  );

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/tv.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const widget = new window.TradingView.widget({
        fullscreen: true,
        symbol: tradingviewSymbol,
        interval: "1",
        timezone: "Etc/UTC",
        theme: "dark",
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        allow_symbol_change: false,
        container_id: "tradingview-widget-container",
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [tradingviewSymbol]);

  return <div id="tradingview-widget-container" className={classes.FullViewChartWrapper}></div>;
};

export const ChartMarginTrade = () => {
  const classes = useStyles();
  const { longAssetSelected } = useLeverageContext();

  return (
    <Box className={classes.ContentWrapper}>
      <Box className={classes.ChartBox}>
        <TradingViewChart assetSymbol={longAssetSelected?.symbol} />
      </Box>
    </Box>
  );
};
