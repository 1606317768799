import { useContext } from "react";
import { pick } from "lodash";
import { MarginTradeContext } from "./MarginTradeContext";

export const useMarginTradeContext = () => {
  const ctx = useContext(MarginTradeContext);

  return {
    ...pick(ctx, [
      "margin",
      "setMargin",
      "notionalExp",
      "setNotionalExp",
      "safetyBuffer",
      "setSafetyBuffer",
      "setFocussedField",
      "resetStates",
      "isMarginTradeFormInvalid",
    ]),
  };
};
