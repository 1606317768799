import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { TableSkeleton } from "components";
import { useToggle } from "hooks";
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import { TokensTable } from "..";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
    },
  },
  collapse: {
    transition: theme.transitions.main,
  },
  button: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,

    "&:hover": {
      color: theme.palette.secondary.dark,
    },
  },
  bottomPaper: {
    left: 0,
    width: "100%",
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: "center",
    backgroundColor: "#191919",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    transition: theme.transitions.main,
    cursor: "pointer",

    "&:hover": {
      transition: theme.transitions.main,

      "& $button": {
        color: theme.palette.common.white,
      },
    },
  },
}));

const MAX_COLLAPSE_ELEMENTS = 4;

const IndexMarketTables = () => {
  const classes = useStyles();
  const { projectTokenList, isLoading } = useFetchGraphData();
  const [shouldShowMore, toggleShowMore] = useToggle(true);

  if (isLoading) {
    return (
      <Box border="1px solid #434849" mt={2} py={2} px={3}>
        <TableSkeleton rows={6} />
      </Box>
    );
  }

  return (
    <Paper className={classes.paper}>
      {projectTokenList.length > MAX_COLLAPSE_ELEMENTS ? (
        <>
          <Collapse in={shouldShowMore} collapsedSize={300} className={classes.collapse}>
            <TokensTable data={projectTokenList} />
          </Collapse>
          <Paper className={classes.bottomPaper} role="button" onClick={toggleShowMore}>
            <Link component="button" href="/" className={classes.button}>
              {shouldShowMore ? "See less" : "See more"}
            </Link>
          </Paper>
        </>
      ) : (
        <TokensTable data={projectTokenList} />
      )}
    </Paper>
  );
};

export default IndexMarketTables;
