import { makeStyles, Button } from "@material-ui/core";
import { Box, Dialog, Typography } from "@mui/material";
import {
  BorrowModal,
  CoinInfo,
  NumericText,
  RepayModal,
  ShareModal,
  Table,
  TableSkeleton,
} from "components";
import { Text } from "components/Title/Text";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { BigNumber } from "ethers";
import { useModalState, useWallet } from "hooks";
import { useCallback, useMemo, useState } from "react";
import { formatUnits } from "utils/number";
import useWindowDimensions from "pages/BorrowerNewDashboard/hooks/useWindowDimensions";
import { useCommonStyle } from "./commonStyle";

const MARIGN_VALUE = 16;

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #434849",
    marginBottom: MARIGN_VALUE,
    "& .MuiTableContainer-root": {
      maxHeight: "379px",

      [theme.breakpoints.down("sm")]: {
        maxHeight: "unset",
      },
    },

    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#191919",
    },

    [theme.breakpoints.down("sm")]: {},
  },
  title: {
    background: "#191919",
    padding: "8px 8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#FFF",
    gap: 10,

    "& p": {
      margin: 0,
    },
  },

  info: {
    display: "flex",
    gap: 20,
    "& p": {
      fontSize: 14,
    },
  },

  TableStyle: {
    backgroundColor: "rgb(33 34 34) !important",
    color: "#fff !important",
    "& .MuiTableRow-head": {
      borderBottom: "#3f4445 1px solid",
    },
    "& .MuiTableCell-root": {
      borderBottom: "#3f4445 1px solid",
      fontSize: "14px",
      textWrap: "nowrap",
    },
    "& .icon-style": {
      width: "20px",
      position: "absolute",
      right: 0,
      bottom: "31%",
    },

    [theme.breakpoints.down("sm")]: {
      "& .MuiTableCell-root": {
        fontSize: "12px",
      },
    },
  },

  EmptyDataText: {
    textAlign: "center",
    fontSize: "1.5rem",
    marginTop: "1.5rem",
    color: "#fff",
  },
  actionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.5),
    justifyContent: "end",
  },

  numericText: {
    textWrap: "nowrap",
  },
}));

function MyBorrrowingPosition({ setMyBorrowingHeight }) {
  const {
    // borrowPosition,
    totalEstimatedOutstandingInUSD,
    isLoading,
    availableBorrowOrLendTokens,
    totalEstimatedPITRemaining,
    decimalOfContractToken,
    totalEstimatedWeightedLoanInUSD,
    estimatedOutstandingInUSD,
    evaluations,
  } = useBorrowContext();

  const classes = useStyles();
  const commonStyle = useCommonStyle();

  const { account } = useWallet();

  const {
    isOpen: isOpenBorrowModalOpen,
    onOpen: onOpenBorrowModal,
    onClose: onCloseBorrowModal,
  } = useModalState();

  const {
    isOpen: isOpenRepayModalOpen,
    onOpen: onOpenRepayModal,
    onClose: onCloseRepayModal,
  } = useModalState();

  const [borrowDataModal, setBorrowDataModal] = useState();
  const [repayDataModal, setRepayDataModal] = useState();
  const [openShare, setOpenShare] = useState(false);
  const [shareData, setShareData] = useState({
    token: "",
    amount: "",
    apy: "",
  });

  const windowDimension = useWindowDimensions();

  const usdcToken = localStorage.getItem("usdcToken");

  const handleOpenBorrowModal = useCallback(
    (data) => {
      setBorrowDataModal(data);
      onOpenBorrowModal();
    },
    [onOpenBorrowModal]
  );

  const handleOpenRepayModal = useCallback(
    (data) => {
      setRepayDataModal(data);
      onOpenRepayModal();
    },
    [onOpenRepayModal]
  );

  const handleCloseModalShare = useCallback(() => {
    setOpenShare(false);
  }, []);

  const COLUMNS = [
    {
      Header: <Text>Asset</Text>,
      accessor: "asset",
      Cell: ({
        row: {
          original: { symbol, logo },
        },
      }) => <CoinInfo logoUrl={logo}>{symbol}</CoinInfo>,
    },
    {
      Header: (
        <Box>
          <Text>Borrow </Text>
          <Text>APY</Text>
        </Box>
      ),
      accessor: "borrowAPY",
      Cell: ({ value }) => <NumericText value={value?.amount || 0} precision={2} suffix="%" />,
    },
    {
      Header: (
        <Box>
          <Text>Remaining </Text>
          <Text>credit</Text>
        </Box>
      ),
      accessor: "remainingCredit",
      Cell: ({
        row: {
          original: {
            totalEstimatedPITRemaining: remainingCredit,
            evaluation: tokenEvaluation,
            decimal,
            symbol,
            decimalUSDC,
          },
        },
      }) => {
        const { evaluation, tokenAmount } = tokenEvaluation;

        const tokenFromUsd = formatUnits(
          remainingCredit.mul(BigNumber.from(tokenAmount)).div(BigNumber.from(evaluation)),
          decimal
        );
        return (
          <>
            <Typography className={classes.numericText}>
              <NumericText value={Number(tokenFromUsd).toFixed(9)} precision={2} suffix={symbol} />
            </Typography>
            <Box>
              $
              <NumericText
                value={Number(formatUnits(remainingCredit, decimalUSDC)).toFixed(9)}
                precision={2}
              />
            </Box>
          </>
        );
      },
    },
    {
      Header: (
        <Box>
          <Text>Debt </Text>
          <Text>outstanding</Text>
        </Box>
      ),
      accessor: "estimatedOutstandingInUSD ",
      Cell: ({
        row: {
          original: {
            estimatedOutstandingInUSD: debtOutStanding,
            decimal,
            evaluation: tokenEvaluation,
            symbol,
            decimalUSDC,
          },
        },
      }) => {
        const { evaluation, tokenAmount } = tokenEvaluation;

        const tokenFromUsd = formatUnits(
          BigNumber.from(debtOutStanding)
            .mul(BigNumber.from(tokenAmount))
            .div(BigNumber.from(evaluation)),
          decimal
        );
        return (
          <>
            <Typography className={classes.numericText}>
              <NumericText value={Number(tokenFromUsd).toFixed(9)} precision={2} suffix={symbol} />
            </Typography>
            <Box>
              $
              <NumericText
                value={Number(formatUnits(debtOutStanding, decimalUSDC)).toFixed(9)}
                precision={2}
              />
            </Box>
          </>
        );
      },
    },
  ];

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        Header: "",
        accessor: "button",
        Cell: ({ row: { original } }) => (
          <Box className={classes.actionBox}>
            <Button className={commonStyle.button} onClick={() => handleOpenBorrowModal(original)}>
              Borrow
            </Button>
            <Button className={commonStyle.button} onClick={() => handleOpenRepayModal(original)}>
              Repay
            </Button>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const decimalUSDC = useMemo(
    () => decimalOfContractToken && usdcToken && decimalOfContractToken[usdcToken],
    [decimalOfContractToken, usdcToken]
  );

  const filterBorrowingPosition = useMemo(
    () =>
      availableBorrowOrLendTokens &&
      estimatedOutstandingInUSD &&
      availableBorrowOrLendTokens
        .map((o) => {
          let borrowList = { decimalUSDC };
          estimatedOutstandingInUSD.forEach((i) => {
            if (i.lendingToken === o.address) {
              borrowList = {
                ...borrowList,
                ...i,
                ...o,
                totalEstimatedPITRemaining,
                evaluation: evaluations[o.address],
                decimalUSDC,
              };
            }
          });

          return borrowList;
        })
        .filter((o) => !BigNumber.from(o.loanBody).isZero()),
    [
      availableBorrowOrLendTokens,
      decimalUSDC,
      estimatedOutstandingInUSD,
      evaluations,
      totalEstimatedPITRemaining,
    ]
  );

  const totalHealth = useMemo(() => {
    if (totalEstimatedWeightedLoanInUSD && totalEstimatedPITRemaining)
      return totalEstimatedWeightedLoanInUSD.add(totalEstimatedPITRemaining);

    return 0;
  }, [totalEstimatedPITRemaining, totalEstimatedWeightedLoanInUSD]);

  const healthFactor = useMemo(() => {
    if (totalHealth && totalEstimatedWeightedLoanInUSD) {
      if (!totalHealth.isZero() && !totalEstimatedWeightedLoanInUSD.isZero())
        return totalHealth.div(totalEstimatedWeightedLoanInUSD);
    }

    return 0;
  }, [totalEstimatedWeightedLoanInUSD, totalHealth]);

  const handleSetRef = useCallback(
    (node) => {
      if (node) setMyBorrowingHeight(node?.getBoundingClientRect()?.height + MARIGN_VALUE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowDimension]
  );

  return (
    <Box
      className={`${classes.container} ${commonStyle.containerBackground}`}
      ref={(node) => handleSetRef(node)}
    >
      <Box className={classes.title}>
        <p style={{ fontSize: "18px", paddingLeft: "16px" }}>Your borrowing positions</p>
        <Box className={classes.info}>
          <p>
            Remaining credit:{" "}
            <NumericText
              value={Number(formatUnits(totalEstimatedPITRemaining || 0, decimalUSDC) || 0)}
              moneyValue
            />
          </p>
          <p>
            Balance:{" "}
            <NumericText
              value={Number(formatUnits(totalEstimatedOutstandingInUSD || 0, decimalUSDC) || 0)}
              moneyValue
            />
          </p>
        </Box>
      </Box>

      {isLoading && !filterBorrowingPosition ? (
        <TableSkeleton rows={3} />
      ) : (
        account &&
        filterBorrowingPosition?.length > 0 && (
          <>
            <Table stickyHeader columns={columns} data={filterBorrowingPosition} />
            {isOpenBorrowModalOpen && (
              <Dialog open={isOpenBorrowModalOpen} onClose={onCloseBorrowModal} noPadding>
                <BorrowModal
                  {...{
                    token: {
                      ...borrowDataModal,
                      decimal: decimalOfContractToken[borrowDataModal?.address?.toLowerCase()],
                    },
                    onClose: () => onCloseBorrowModal(),
                    healthFactor,
                    openShare: () => setOpenShare(true),
                    setShareData: () => setShareData(),
                  }}
                />
              </Dialog>
            )}

            {isOpenRepayModalOpen && (
              <Dialog open={isOpenRepayModalOpen} onClose={onCloseRepayModal} noPadding>
                <RepayModal
                  data={{
                    ...repayDataModal,
                    healthFactor,
                    decimal: decimalOfContractToken[repayDataModal?.address?.toLowerCase()],
                  }}
                  onClose={onCloseRepayModal}
                />
              </Dialog>
            )}

            <ShareModal
              open={openShare}
              onCloseModal={handleCloseModalShare}
              data={shareData}
              type="borrow"
            />
          </>
        )
      )}

      {!isLoading && (filterBorrowingPosition?.length <= 0 || !filterBorrowingPosition) && (
        <p className={classes.EmptyDataText}>You have no open borrow positions</p>
      )}
    </Box>
  );
}

export default MyBorrrowingPosition;
