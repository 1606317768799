import { useLendingAssetContext } from "context/tokens/BorrowTokenContext";
import { get } from "lodash";

const usePriceLendingToken = () => {
  const ctx = useLendingAssetContext();
  const priceLendingToken = get(ctx, "data.priceLendingToken", []);

  return [...priceLendingToken].reduce(
    (preResult, curr) => ({
      ...preResult,
      [curr?.lendingToken]: curr?.price,
    }),
    {}
  );
};

export default usePriceLendingToken;
