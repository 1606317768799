const MuiSkeleton = {
  overrides: {
    root: {
      backgroundColor: "rgba(255, 255, 255, 0.13)",
    },
    wave: {
      "&:after": {
        background:
          "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.08), transparent)",
      },
    },
  },
};

export default MuiSkeleton;
