import { Box, makeStyles } from "@material-ui/core";
import { Text } from "components/Title/Text";
import { IconInfo } from "./IconInfo";

const useStyles = makeStyles(() => ({
  infoIcon: {
    marginRight: "2px",
    display: "flex",
  },
}));

export const TitleLeverage = ({ title, helptext, color }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.infoIcon}>
        <IconInfo title={helptext} />
      </Box>
      <Box>
        <Text color={color}>{title}</Text>
      </Box>
    </Box>
  );
};
