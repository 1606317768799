import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(2),
    color: theme.palette.common.white,

    [theme.breakpoints.up("sm")]: {
      fontSize: 15,
    },

    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
}));

const MarketDetails = ({ children }) => {
  const classes = useStyles();

  return <Typography className={classes.title}>{children}</Typography>;
};

MarketDetails.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MarketDetails;
