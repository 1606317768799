import { Box, makeStyles } from "@material-ui/core";
import { Grid } from "@mui/material";
import { BorrowOverView } from "pages/BorrowerNewDashboard/components";
import { CollateralToSupply, MyCollateral } from "pages/BorrowerNewDashboard/components/BorrowV3";
import React from "react";
import { MarginTradeOverview, OpenPositionFrame } from ".";
import { ChartMarginTrade } from "../ChartMarginTrade";
import { OpenMarginPositionTable } from "../OpenMarginPositionTable";

const useStyles = makeStyles((theme) => ({
  ContentWrapper: {
    background: "#000",
  },
  TextTitle: {
    color: "#FFFFFF",
    textAlign: "center",
    marginTop: "18px",
    marginBottom: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& h1": {
      fontSize: "18px",
    },
  },
  Overview: {
    display: "flex",
    gap: "12px",
    marginBottom: 20,

    "& .left, & .right": {
      width: "50%",
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",

      "& .left, & .right": {
        width: "100%",
      },
    },
  },

  marginSection: {},
  positionTableContainer: {
    padding: "0 16px",

    [theme.breakpoints.down("md")]: {
      padding: "16px 0",
    },
  },
}));

function MarginTradeDashboard() {
  const classes = useStyles();

  const [myCollateralHeight, setMyCollateralHeight] = React.useState(0);

  return (
    <>
      <Box className={classes.TextTitle}>
        <h1>Margin Trade - take a leveraged position on a pair of crypto assets</h1>
      </Box>
      <Box className={classes.Overview}>
        <Box className="left">
          <MarginTradeOverview />
        </Box>
        <Box className="right">
          <BorrowOverView />
        </Box>
      </Box>
      <Grid container className={classes.marginSection}>
        <Grid item xs={12} lg={3}>
          <OpenPositionFrame />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box className={classes.positionTableContainer}>
            <ChartMarginTrade />
            <OpenMarginPositionTable />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <MyCollateral noMargin setHeightState={setMyCollateralHeight} />
          <Box sx={{ marginTop: 16 }}>
            <CollateralToSupply myCollateralHeight={myCollateralHeight} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default MarginTradeDashboard;
