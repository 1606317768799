import useWallet from "hooks/useWallet";
import { useQuery } from "react-query";

import { useAtomicRepaymentContractAction } from "hooks/contract/core/useAtomicRepaymentContract";

export const useGetTotalOutstanding = (lendingToken) => {
  const { account, chainId } = useWallet();
  const { atomicRepaymentContract } = useAtomicRepaymentContractAction();
  const remainingDeposit = useQuery(
    ["total-outstanding", account, chainId, lendingToken],
    async () => {
      const res = await atomicRepaymentContract.getTotalOutstanding(account, lendingToken);
      return res;
    }
  );

  return remainingDeposit.data;
};
