import { Box, makeStyles } from "@material-ui/core";
import BigNumber from "bignumber.js";
import { parseUnits } from "ethers/lib/utils";
import { get, pick } from "lodash";

import { IconInfo } from "components/Leveraged/IconInfo";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import { useWallet } from "hooks";
import { useERC20TokenApproval } from "hooks/contract/tokens/useERC20TokenApproval";
import { useLeverageContract } from "hooks/contract/useLeverageContract";
import { memo, useCallback, useMemo } from "react";
import { getInstantLeverageContract } from "utils/ethereum/contracts";
import { useMarginTradeContext } from "context/InstantsLeverage/MarginTradeContext/useMarginTradeContext";
import { useGetLongInfoAsset } from "pages/AmplifyDashboard/hook/useGetInfoAsset";
import numeral from "numeral";
import { ApproveAsset } from "./ApproveAsset";
import { LeverageButton } from "./LeverageButton";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    background: "#191919",
    paddingLeft: "20px",
    paddingRight: "40px",
  },
  textTitle: {
    color: "#FFFFFF",
    fontSize: "18px",
    textAlign: "center",
    paddingTop: "18px",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconInf: {
    marginLeft: "12px",
  },
  flexPosition: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row-reverse",
    justifyContent: "center",
    "@media(min-width: 1200px)": {
      justifyContent: "space-between",
    },
  },
  ChartBox: {
    width: "90%",
    "@media(min-width: 1200px)": {
      width: "52%",
    },
  },
  DashBoardBox: {
    width: "90%",
    marginTop: "50px",
    "@media(min-width: 1200px)": {
      width: "45%",
      marginTop: 0,
    },
  },
  PositionButton: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  ButtonStyle: {
    fontSize: "17px",
    lineHeight: 1.5,
    textAlign: "center",
    padding: "4px 8px",
  },
}));

const withOpenPosition = (WrappedComponent) => {
  const ButtonContainer = memo(WrappedComponent);
  const ApproveAssetMemo = memo(ApproveAsset);

  return (props) => {
    const classes = useStyles();
    const { depositedAsset, price, decimal } = useGetLongInfoAsset();
    const { longAssetSelected, shortAssetSelected, margin, notionalExp, isMarginInvalid } =
      useLeverageContext();

    const marginConvert = price ? margin[0] / price : "0";
    const marginConvertBN =
      numeral(marginConvert).format("0,0.[0000]") !== "NaN" && marginConvert
        ? parseUnits(marginConvert.toFixed(18).toString(), decimal)
        : 0;
    const depositedAssetBN = parseUnits(depositedAsset.toString(), decimal);

    const marginTradeContext = useMarginTradeContext();
    const { openLeveragePosition, shortAmount } = useLeverageContract();
    const { chainId } = useWallet();

    const {
      query: longApproval,
      approve: approveLongAsset,
      isLoading: isLoadingLongAsset,
    } = useERC20TokenApproval(longAssetSelected?.address, [
      getInstantLeverageContract(chainId).address,
    ]);

    const {
      query: shortApproval,
      approve: approveShortAsset,
      isLoading: isLoadingShortAsset,
    } = useERC20TokenApproval(shortAssetSelected?.address, [
      getInstantLeverageContract(chainId).address,
    ]);

    const { leverageType } = props;

    const disabled =
      !longAssetSelected ||
      isMarginInvalid ||
      marginTradeContext?.isMarginTradeFormInvalid === true;

    const isEnableLong = useMemo(
      () =>
        marginConvertBN && depositedAssetBN && longApproval.data
          ? longApproval.data.lt(marginConvertBN.sub(depositedAssetBN))
          : false,
      [marginConvertBN, depositedAssetBN, longApproval.data]
    );

    const handleOpenLeveragePosition = useCallback(() => {
      const longAsset = pick(longAssetSelected, ["address", "decimal"]);
      const shortAddress = get(shortAssetSelected, ["address"]);
      const longPrice = get(longAssetSelected, ["price"], 1);
      const notionalExpBN = parseUnits(notionalExp, 6).toString();

      const marginBN = parseUnits(
        new BigNumber(margin[0]).dividedBy(longPrice).toFixed(longAsset.decimal),
        longAsset.decimal
      ).toString();

      openLeveragePosition({
        longAddress: longAsset.address,
        shortAddress,
        notionalExp: notionalExpBN,
        marginLong: marginBN,
        leverageType,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [longAssetSelected, margin[0], notionalExp, openLeveragePosition, shortAssetSelected]);

    if (!longApproval.data || isEnableLong)
      return (
        <Box className={classes.PositionButton}>
          <ApproveAssetMemo
            onClick={() => {
              if (isLoadingLongAsset) return;
              approveLongAsset();
            }}
            loading={isLoadingLongAsset}
            disabled={disabled}
          >
            Enable {longAssetSelected?.symbol}
          </ApproveAssetMemo>
        </Box>
      );

    if (!shortApproval.data || (shortAmount && shortAmount.gt(shortApproval.data)))
      return (
        <Box className={classes.PositionButton}>
          <ApproveAssetMemo
            onClick={() => {
              if (isLoadingShortAsset) return;
              approveShortAsset();
            }}
            loading={isLoadingShortAsset}
            disabled={disabled}
          >
            Enable {shortAssetSelected?.symbol}
          </ApproveAssetMemo>
        </Box>
      );

    return (
      <ButtonContainer
        {...props}
        handleOpenLeveragePosition={handleOpenLeveragePosition}
        disabled={disabled}
      />
    );
  };
};

export const OpenPosition = withOpenPosition(({ handleOpenLeveragePosition, disabled }) => {
  const classes = useStyles();

  return (
    <Box className={classes.PositionButton}>
      <IconInfo title="Let open your leveraged Position" />
      <Box>
        <LeverageButton onClick={handleOpenLeveragePosition} disabled={disabled}>
          Open Position
        </LeverageButton>
      </Box>
    </Box>
  );
});
