import BigNumber from "bignumber.js";

export const getNotionalExposure = ({ marginCollateral, lvr, safeBuffer }) => {
  const marginCollateralBN = new BigNumber(marginCollateral);
  const minusLvrBN = new BigNumber(1).minus(lvr);
  const plusSafeBufferBN = new BigNumber(safeBuffer).plus(1);
  return marginCollateralBN.dividedBy(minusLvrBN.multipliedBy(plusSafeBufferBN)).toString();
};

export const getNotionalExposureWithAmplify = ({ margin, amplify }) =>
  new BigNumber(amplify).minus(1).multipliedBy(margin).toString();

export const getSafeBuffer = ({ margin, notionalExp, lvr }) => {
  const marginBN = new BigNumber(margin);
  const notional = new BigNumber(notionalExp);
  const lvrBN = new BigNumber(lvr);
  return marginBN.plus(notional).dividedBy(notional.dividedBy(lvrBN)).minus(1).toString();
};

export const getMargin = ({ safeBuffer, notionalExp, lvr }) => {
  const safeBufferBN = new BigNumber(safeBuffer);
  const notional = new BigNumber(notionalExp);
  const lvrBN = new BigNumber(lvr);
  return safeBufferBN.plus(1).multipliedBy(notional.dividedBy(lvrBN)).minus(notional).toString();
};

export const getMaxAmplification = ({ lvr, notionValue: notionExp }) => {
  const minusLvrBN = new BigNumber(1).minus(lvr);
  const notionValueBN = new BigNumber(notionExp);
  return new BigNumber(1).dividedBy(minusLvrBN.multipliedBy(notionValueBN)).toString();
};
