import { Button, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    padding: "6px 20px",
    borderRadius: "5px",
    maxWidth: "unset",
    marginRight: "8px",
    backgroundColor: "rgb(110 116 123)",
    "&:hover": {
      backgroundColor: "rgb(137 143 150)",
    },
    "&:disabled": {
      backgroundColor: "rgb(61 61 61)",
      color: "#a7a7a7",
    },
  },
  circularProgress: {
    marginRight: "8px",
  },
}));

export const LeverageButton = (props) => {
  const classes = useStyles();
  const { children, loading } = props;
  return (
    <Button className={classes.buttonStyle} {...props}>
      {loading && (
        <CircularProgress className={classes.circularProgress} size={16} color="inherit" />
      )}
      {children}
    </Button>
  );
};
