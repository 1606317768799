import { LinearProgress, makeStyles } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import { NumericText } from "components";
import { IconInfo } from "components/Leveraged/IconInfo";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { getHealthColor } from "helpers/styles";
import { useMemo } from "react";
import { formatUnits } from "utils/number";

const useStyles = makeStyles((theme) => ({
  healthFactorWrapper: {
    position: "relative",
    paddingTop: 60,
  },
  progress: {
    background: "linear-gradient(90deg, #05EE76 0%, #FFC000 50.52%, #FF1D1D 100%)",
    height: 20,
    borderRadius: "50px",
    position: "relative",
  },
  barColorPrimary: {
    background: "transparent",
  },
  infoContainer: {
    width: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    gap: "8px",
    top: "110%",
  },
  infoBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",

    "& p": { margin: 0, display: "inline-block", textAlign: "center", textWrap: "nowrap" },

    [theme.breakpoints.down("sm")]: {
      "& p": {
        fontSize: "12px",
      },
    },
  },

  line: {
    width: "100%",
    borderTop: "1px solid #fff",
    position: "relative",

    "& span:first-child": {
      position: "absolute",
      top: -11.5,
      left: -2,
    },
    "& span:nth-child(2)": {
      position: "absolute",
      top: -11.5,
      right: -2,
    },
  },

  infoValue: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // padding: "15px 0",
    paddingTop: 15,
  },

  verticalLine: {
    position: "absolute",
    height: 15,
    width: 1,
    borderLeft: "1px solid #fff",
    top: 0,
    left: "50%",
  },

  safetyBuffer: {
    top: "-77px",
    width: "100%",
    height: 45,
    position: "absolute",
    right: "-4.5px",

    "& p": {
      margin: 0,
      textAlign: "center",
      fontSize: 14,
    },

    [theme.breakpoints.down("sm")]: {
      "& p": {
        fontSize: 12,
      },
    },
  },

  verticalLineSafety: {
    position: "absolute",
    bottom: 0,
    width: 1,
    height: 15,
    background: "#FFF",
  },

  safetyContainer: {
    position: "absolute",
    top: -10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // left: `calc(50% - 50px)`,
  },

  triangleItem: {
    top: "-15%",
    width: 15,
    height: 15,
    background: "#FFF",
    "-webkit-clip-path": "polygon(50% 0, 100% 100%, 0 100%)",
    clipPath: "polygon(50% 0, 100% 100%, 0 100%)",
    transform: "rotate(180deg)",
    position: "absolute",
    right: "calc(50% - 8px)",
  },
}));

function HealthFactor() {
  const classes = useStyles();
  const {
    totalEstimatedOutstandingInUSD,
    totalEstimatedPITRemaining,
    totalEstimatedWeightedLoanInUSD,
    decimalOfContractToken,
  } = useBorrowContext();

  const usdcToken = localStorage.getItem("usdcToken");
  const decimalUSDC = useMemo(
    () => decimalOfContractToken && usdcToken && decimalOfContractToken[usdcToken],
    [decimalOfContractToken, usdcToken]
  );

  const totalHealth = useMemo(() => {
    if (totalEstimatedWeightedLoanInUSD && totalEstimatedPITRemaining)
      return totalEstimatedWeightedLoanInUSD.add(totalEstimatedPITRemaining);

    return 0;
  }, [totalEstimatedPITRemaining, totalEstimatedWeightedLoanInUSD]);

  const healthPercentage = useMemo(() => {
    if (totalHealth && totalEstimatedWeightedLoanInUSD) {
      return (
        (Number(
          totalHealth.div(
            totalEstimatedWeightedLoanInUSD.isZero() ? 1 : totalEstimatedWeightedLoanInUSD
          )
        ) -
          1) *
        100
      ).toFixed(2);
    }

    return 0;
  }, [totalEstimatedWeightedLoanInUSD, totalHealth]);

  const debtPercentage = useMemo(() => {
    let percentage;
    if (totalHealth) {
      percentage = (Number(totalEstimatedOutstandingInUSD) * 100) / Number(totalHealth);
      if (percentage < 50) {
        percentage = Math.ceil(percentage);
      }
    }

    return percentage;
  }, [totalHealth, totalEstimatedOutstandingInUSD]);

  // eslint-disable-next-line consistent-return
  const remainingPercentage = useMemo(() => 100 - (debtPercentage || 0), [debtPercentage]);

  const colorSafetyBuffer = getHealthColor(remainingPercentage);

  return (
    <Box className={classes.healthFactorWrapper}>
      <LinearProgress
        value={50}
        className={classes.progress}
        classes={{ barColorPrimary: classes.barColorPrimary }}
      />

      <Box className={classes.infoContainer}>
        <Box
          className={classes.infoBox}
          sx={{ width: debtPercentage <= 2 ? "2%" : `${debtPercentage}%` }}
        >
          <Box
            className={classes.triangleItem}
            sx={{
              right: "-11.5px",
            }}
          />
          <Box className={classes.safetyBuffer}>
            <Box className={classes.safetyContainer} sx={{ right: 0 }}>
              <Box sx={{ position: "absolute", left: debtPercentage < 10 ? "-30px" : "unset" }}>
                <Box display="flex" gap={1}>
                  <Typography sx={{ textWrap: "nowrap" }} component="p" color={colorSafetyBuffer}>
                    Account health
                  </Typography>
                  <IconInfo title="Your account health. Safety Buffer is the maximum collateral price decline or maximum capital price increase before your account is subject to liquidation. Account health considers both regular collateral deposits, regular loans and also any leveraged trading positions." />
                </Box>
                <Typography sx={{ textWrap: "nowrap" }} component="p" color={colorSafetyBuffer}>
                  Safety Buffer = <NumericText value={healthPercentage} precision={2} suffix="%" />
                </Typography>
              </Box>
            </Box>
            <Box
              className={classes.verticalLineSafety}
              sx={{
                right: 0,
              }}
            />
          </Box>
          <Box className={classes.line}>
            <span>&lt;</span>
            <span>&gt;</span>
          </Box>
          <Box className={classes.infoValue}>
            <Box className={classes.verticalLine} />
            <Box
              sx={{
                position: "relative",
                left: 0,
                // left: +totalEstimatedOutstandingInUSD < 1000 ? "0" : "40%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p>Debt</p>
              <NumericText
                value={Number(formatUnits(totalEstimatedOutstandingInUSD || 0, decimalUSDC) || 0)}
                moneyValue
              />
            </Box>
          </Box>
        </Box>
        <Box
          className={classes.infoBox}
          sx={{ width: remainingPercentage <= 2 ? "2%" : `${remainingPercentage}%` }}
        >
          <Box className={classes.line}>
            <span>&lt;</span>
            <span>&gt;</span>
          </Box>
          <Box className={classes.infoValue}>
            <Box className={classes.verticalLine} />
            <p>Remaining credit</p>
            <NumericText
              value={Number(formatUnits(totalEstimatedPITRemaining || 0, decimalUSDC) || 0)}
              moneyValue
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default HealthFactor;
