import { BigNumber, ethers } from "ethers";
import { formatUnits } from "utils/number";
import { useWallet } from "hooks";
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import { useMultiCallContractInstance } from "hooks/contract/multicall/useMultiCallContract";
import { get, reduce } from "lodash";
import { useQuery } from "react-query";
import { ERC20TokenABI, FTokenABI } from "utils/ethereum/abi";

const handleGetResult = (result) => {
  let cashFToken = {};

  const final = reduce(
    result,
    (res, current, key) => {
      const raw = get(current, ["callsReturnContext", "0", "returnValues"], "0");
      const methodName = get(current, ["callsReturnContext", "0", "methodName"], "0");

      if (methodName === "allowance")
        return {
          ...res,
          [key]: {
            allowance: ethers.BigNumber.from(raw),
          },
        };

      const decimal = get(current, ["callsReturnContext", "1", "returnValues", 0], "0");
      const lendingToken = get(current, ["callsReturnContext", "1", "reference"], "0");

      cashFToken = {
        ...cashFToken,
        [lendingToken]: {
          cash: formatUnits(BigNumber.from(raw[0]?.hex), decimal),
        },
      };
      return res;
    },
    {}
  );

  return { final, cashFToken };
};

export const useAllowanceToken = (fTokens = []) => {
  const { account, chainId } = useWallet();
  const { availableBorrowTokens = [] } = useFetchGraphData();

  const multicallInstance = useMultiCallContractInstance();

  return useQuery(
    ["allowance", account, chainId],
    async () => {
      const listRequestToTokenContracts = [];

      availableBorrowTokens.forEach((token) => {
        listRequestToTokenContracts.push({
          contractAddress: token.address,
          abi: ERC20TokenABI,
          reference: token.address,
          calls: [
            {
              methodParameters: [account, fTokens.find((o) => o.token === token.address).ftoken],
              methodName: "allowance",
              reference: "isAllowanceForFToken",
            },
          ],
        });
      });

      fTokens.forEach((token) => {
        listRequestToTokenContracts.push({
          contractAddress: token.ftoken,
          abi: FTokenABI,
          reference: token.ftoken,
          calls: [
            {
              methodParameters: [],
              methodName: "getCash",
              reference: "CashForLendingToken",
            },
            {
              methodParameters: [],
              methodName: "decimals",
              reference: token.token,
            },
          ],
        });
      });

      const { results } = await multicallInstance.call(listRequestToTokenContracts);

      const { final: allowanceForFToken, cashFToken } = handleGetResult(results);

      return {
        allowanceForFToken,
        cashFToken,
      };
    },
    {
      enabled: fTokens.length > 0 && availableBorrowTokens.length > 0,
    }
  );
};
