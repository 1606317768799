import { BigNumber } from "ethers";
import { formatUnits } from "utils/number";
import { useWallet } from "hooks";
import { useMultiCallContractInstance } from "hooks/contract/multicall/useMultiCallContract";
import { get, reduce } from "lodash";
import { useQuery } from "react-query";
import { ERC20TokenABI } from "utils/ethereum/abi";
// import { useGetTokens } from "./useTokenSupported";

const handleGetResult = (result) => {
  const final = reduce(
    result,
    (res, current) => {
      const decimalHex = get(current, ["callsReturnContext", "0", "returnValues"], "0");
      const tokenAddress = get(current, ["callsReturnContext", "0", "reference"], "");
      res[tokenAddress] = formatUnits(BigNumber.from(decimalHex), 0);
      return res;
    },
    {}
  );
  return final;
};

export const useDecimalToken = (tokens = []) => {
  const { account, chainId } = useWallet();
  // const { availableBorrowTokens, projectTokenList } = useGetTokens();
  // const tokens = [...availableBorrowTokens, ...projectTokenList];
  const multicallInstance = useMultiCallContractInstance();

  return useQuery(
    ["decimals", account, chainId],
    async () => {
      const listRequestToTokenContracts = [];

      tokens.forEach((token) => {
        listRequestToTokenContracts.push({
          contractAddress: token.address,
          abi: ERC20TokenABI,
          reference: token.address,
          calls: [
            {
              methodParameters: [],
              methodName: "decimals",
              reference: token.address,
            },
          ],
        });
      });
      const { results } = await multicallInstance.call(listRequestToTokenContracts);

      const decimals = handleGetResult(results);
      console.log({ decimals });
      return decimals;
    },
    {
      enabled: !!tokens?.length,
    }
  );
};
