import { PRICE_PROVIDER_AGGREGATOR_CONTRACT } from "constants/NetworkChainId";
import { PITContractMethod, TokenMethod } from "context/tokens/methodName";
import { BigNumber } from "ethers";
import BNjs from "bignumber.js";
import { formatUnits } from "utils/number";
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import { useMultiCallContractInstance } from "hooks/contract/multicall/useMultiCallContract";
import useWallet from "hooks/useWallet";
import { flatten, get, groupBy, isEmpty } from "lodash";
import { useQuery } from "react-query";
import { ERC20TokenABI, PriceProviderAggregatorABI } from "utils/ethereum/abi";
import { getPrimaryIndexToken } from "utils/ethereum/contracts";

function handleGetRequestLendingInfo({ PITToken, availableBorrowTokens, account, chainId }) {
  const requests = [];

  const fTokenRequest = {
    contractAddress: PITToken.address,
    abi: PITToken.abi,
    reference: "PITContract",
    calls: [],
  };

  const PriceProviderAggregatorRequest = {
    contractAddress: PRICE_PROVIDER_AGGREGATOR_CONTRACT[chainId],
    abi: PriceProviderAggregatorABI,
    reference: "PriceProviderContract",
    calls: [],
  };

  availableBorrowTokens.forEach((token) => {
    fTokenRequest.calls.push({
      methodParameters: [token.address],
      methodName: PITContractMethod.lendingTokenInfoMethod,
      reference: token.address,
    });

    PriceProviderAggregatorRequest.calls.push({
      methodParameters: [token.address],
      methodName: "getPrice",
      reference: token.address,
    });

    requests.push({
      contractAddress: token.address,
      abi: ERC20TokenABI,
      reference: token.address,
      calls: [
        {
          methodName: TokenMethod.balanceOf,
          methodParameters: [account],
        },
        {
          methodName: TokenMethod.decimals,
          methodParameters: [],
        },
        {
          methodName: TokenMethod.name,
          methodParameters: [],
        },
        {
          methodName: TokenMethod.symbol,
          methodParameters: [],
        },
      ],
    });
  });

  return [...requests, fTokenRequest, PriceProviderAggregatorRequest];
}

const handleGetDataFromResultMulticall = ({ contractRefs = [], methodName, resultInRaw }) =>
  flatten(
    contractRefs.map((ref) => {
      const contract = get(resultInRaw, [ref, "callsReturnContext"], []);
      const contractAddress = get(
        resultInRaw,
        [ref, "originalContractCallContext", "contractAddress"],
        ""
      );

      const groupByMethod = groupBy(contract, "methodName");

      const methodResult = get(groupByMethod, methodName, []);

      return [...methodResult].map((e) => ({ ...e, contractAddress }));
    })
  );

export const useUserTokenInfo = () => {
  const { availableBorrowTokens } = useFetchGraphData();
  const { account, chainId } = useWallet();
  const multicallContract = useMultiCallContractInstance();

  return useQuery(
    ["f-token-info", account, chainId],
    async () => {
      const PITToken = getPrimaryIndexToken(chainId);

      const lendTokenRequest = handleGetRequestLendingInfo({
        PITToken,
        availableBorrowTokens,
        account,
        chainId,
      });

      const { results } = await multicallContract.call(lendTokenRequest);

      const priceOfTokens = handleGetDataFromResultMulticall({
        contractRefs: ["PriceProviderContract"],
        methodName: "getPrice",
        resultInRaw: results,
      }).map((o) => {
        const priceBN = get(o, ["returnValues", 0], 0);
        const priceDecimal = get(o, ["returnValues", 1], 0);
        const lendingToken = get(o, ["reference"], "");
        return {
          price: formatUnits(priceBN, priceDecimal),
          lendingToken,
        };
      });

      const fTokens = handleGetDataFromResultMulticall({
        contractRefs: ["PITContract"],
        methodName: PITContractMethod.lendingTokenInfoMethod,
        resultInRaw: results,
      }).map((o) => ({
        lendingToken: get(o, ["methodParameters", 0], ""),
        ftoken: get(o, ["returnValues", 2], ""),
      }));

      const decimalOfLendingToken = handleGetDataFromResultMulticall({
        contractRefs: availableBorrowTokens.map((l) => l.address),
        methodName: TokenMethod.decimals,
        resultInRaw: results,
      }).map((o) => ({
        token: get(o, "contractAddress", ""),
        value: BigNumber.from(get(o, ["returnValues"], "")).toString(),
      }));

      const balanceOfLendingToken = handleGetDataFromResultMulticall({
        contractRefs: availableBorrowTokens.map((l) => l.address),
        methodName: TokenMethod.balanceOf,
        resultInRaw: results,
      }).map((o, idx) => {
        const lendingToken = get(o, "contractAddress", "");
        const balance = formatUnits(get(o, ["returnValues"], ""), decimalOfLendingToken[idx].value);
        const priceInfo = priceOfTokens.find((p) => p.lendingToken === lendingToken);

        return {
          token: lendingToken,
          balance,
          balanceInUsd: new BNjs(balance).multipliedBy(priceInfo.price).toString(),
          decimal: decimalOfLendingToken[idx].value,
          priceInfo,
          ...availableBorrowTokens.find((x) => x.address === lendingToken),
        };
      });

      return {
        fTokens,
        balanceOfLendingToken,
        priceLendingToken: priceOfTokens,
      };
    },
    {
      enabled: !isEmpty(availableBorrowTokens) && Boolean(account),
    }
  );
};
