import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BigNumber from "bignumber.js";
import { BorrowModal, CoinInfo, Dialog, ShareModal } from "components";
import { logoBySymbol } from "constants/TokenListGoerliNetwork";
import { useLendingAssetContext } from "context/tokens/BorrowTokenContext";
import { useFetchGraphData } from "hooks/query/graphQL/useFetchGraphData";
import _get from "lodash/get";
import numeral from "numeral";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useTokenInfo } from "./useTokenInfo";

export const BorrowTokenContext = createContext();
export const ShareContext = createContext();

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    maxWidth: 900,
  },
  rootContainer: {
    minWidth: 850,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    overflowY: "unset",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },

  headerContent: {
    borderBottom: "1px solid",
    paddingBottom: 8,
    paddingTop: 8,
  },
  content: {
    addingBottom: 8,
    paddingTop: 8,
  },

  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  supplyBox: {
    borderBottom: "1px solid #E0E0E0",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  supplyRates: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "18px",
  },
  formatValue: {
    wordWrap: "break-word",
  },
  headerCell: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    textWrap: "nowrap",
    padding: theme.spacing(1),
    fontWeight: "400",
    lineHeight: "1.5",

    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  bodyCell: {
    borderBottom: "unset !important",
  },
  tbody: {
    "& td": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      border: "unset",

      "& p, & h6": {
        [theme.breakpoints.down("xs")]: {
          fontSize: 14,
        },
      },
    },
  },
  thead: {
    borderBottom: "1px solid",
  },
  buttonBorrow: {
    [theme.breakpoints.down("xs")]: {
      "& span": {
        fontSize: 12,
      },
      padding: "8px 18px",
    },
  },
}));

const formatBalance = (balance) => {
  if (+balance < 100000) return +balance.toFixed(2);
  return numeral(balance).format("0,000.00a").toUpperCase();
};

export const TokenInfo = (o) => {
  const { symbol, address, logo, liquidity, cashValue, lvr } = o;
  const classes = useStyles();
  const { setTokenBorrow } = useContext(BorrowTokenContext);
  const { isLoading, isError } = useTokenInfo(address);

  if (isLoading || isError) return <></>;

  return (
    <>
      <TableRow>
        <TableCell className={classes.bodyCell} align="left" sx={{ p: "8px 0", width: "20%" }}>
          <CoinInfo logoUrl={logo} size="md">
            <Typography variant="h6">{symbol}</Typography>
          </CoinInfo>
        </TableCell>
        <TableCell className={classes.bodyCell} align="left" sx={{ p: "8px 0", width: "15%" }}>
          <Typography variant="h6">{numeral(lvr).format("0,0%")}</Typography>
        </TableCell>
        <TableCell className={classes.bodyCell} align="left" sx={{ p: "8px 0", width: "20%" }}>
          <Typography variant="h6">
            {numeral(_get(o, "borrowApy", 0)).format("0.[0000]")}
            {"%"}
          </Typography>
        </TableCell>
        <TableCell className={classes.bodyCell} align="left" sx={{ p: "8px 0", width: "auto" }}>
          <Typography variant="h6">
            {formatBalance(+liquidity || 0)} {symbol}
          </Typography>
        </TableCell>
        <TableCell className={classes.bodyCell} align="left" sx={{ p: "8px 0", width: "20%" }}>
          <Typography variant="h6" className={classes.formatValue}>
            ${formatBalance(+cashValue || 0)}
          </Typography>
        </TableCell>
        <TableCell className={classes.bodyCell} align="left" sx={{ p: "8px 0", width: "10%" }}>
          <Button className={classes.buttonBorrow} onClick={() => setTokenBorrow(o)}>
            Borrow
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const DataTokenList = ({ list }) => (
  <>
    {list.map((o) => (
      <TokenInfo key={`token-${o.address}`} {...{ ...o, logo: logoBySymbol[o?.symbol] }} />
    ))}
  </>
);

const SelectAssetModal = (props) => {
  const { open } = props;
  const ctx = useBorrowContext();
  const { data: lendingAssetCtx } = useLendingAssetContext();
  const { getBorrowingApy } = useFetchGraphData();

  const classes = useStyles();
  const [tokenBorrow, setTokenBorrow] = useState();
  const [openShare, setOpenShare] = useState(false);
  const [shareData, setShareData] = useState({
    token: "",
    amount: "",
    apy: "",
    colToken: "",
  });
  const lendingAssetSymbol = useMemo(
    () => _get(props, ["data", "data", "lendingAsset"], null),
    [props]
  );

  const [availableBorrowTokens, decimalOfContractToken, liquidityAmounts, lvrLending] = useMemo(
    () => [
      _get(ctx, ["availableBorrowTokens"], []),
      _get(ctx, ["decimalOfContractToken"], {}),
      _get(ctx, ["cashFToken"], {}),
      _get(ctx, ["lvrLending"], {}),
    ],
    [ctx]
  );
  const [priceLendingToken] = useMemo(
    () => [_get(lendingAssetCtx, ["priceLendingToken"], [])],
    [lendingAssetCtx]
  );

  const lendingAsset = availableBorrowTokens.find(
    (o) => o?.symbol?.toUpperCase() === lendingAssetSymbol?.toUpperCase()
  );
  const handleClose = () => {
    setOpenShare(false);
  };

  useEffect(() => {
    if (open) {
      setTokenBorrow(lendingAsset || null);
    }
    return () => {
      setTokenBorrow(null);
    };
  }, [open, lendingAsset]);

  return (
    <BorrowTokenContext.Provider
      value={{
        tokenBorrow,
        setTokenBorrow,
        shareData,
        setShareData,
        cash: liquidityAmounts,
      }}
    >
      <Dialog {...props} className={classes.modalWrapper}>
        {!tokenBorrow && open && (
          <>
            <TableContainer className={classes.rootContainer}>
              <Table>
                <TableHead className={classes.thead}>
                  <TableRow>
                    <TableCell align="left" sx={{ p: "8px 0", width: "20%", border: "unset" }}>
                      <Typography className={classes.headerCell}>Borrow Assets</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ p: "8px 0", width: "15%", border: "unset" }}>
                      <Typography className={classes.headerCell}>LVR</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ p: "8px 0", width: "20%", border: "unset" }}>
                      <Typography className={classes.headerCell}>Borrow APY</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ p: "8px 0", width: "25%", border: "unset" }}>
                      <Typography className={classes.headerCell}>Available</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ p: "8px 0", width: "20%", border: "unset" }}>
                      <Typography className={classes.headerCell}>Value ($)</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ p: "8px 0", width: "10%", border: "unset" }} />
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tbody}>
                  <DataTokenList
                    list={availableBorrowTokens.map((p) => {
                      const borrowApy = getBorrowingApy(p.address);
                      const liquidity = _get(liquidityAmounts, [p.address, "cash"]);
                      const lvr = _get(lvrLending, [p.address]);
                      const price =
                        [...priceLendingToken].find((o) => p.address === o.lendingToken)?.price ||
                        0;
                      const cashValue = new BigNumber(liquidity).multipliedBy(price).toString();
                      return {
                        ...p,
                        borrowApy: _get(borrowApy, "amount", 0),
                        liquidity,
                        cashValue,
                        lvr,
                      };
                    })}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {tokenBorrow && open && (
          <BorrowModal
            {...{
              ...props,
              token: {
                ...tokenBorrow,
                decimal: decimalOfContractToken[tokenBorrow?.address?.toLowerCase()],
              },
              openShare: () => setOpenShare(true),
            }}
          />
        )}
      </Dialog>
      <ShareModal open={openShare} onCloseModal={handleClose} data={shareData} type="borrow" />
    </BorrowTokenContext.Provider>
  );
};

export default SelectAssetModal;
