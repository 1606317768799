import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 18,
  },
}));

const BreadcrumbItem = ({ to, children }) => {
  const classes = useStyles();

  if (to) {
    return (
      <Link color="inherit" component={RouterLink} to={to}>
        {children}
      </Link>
    );
  }

  return (
    <Typography color="secondary" className={classes.text}>
      {children}
    </Typography>
  );
};

BreadcrumbItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BreadcrumbItem.defaultProps = {
  to: "",
};

export default BreadcrumbItem;
