import { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Link } from "@material-ui/core";

import { MobileMenuButton } from "../MobileMenuButton";

const useStyles = makeStyles((theme) => ({
  navigationMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  link: {
    margin: "10px 0 20px 0",
    padding: "10px 24px",
    border: "1px solid transparent",

    lineHeight: "23px",
    color: theme.palette.common.white,
    fontSize: 18,

    "&:hover, &.active": {
      border: `1px solid ${theme.palette.secondary.main}`,
      boxSizing: "border-box",
      borderRadius: 100,
    },
  },
}));

const MobileMenu = ({ links }) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(false);

  const handleButtonClick = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <MobileMenuButton onClick={handleButtonClick} active={menuOpen} />
      <Drawer open={menuOpen} transitionDuration={500}>
        <nav className={classes.navigationMobile}>
          {links.map(({ path, label, exact }) => (
            <Link
              key={path}
              to={path}
              exact={exact}
              component={NavLink}
              className={classes.link}
              underline="none"
              onClick={handleLinkClick}
            >
              {label}
            </Link>
          ))}
        </nav>
      </Drawer>
    </>
  );
};

MobileMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
      exact: PropTypes.bool,
    })
  ).isRequired,
};

export default MobileMenu;
