import { makeStyles } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import { NumericText } from "components";
import { OpenPosition } from "components/Button/OpenPosition";
import { Exposure } from "components/Leveraged/Exposure";
import { IconInfo } from "components/Leveraged/IconInfo";
import { SafetyBuffer } from "components/SafetyBuffer/SafetyBuffer";
import { Text } from "components/Title/Text";
import { LeverageType } from "constants/leverageType";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import { get } from "lodash";
import numeral from "numeral";
import { useMemo } from "react";
import DiagramSB from "./DiagramSB";

const useStyles = makeStyles(() => ({
  alignCenterBox: {
    display: "flex",
    alignItems: "center",
  },
  rowStyle: {
    flexWrap: "wrap",
  },
  AssetColumn: {
    "&:first-child": {
      paddingRight: "5px",
    },
    "&:last-child": {
      paddingLeft: "5px",
    },
  },

  AssetItem: {
    marginBottom: "12px",
    alignItems: "start",

    "&.safetyBuffer": {
      paddingTop: 8,
    },

    "&.LP": {
      paddingTop: 18,
    },
  },

  SB: {
    width: "70%",
  },

  infoIcon: {
    marginTop: "1px",
    marginLeft: "2px",
    display: "flex",
  },
}));

function AssetDash() {
  const classes = useStyles();
  const { exposureLimit, shortAssetSelected, longAssetSelected } = useLeverageContext();

  const longSymbol = get(longAssetSelected, "symbol", "");
  const shortSymbol = get(shortAssetSelected, "symbol", "");
  const borrowApy = get(shortAssetSelected, "borrowApy", 0);

  const formattedExposureLimit = useMemo(
    () => numeral(exposureLimit).format("0,0.[00]"),
    [exposureLimit]
  );

  const liqPrice = 0.14;

  const asset = [
    {
      firstChild: <Exposure />,
      secondChild: (
        <Grid container display="flex" className={classes.rowStyle}>
          <Grid item xs={12}>
            <Box display="flex" gridGap={2}>
              <Text>Exposure Limit</Text>
              <IconInfo title="Value of the available funds in the short asset lending pool." />
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.alignCenterBox}>
            ${exposureLimit !== null ? formattedExposureLimit : "N/A"}
          </Grid>
        </Grid>
      ),
    },
    {
      firstChild: (
        <Box display="flex" justifyContent="space-between" flexWrap="nowrap" alignItems="center">
          <Text>{`9.4 ${longSymbol}`}</Text>
          <Text>:</Text>
        </Box>
      ),
      secondChild: <Text>{`0.89 ${shortSymbol}`}</Text>,
    },
    {
      firstChild: <Text>Price</Text>,
      secondChild: (
        <Text>
          {shortAssetSelected && longAssetSelected
            ? `0.14 ${longAssetSelected?.symbol}/${shortAssetSelected?.symbol}`
            : "..."}
        </Text>
      ),
    },
    {
      firstChild: <Text>Available margin</Text>,
      secondChild: (
        <Text>
          <NumericText moneyValue value="242098" />
        </Text>
      ),
    },
    {
      skip: true,
      child: <SafetyBuffer />,
    },
    {
      skip: true,
      child: (
        <Box className={classes.SB}>
          <DiagramSB />
        </Box>
      ),
      additionalClass: "safetyBuffer",
    },
    {
      firstChild: (
        <Box display="flex" alignItems="center">
          <Box>
            <Text>Liquidation Price</Text>
          </Box>
          <Box className={classes.infoIcon}>
            <IconInfo title="Price of long asset / short asset that will result in the position becoming liquidatable." />
          </Box>
        </Box>
      ),
      secondChild: (
        <>
          {numeral(Number.isNaN(+liqPrice) ? 0 : liqPrice).format("[0,0].[00]")} {longSymbol}/
          {shortSymbol}
        </>
      ),
      additionalClass: "LP",
    },
    {
      firstChild: (
        <Box display="flex" alignItems="center">
          <Box>
            <Text>Interest Rate</Text>
          </Box>
          <Box className={classes.infoIcon}>
            <IconInfo title="Interest rate charged for the borrowed (short) capital asset." />
          </Box>
        </Box>
      ),
      secondChild: <>{numeral(borrowApy).format("[0,0].[0000]%")}</>,
    },
  ];

  return (
    <>
      {asset.map((i, idx) => (
        <Grid
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          container
          className={`${classes.AssetItem} ${i.additionalClass}`}
          alignItems="start"
        >
          <>
            {i?.skip && i.child}
            {!i?.skip && (
              <>
                <Grid item sm={6} xs={12} className={classes.AssetColumn}>
                  {i?.firstChild}
                </Grid>
                <Grid item sm={6} xs={12} className={classes.AssetColumn}>
                  {i?.secondChild}
                </Grid>
              </>
            )}
          </>
        </Grid>
      ))}
      <Box sx={{ marginTop: "16px" }}>
        <OpenPosition leverageType={LeverageType.MARGIN_TRADE} />
      </Box>
    </>
  );
}

export default AssetDash;
