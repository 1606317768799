export const LoadingStatus = {
  START: "START",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export default function loadingReducer(state, action) {
  switch (action.type) {
    case LoadingStatus.START:
      return { ...state, isLoading: true };
    case LoadingStatus.SUCCESS:
      return { ...state, isLoading: false, data: action.payload };
    case LoadingStatus.ERROR:
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}
