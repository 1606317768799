import theme from "../theme";

const MuiTypography = {
  props: {},
  overrides: {
    body2: {
      color: theme.palette.primary.dark,
    },

    colorPrimary: {
      color: theme.palette.primary.light,
    },

    colorSecondary: {
      color: theme.palette.common.white,
    },

    colorTextPrimary: {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },

    h4: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
  },
};

export default MuiTypography;
