import { Button, makeStyles } from "@material-ui/core";
import { Box, Dialog, Typography } from "@mui/material";
import { CoinInfo, NumericText, ShareModal, Table, TableSkeleton } from "components";
import { Text } from "components/Title/Text";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { useWallet } from "hooks";
import { RedeemModal, SupplyModal } from "pages/MultiAssetDashboard/components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { formatUnits } from "utils/number";
import useWindowDimensions from "pages/BorrowerNewDashboard/hooks/useWindowDimensions";
import { useCommonStyle } from "./commonStyle";

const MARIGN_VALUE = 16;

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #434849",
    marginBottom: 16,

    "& .MuiTableContainer-root": {
      maxHeight: "379px",

      [theme.breakpoints.down("sm")]: {
        maxHeight: "unset",
      },
    },

    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#191919",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  title: {
    background: "#191919",
    padding: "8px 8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#FFF",
    gap: 10,

    "& p": {
      margin: 0,
    },
  },

  info: {
    display: "flex",
    gap: 20,
    "& p": {
      fontSize: 14,
    },
  },

  TableStyle: {
    backgroundColor: "rgb(33 34 34) !important",
    color: "#fff !important",
    "& .MuiTableRow-head": {
      borderBottom: "#3f4445 1px solid",
    },
    "& .MuiTableCell-root": {
      borderBottom: "#3f4445 1px solid",
      fontSize: "14px",
      textWrap: "nowrap",
    },
    "& .icon-style": {
      width: "20px",
      position: "absolute",
      right: 0,
      bottom: "31%",
    },

    [theme.breakpoints.down("sm")]: {
      "& .MuiTableCell-root": {
        fontSize: "12px",
      },
    },
  },

  EmptyDataText: {
    textAlign: "center",
    fontSize: "1.5rem",
    marginTop: "1.5rem",
    color: "#fff",
  },

  actionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.5),
    justifyContent: "end",
  },

  numericText: {
    textWrap: "nowrap",
  },
}));

function MyLendingPosition({ setMyLendingHeight }) {
  const classes = useStyles();
  const commonStyle = useCommonStyle();
  const windowDimension = useWindowDimensions();

  const {
    decimalOfContractToken,
    isLoading,
    availableBorrowOrLendTokens,
    totalEstimatedOutstandingInUSD,
  } = useBorrowContext();

  const { account } = useWallet();

  const [supplyDataModal, setSupplyDataModal] = useState();
  const [redeemDataModal, setRedeemDataModal] = useState();

  const [openShare, setOpenShare] = useState(false);
  const [shareData, setShareData] = useState({
    token: "",
    amount: "",
    apy: "",
  });

  const usdcToken = localStorage.getItem("usdcToken");
  const decimalUSDC = useMemo(
    () => decimalOfContractToken && usdcToken && decimalOfContractToken[usdcToken],
    [decimalOfContractToken, usdcToken]
  );

  const filterLendingPosition = useMemo(
    () =>
      availableBorrowOrLendTokens &&
      availableBorrowOrLendTokens.filter((o) => Number(o.balanceOfUnderlyingView) !== 0),
    [availableBorrowOrLendTokens]
  );

  const COLUMNS = [
    {
      Header: <Text>Asset</Text>,
      accessor: "asset",
      Cell: ({
        row: {
          original: { symbol, logo },
        },
      }) => <CoinInfo logoUrl={logo}>{symbol}</CoinInfo>,
    },
    {
      Header: (
        <Box>
          <Text>Supply </Text>
          <Text>APY</Text>
        </Box>
      ),
      accessor: "lendingAPY",
      Cell: ({ value }) => <NumericText value={value?.amount || 0} precision={2} suffix="%" />,
    },
    {
      Header: (
        <Box display="flex" sx={{ textWrap: "nowrap" }} gridGap={2}>
          <Text>Supplied</Text>
        </Box>
      ),
      accessor: "balanceOfUnderlyingView",
      Cell: ({
        value,
        row: {
          original: { priceInfo, symbol },
        },
      }) => (
        <>
          <Typography className={classes.numericText}>
            <NumericText value={Number(value).toFixed(9)} precision={2} suffix={symbol} />
          </Typography>
          <Box>
            $
            <NumericText value={Number(priceInfo.price) * Number(value).toFixed(9)} precision={2} />
          </Box>
        </>
      ),
    },
    {
      Header: (
        <Box>
          <Text>Wallet </Text>
          <Text>balance</Text>
        </Box>
      ),
      accessor: "balance",
      Cell: ({
        value,
        row: {
          original: { balanceInUsd, symbol },
        },
      }) => (
        <>
          <Typography className={classes.numericText}>
            <NumericText value={Number(value).toFixed(9)} precision={2} suffix={symbol} />
          </Typography>
          <Box>
            $<NumericText value={Number(balanceInUsd).toFixed(9)} precision={2} />
          </Box>
        </>
      ),
    },
  ];

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        Header: "",
        accessor: "button",
        props: {
          align: "right",
        },
        Cell: ({ row: { original } }) => (
          <Box className={classes.actionBox}>
            <Button className={commonStyle.button} onClick={() => setSupplyDataModal(original)}>
              Lend
            </Button>
            <Button className={commonStyle.button} onClick={() => setRedeemDataModal(original)}>
              Redeem
            </Button>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!filterLendingPosition) return;
    if (!supplyDataModal) return;

    const activeToken = filterLendingPosition.find(
      ({ address }) => address === supplyDataModal.address
    );
    if (activeToken) {
      setSupplyDataModal(activeToken);
    }
  }, [filterLendingPosition, supplyDataModal]);

  const handleCloseModalShare = useCallback(() => {
    setOpenShare(false);
  }, []);

  const handleClose = useCallback(() => {
    setSupplyDataModal(null);
    setRedeemDataModal(null);
  }, []);

  const handleSetRef = useCallback(
    (node) => {
      if (node) setMyLendingHeight(node?.getBoundingClientRect()?.height + MARIGN_VALUE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowDimension]
  );

  return (
    <Box
      className={`${classes.container} ${commonStyle.containerBackground}`}
      ref={(node) => handleSetRef(node)}
    >
      <Box className={classes.title}>
        <p style={{ fontSize: "18px", paddingLeft: "16px" }}>Your lending positions</p>
        <p>
          Balance:{" "}
          <NumericText
            value={Number(formatUnits(totalEstimatedOutstandingInUSD || 0, decimalUSDC) || 0)}
            moneyValue
          />
        </p>
      </Box>

      {isLoading && !filterLendingPosition ? (
        <TableSkeleton rows={3} />
      ) : (
        account &&
        filterLendingPosition?.length > 0 && (
          <>
            <Table stickyHeader columns={columns} data={filterLendingPosition} />

            {supplyDataModal && (
              <Dialog open={supplyDataModal} onClose={handleClose} noPadding>
                <SupplyModal
                  data={{
                    ...supplyDataModal,
                  }}
                  onClose={handleClose}
                  openShare={() => setOpenShare(true)}
                  setShareData={() => setShareData()}
                />
              </Dialog>
            )}

            {redeemDataModal && (
              <Dialog open={redeemDataModal} onClose={handleClose} noPadding>
                <RedeemModal
                  data={{
                    ...redeemDataModal,
                    lendingTokenBalance: redeemDataModal.balanceOfUnderlyingView,
                  }}
                  onClose={handleClose}
                />
              </Dialog>
            )}

            <ShareModal
              open={openShare}
              onCloseModal={handleCloseModalShare}
              data={shareData}
              type="borrow"
            />
          </>
        )
      )}
      {!isLoading && (filterLendingPosition?.length <= 0 || !filterLendingPosition) && (
        <p className={classes.EmptyDataText}>You have no open lending positions</p>
      )}
    </Box>
  );
}

export default MyLendingPosition;
