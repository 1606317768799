import PropTypes from "prop-types";
import clsx from "clsx";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    position: "absolute",
    right: 0,
    top: 5,
    width: 35,
    height: 32,
    minWidth: 0,
    padding: 0,
    backgroundColor: "transparent",
    border: "none",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  menuActive: {
    "& $top": {
      top: "50%",
      transform: "rotate(45deg)",
    },

    "& $middle": {
      opacity: 0,
      transform: "rotate(45deg)",
    },

    "& $bottom": {
      top: "50%",
      transform: "rotate(-45deg)",
    },
  },

  icon: {
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.secondary.main,
    transition: theme.transitions.main,
  },

  top: {},

  middle: {
    top: "50%",
    width: "70%",
    opacity: 1,
  },

  bottom: {
    top: "100%",
  },
}));

const MobileMenuButton = ({ active, onClick }) => {
  const classes = useStyles();
  return (
    <Button
      edge="end"
      aria-label="menu"
      className={clsx(classes.menuButton, { [classes.menuActive]: active })}
      onClick={onClick}
    >
      <span className={clsx(classes.icon, classes.top)} />
      <span className={clsx(classes.icon, classes.middle)} />
      <span className={clsx(classes.icon, classes.bottom)} />
    </Button>
  );
};

MobileMenuButton.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MobileMenuButton;
