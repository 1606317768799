import { useQuery } from "react-query";

import useWallet from "hooks/useWallet";
import { useAtomicRepaymentContractAction } from "hooks/contract/core/useAtomicRepaymentContract";

export const useGetRemainingDeposit = (prjToken) => {
  const { account, chainId } = useWallet();

  const { atomicRepaymentContract } = useAtomicRepaymentContractAction();

  const remainingDeposit = useQuery(
    ["remaining-deposit", account, chainId, prjToken],
    async () => {
      const res = await atomicRepaymentContract.getRemainingDeposit(account, prjToken);
      return res;
    },
    { enabled: Boolean(atomicRepaymentContract) }
  );

  return remainingDeposit.data;
};
