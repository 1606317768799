import { Box, Button, Paper, Tooltip } from "@material-ui/core";
import { CoinInfo, Dialog, NumericText, PaperTitle, ShareModal, Table } from "components";
import { useEffect, useMemo, useState } from "react";
import { RedeemModal } from "../RedeemModal";
import { SupplyModal } from "../SupplyModal/index";
import { ShareLendingContext } from "./ShareLendingContext";

const COLUMNS = [
  {
    Header: () => (
      <Tooltip
        title="List of assets that can be lent on the platform to earn interest. "
        arrow
        placement="top"
      >
        <Box display="inline">Asset</Box>
      </Tooltip>
    ),
    accessor: "name",
    props: {
      width: 140,
    },
    Cell: ({
      row: {
        original: { symbol, logo },
      },
    }) => <CoinInfo logoUrl={logo}>{symbol}</CoinInfo>,
  },
  {
    Header: () => (
      <Tooltip
        title="Current Annual Percentage Yield for each capital asset. Each capital asset has a separate market. Prevailing lender deposits vs borrower demand affects the market's APY."
        arrow
        placement="top"
      >
        <Box display="inline">Supply APY</Box>
      </Tooltip>
    ),
    accessor: "apy",
    props: {
      width: 100,
    },
    Cell: ({ value }) => <NumericText value={value} precision={2} suffix="%" />,
  },
  {
    Header: () => (
      <Tooltip
        title="Token count and value of your interest-earning deposits."
        arrow
        placement="top"
      >
        <Box display="inline">Supplied</Box>
      </Tooltip>
    ),
    accessor: "balanceOfUnderlyingView",
    props: {
      width: 100,
    },
    Cell: ({
      value,
      row: {
        original: { priceInfo },
      },
    }) => (
      <>
        <NumericText value={Number(value).toFixed(9)} precision={2} />
        <Box>
          $<NumericText value={Number(priceInfo.price) * Number(value).toFixed(9)} precision={2} />
        </Box>
      </>
    ),
  },
  {
    Header: () => (
      <Tooltip title="Token count and value held in your external wallet." arrow placement="top">
        <Box display="inline">Wallet Balance</Box>
      </Tooltip>
    ),
    accessor: "balance",
    props: {
      width: 150,
    },
    Cell: ({
      value,
      row: {
        original: { balanceInUsd },
      },
    }) => (
      <>
        <NumericText value={Number(value).toFixed(9)} precision={2} />
        <Box>
          $<NumericText value={Number(balanceInUsd).toFixed(9)} precision={2} />
        </Box>
      </>
    ),
  },
];
const LendingAssetsTable = ({ dataSource: data = [] }) => {
  const [modalData, setModalData] = useState();
  const [modalWithdrawData, setModalWithdrawData] = useState();
  const [openShare, setOpenShare] = useState(false);

  const [shareData, setShareData] = useState({
    token: "",
    amount: "",
    apy: "",
    colToken: "",
  });

  const onClose = () => {
    setModalData(null);
    setModalWithdrawData(null);
  };
  const handleClose = () => {
    setOpenShare(false);
  };
  useEffect(() => {
    if (!data) return;
    if (!modalData) return;

    const activeToken = data.find(({ lendingToken }) => lendingToken === modalData.lendingToken);
    if (activeToken) {
      setModalData(activeToken);
    }
  }, [data, modalData]);
  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        Header: "",
        accessor: "button",
        props: {
          align: "right",
          width: 150,
        },
        Cell: ({ row: { original } }) => (
          <Box style={{ display: "flex" }}>
            <Button
              fullWidth
              onClick={() => {
                setModalData(original);
              }}
              style={{ marginRight: 16, height: 36 }}
            >
              Deposit
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setModalWithdrawData(original);
              }}
              style={{ height: 36 }}
            >
              Withdraw
            </Button>
          </Box>
        ),
      },
    ],
    [setModalData]
  );

  return (
    <ShareLendingContext.Provider value={{ shareData, setShareData }}>
      <Paper>
        <PaperTitle>Lending Assets</PaperTitle>
        <Box mt={2}>
          <Table columns={columns} data={data} />
        </Box>

        {modalData && (
          <Dialog open={modalData} onClose={onClose} noPadding>
            <SupplyModal
              data={{
                ...modalData,
              }}
              onClose={onClose}
              openShare={() => setOpenShare(true)}
            />
          </Dialog>
        )}

        {modalWithdrawData && (
          <Dialog open={modalWithdrawData} onClose={onClose} noPadding>
            <RedeemModal
              data={{
                ...modalWithdrawData,
                lendingTokenBalance: modalWithdrawData.balanceOfUnderlyingView,
              }}
              onClose={onClose}
            />
          </Dialog>
        )}
      </Paper>
      <ShareModal open={openShare} onCloseModal={handleClose} data={shareData} type="lending" />
    </ShareLendingContext.Provider>
  );
};

export default LendingAssetsTable;
