import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import { CoinInfo } from "components";
import React from "react";

const useStyles = makeStyles({
  coinLogo: {
    position: "absolute",
    top: 15,
    left: 15,
    width: "50%",
  },
});

const DialogLogo = ({ logoUrl, name }) => {
  const classes = useStyles();
  return (
    <Box className={classes.coinLogo}>
      <CoinInfo logoUrl={logoUrl} size="large">
        {name}
      </CoinInfo>
    </Box>
  );
};

DialogLogo.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default DialogLogo;
