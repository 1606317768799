import { TextField, makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";
import { useDebounce } from "hooks/common/useDebounce";
import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";

const useStyles = makeStyles(() => ({
  textField: {
    border: "1px solid #434849",
    width: "100%",
    padding: "0 8px",
    paddingRight: "32px",
    borderRadius: "4px",

    "& input": {
      color: "#FFF",
      fontSize: 16,
      textAlign: "left",
    },
  },

  clearIcon: {
    width: 12,
    height: 12,
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
}));

function SearchField({ setInputDebounce }) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const inputSearch = useDebounce(search, 300);

  React.useEffect(() => {
    setInputDebounce(inputSearch.toLowerCase());
  }, [inputSearch, setInputDebounce]);

  const handleSearchChange = (e) => {
    setSearch(e?.target?.value);
  };

  const handleClear = () => {
    setSearch("");
  };

  return (
    <Box sx={{ position: "relative" }}>
      <TextField
        className={classes.textField}
        value={search}
        onChange={handleSearchChange}
        placeholder="Search for collateral"
      />
      {inputSearch && <CloseIcon onClick={handleClear} className={classes.clearIcon} />}
    </Box>
  );
}

export default SearchField;
