import { Redirect, Route, Switch } from "react-router-dom";

import { BorrowerNewDashboard, IndexMarket, TermsOfService, MarginTrade } from "pages";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={BorrowerNewDashboard} />
    <Route exact path="/markets" component={IndexMarket} />
    <Route exact path="/margin-trade" component={MarginTrade} />
    <Route exact path="/terms-of-service" component={TermsOfService} />
    {/* <Route exact path="/amplify" component={Amplify} />
    <Route exact path="/lend" component={MultiAsset} /> */}
    {/* <Route exact path="/markets-new" component={IndexMarketNew} /> */}
    {/* <Route exact path="/borrow-new" component={BorrowerNewDashboard} /> */}
    {/* <Route exact path="/lender-multi-asset" component={MultiAsset} /> */}
    <Redirect to="/" />
  </Switch>
);

export default Routes;
