import { makeStyles } from "@material-ui/core";
import { Grid } from "@mui/material";
import { LongAsset } from "../../LongAsset";
import { ShortAsset } from "../../ShortAsset";

const useStyles = makeStyles(() => ({
  TextTitle: {
    color: "#FFFFFF",
    fontSize: "18px",
    textAlign: "center",
    marginTop: "18px",
    marginBottom: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  FlexPosition: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row-reverse",
    justifyContent: "center",
    "@media(min-width: 1200px)": {
      justifyContent: "space-between",
    },
  },
  DashBoardBox: {
    width: "90%",
    marginTop: "50px",
    "@media(min-width: 1200px)": {
      width: "30%",
      marginTop: 0,
    },
  },
  ChartBox: {
    width: "90%",
    "@media(min-width: 1200px)": {
      width: "68%",
    },
  },
  AssetWrapper: {
    background: "#212222",
    borderRadius: "5px",
    justifyContent: "center",
  },
  AssetColumn: {
    "&:first-child": {
      paddingRight: "5px",
    },
    "&:last-child": {
      paddingLeft: "5px",
    },
  },
}));

function SelectAsset() {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.AssetWrapper}>
        <Grid item sm={6} xs={12} className={classes.AssetColumn}>
          <LongAsset />
        </Grid>
        <Grid item sm={6} xs={12} className={classes.AssetColumn}>
          <ShortAsset />
        </Grid>
      </Grid>
    </>
  );
}

export default SelectAsset;
