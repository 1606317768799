import { common, success } from "theme/palette";

const MuiTab = {
  props: {
    disableRipple: true,
  },
  overrides: {
    root: {
      fontSize: 18,
    },

    textColorPrimary: {
      "&$selected": {
        color: success.main,
      },
    },

    textColorSecondary: {
      color: common.white,
    },
  },
};

export default MuiTab;
