import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Dialog } from "@mui/material";
import { DECIMAL_SCALE, EVENT_TYPES, REPAY_TOLERANCE_AMOUNT } from "app/constants";
import { ReactComponent as ExitIcon } from "assets/svg/exit.svg";
import BigNumber from "bignumber.js";
import { CoinInfo } from "components/CoinInfo";
import { DialogApplyButton } from "components/DialogApplyButton";
import { DialogLogo } from "components/DialogLogo";
import { HealthFactorProgressBar } from "components/HealthFactorProgressBar";
import { NumericTextField } from "components/NumericTextField";
import { Spinner } from "components/Spinner";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { constants } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { useModalState } from "hooks";
import { useAtomicRepayment } from "hooks/atomic/useAtomicRepayment";
import { useGetTotalOutstanding } from "hooks/atomic/useGetTotalOutstanding";
import { useDebounce } from "hooks/common/useDebounce";
import { useLendingTokenMutations } from "hooks/mutation";
import _get from "lodash/get";
import React, { useMemo, useState } from "react";
import { convertNumberHex } from "utils/utils";
import { minBigNumber } from "utils/number";
import { SelectCollateralModal } from ".";
import { useStyles } from "./useStyles";

const RepayModal = (props) => {
  const { data: dataModal, onClose } = props;
  const { name, address: lendingTokenAddress, decimal: lendingDecimal, healthFactor } = dataModal;

  const context = useBorrowContext();
  const classes = useStyles();
  const [inputNum, setInputValue] = useState("");
  const [selectedAsset, setSelectedAsset] = useState(null);

  const {
    isOpen: isOpenSelectCollateralModal,
    onOpen: onOpenSelectCollateralModal,
    onClose: onCloseSelectCollateralModal,
  } = useModalState();

  React.useEffect(() => {
    if (!isOpenSelectCollateralModal) setSelectedAsset(null);
  }, [isOpenSelectCollateralModal]);

  const inputValue = useDebounce(inputNum, 200);
  const [allowance, userLendingTokenInfo] = useMemo(
    () => [
      _get(context, ["allowanceForFToken", lendingTokenAddress, "allowance"], false),
      _get(context, ["userTokenInfo"], []).find((o) => o.address === lendingTokenAddress),
    ],
    [context, lendingTokenAddress]
  );

  const userLendingBalance = userLendingTokenInfo ? Number(userLendingTokenInfo.balanceOf) : 0;
  const userLendingBalanceBN = parseUnits(userLendingBalance.toString(), lendingDecimal);

  const fTokenRate = useMemo(
    () => _get(context, ["fTokenRate", lendingTokenAddress], {}),
    [context, lendingTokenAddress]
  );

  const { isLoading, repay, approve } = useLendingTokenMutations({
    name,
    amount: inputValue,
    kind: EVENT_TYPES.repay,
  });

  const { isWaitingTx } = useAtomicRepayment();

  const outstanding = useGetTotalOutstanding(lendingTokenAddress);
  const outstandingFormat = formatUnits(outstanding || 0, lendingDecimal);
  const owedAmount = outstandingFormat;

  const maxValueBN = useMemo(
    () => (outstanding ? minBigNumber([outstanding, userLendingBalanceBN]) : 0),
    [outstanding, userLendingBalanceBN]
  );
  const maxValue = formatUnits(maxValueBN, lendingDecimal);

  const isRepayFully = useMemo(
    () => new BigNumber(inputValue).minus(owedAmount).abs().lte(REPAY_TOLERANCE_AMOUNT),
    [inputValue, owedAmount]
  );

  const isRepayDisabled =
    !inputValue.toString().length ||
    !Number(inputValue) ||
    (new BigNumber(inputValue).gt(maxValue) && !isRepayFully);

  const needAddAllowance = allowance.lt(parseUnits(convertNumberHex(inputValue), lendingDecimal));

  const resetInputValue = () => {
    setInputValue("");
  };

  const handleRepay = async () => {
    try {
      if (needAddAllowance) {
        await approve({ address: lendingTokenAddress, ftoken: _get(fTokenRate, "ftoken") });
        return;
      }
      const repayParams = {
        borrowToken: lendingTokenAddress,
        prjAmount: isRepayFully
          ? constants.MaxUint256.toString()
          : parseUnits(convertNumberHex(inputValue), lendingDecimal),
      };

      await repay(repayParams);
      resetInputValue();
      onClose();
    } catch {
      resetInputValue();
    }
  };

  const [logoLendingToken, lendingTokenName, lendingAsset] = useMemo(
    () => [
      _get(dataModal, ["logo"], "./assets/coins_list/usd-coin.svg"),
      _get(dataModal, ["name"], ""),
      _get(dataModal, ["symbol"], ""),
    ],
    [dataModal]
  );

  const handleRepayMax = () => {
    setInputValue(maxValue);
  };

  const handleOpenSelectCollateralModal = React.useCallback(() => {
    onOpenSelectCollateralModal();
  }, [onOpenSelectCollateralModal]);

  return (
    <>
      <DialogLogo logoUrl={logoLendingToken} name={lendingTokenName} />
      {(isLoading || isWaitingTx) && <Spinner position="absolute" color="success" />}
      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField value={inputNum} onChange={setInputValue} decimalScale={DECIMAL_SCALE} />
        <Box px={2} pb={2} mt={2} className={classes.contentInner}>
          <Box py={2}>
            <Typography color="textSecondary" className={classes.supplyRates}>
              Supply Rates <ExitIcon />
            </Typography>
          </Box>
          <Box className={classes.supplyBox}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                <CoinInfo logoUrl={logoLendingToken}>Distribution APY ({lendingAsset}) </CoinInfo>
              </Grid>
              <Grid item>
                <Typography color="primary">{_get(fTokenRate, "borrowApy", "-")} %</Typography>
              </Grid>
            </Grid>
          </Box>
          <HealthFactorProgressBar value={healthFactor} />
          <Box className={classes.maxRepay}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                Owed
              </Grid>
              <Grid item>
                <Typography color="primary">{`${owedAmount} ${lendingAsset}`} </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={9}>
              <DialogApplyButton disabled={isRepayDisabled} onClick={handleRepay}>
                {needAddAllowance ? "Re-Enable" : "Repay"}
              </DialogApplyButton>
            </Grid>
            <Grid item xs={3}>
              <Button className={classes.maxButton} onClick={handleRepayMax}>
                MAX
              </Button>
            </Grid>
          </Grid>

          <Box marginTop={2}>
            <DialogApplyButton onClick={handleOpenSelectCollateralModal}>
              Repay using collateral
            </DialogApplyButton>
          </Box>
        </Box>

        {isOpenSelectCollateralModal && (
          <Dialog
            open={isOpenSelectCollateralModal}
            onClose={onCloseSelectCollateralModal}
            className={classes.selectModal}
          >
            <SelectCollateralModal
              onClose={onCloseSelectCollateralModal}
              setSelectedAsset={setSelectedAsset}
              selectedAsset={selectedAsset}
              lendingAsset={dataModal}
              onCloseRepayModal={onClose}
            />
          </Dialog>
        )}
      </Box>
    </>
  );
};

export default RepayModal;
