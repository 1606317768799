import { DECIMAL_ETHER } from "app/constants";
import { constants } from "ethers";
import _get from "lodash/get";
import _reduce from "lodash/reduce";
import { useQuery } from "react-query";
import { bUSDCContractABI } from "utils/ethereum/abi";
import { useMultiCallContractInstance } from "./contract/multicall/useMultiCallContract";

const methodOnFTokenContract = {
  borrowRatePerBlock: "borrowRatePerBlock",
  supplyRatePerBlock: "supplyRatePerBlock",
};

export const getApy = (rate) => {
  const blockPerYear = process.env.REACT_APP_BLOCKS_PER_DAY * 356;
  // eslint-disable-next-line no-restricted-properties
  const rateIntMath = Math.pow(+rate / 10 ** DECIMAL_ETHER + 1, blockPerYear) - 1;

  return rateIntMath.toString();
};

const useGetApyOnToken = (fTokens = []) => {
  const multiCallSMC = useMultiCallContractInstance();

  return useQuery(
    ["apy-pit-query"],
    async () => {
      const lisRequest = fTokens.map((o) => {
        const FContract = {
          contractAddress: o.ftoken,
          abi: bUSDCContractABI,
          reference: o.ftoken,
          calls: [
            {
              methodParameters: [],
              methodName: methodOnFTokenContract.borrowRatePerBlock,
              reference: o.token,
            },
            {
              methodParameters: [],
              methodName: methodOnFTokenContract.supplyRatePerBlock,
              reference: o.token,
            },
          ],
        };
        return FContract;
      });

      const { results } = await multiCallSMC.call(lisRequest);

      const fTokenApyList = _reduce(
        results,
        (result, value, key) => {
          const borrowRate = _get(value, ["callsReturnContext", 1, "returnValues", 0, "hex"], "0");
          const lenderRate = _get(value, ["callsReturnContext", 0, "returnValues", 0, "hex"], "0");

          const token = _get(value, ["callsReturnContext", 0, "reference"], constants.AddressZero);
          const data = {
            lenderApy: getApy(+borrowRate),
            borrowApy: getApy(+lenderRate),
            borrowRate: +borrowRate,
            lenderRate: +lenderRate,
            ftoken: key,
          };
          return { ...result, [token]: data };
        },
        {}
      );

      return fTokenApyList;
    },
    {
      enabled: Boolean(fTokens.length),
    }
  );
};

export default useGetApyOnToken;
