import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { Analytics, Footer, Header, NotificationProvider, ScrollToTop } from "components";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { configureChains, createClient as createClientWagmi, WagmiConfig } from "wagmi";
import {
  arbitrumGoerli,
  goerli,
  optimismGoerli,
  polygonMumbai,
  mainnet,
  polygon,
  arbitrum,
  optimism,
  zkSyncTestnet,
} from "wagmi/chains";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import { alchemyProvider } from "wagmi/providers/alchemy";

import { WalletProvider } from "context";
import theme from "theme";
import Routes from "./Routes";

const TestNetwork = [goerli, polygonMumbai, optimismGoerli, arbitrumGoerli, zkSyncTestnet];
const MainNetwork = [mainnet, polygon, arbitrum, optimism];

const { chains, provider, webSocketProvider } = configureChains(
  process.env.REACT_APP_NETWORK === "mainnet" ? MainNetwork : TestNetwork,
  [
    alchemyProvider({ apiKey: "zRcCFIoXNrtwGrVTV7vDHEKH1emcdJwS" }),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: chain.rpcUrls.default.http[0],
      }),
      priority: 0,
    }),
    publicProvider(),
  ]
);

const clientWagmi = createClientWagmi({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
        projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

const useGlobalStyles = makeStyles({
  "@global": {
    "::-webkit-scrollbar": {
      width: "2px",
      height: "2px",
      "&:hover": {
        height: "20px",
      },
    },
    "::-webkit-scrollbar-thumb": {
      background: "white",
      borderRadius: "8px",

      "&:hover": {
        background: "red",
      },
    },
    body: {
      height: "100%",
      background: "#000",
    },
    ".MuiMenu-list": {
      background: "#2E2E2E",
    },
    ".MuiPaper-rounded": {},
    h1: {
      color: "#fff",
      fontWeight: "300",
      textAlign: "center",
      fontSize: "30px",
    },
    ".title-lender": {
      fontSize: "20px",
    },
    ".MuiTableContainer-root": {},
  },
});

const useStyles = makeStyles(() => ({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },

  content: {
    flex: "1 0 auto",
  },
}));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App = () => {
  useGlobalStyles();
  const classes = useStyles();

  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig client={clientWagmi}>
        <WalletProvider>
          <Router>
            <Analytics />
            <ScrollToTop />
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <NotificationProvider>
                <div className={classes.mainWrapper}>
                  <Header />
                  <Container disableGutters={false} maxWidth="xl" className={classes.content}>
                    <Routes />
                  </Container>
                  <Footer />
                </div>
              </NotificationProvider>
            </ThemeProvider>
          </Router>
        </WalletProvider>
      </WagmiConfig>
    </QueryClientProvider>
  );
};

export default App;
