import { makeStyles, Typography } from "@material-ui/core";
import TableContainer from "@mui/material/TableContainer";
import { Fragment } from "react";
import { useTable } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Grid } from "@mui/material";
import OpenPositionRow from "./OpenPositionRow";

const useStyles = makeStyles((theme) => ({
  RowNumberTable: {
    paddingLeft: "8px",
    color: "#fff",
    fontSize: "16px",
    display: "flex",
    borderRight: "#ccc 1px solid",
    "@media(max-width:1024px)": {
      borderRight: "none",
      justifyContent: "space-between",
    },

    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  NumberProfit: {
    paddingLeft: "8px",
    color: "#fff",
    fontSize: "16px",
    display: "flex",
    "@media(max-width:1024px)": {
      justifyContent: "space-between",
    },

    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  TitleTable: {
    margin: "10px 0",
    marginTop: "40px",
  },
  TableStyle: {
    backgroundColor: "rgb(33 34 34) !important",
    color: "#fff !important",
    "& .MuiTableRow-head": {
      borderBottom: "#3f4445 1px solid",
    },
    "& .MuiTableCell-root": {
      borderBottom: "#3f4445 1px solid",
      fontSize: "14px",
      textWrap: "nowrap",
    },
    "& .icon-style": {
      width: "20px",
      position: "absolute",
      right: 0,
      bottom: "31%",
    },

    [theme.breakpoints.down("sm")]: {
      "& .MuiTableCell-root": {
        fontSize: "12px",
      },
    },
  },
  Pagination: {
    color: "#fff !important",
  },
  TableButton: {
    padding: "4px 8px",
    borderRadius: "5px",
    maxWidth: "unset",
    backgroundColor: "rgb(110 116 123)",
    "&:hover": {
      backgroundColor: "rgb(137 143 150)",
    },
  },
  RowButton: {
    width: "250px !important",
    justifyContent: "space-between",
  },
  RowStyle: {
    background: "#2a2a2a",
    "& .MuiTableCell-body": {
      color: "#fff !important",
    },
    "& .buffer-color": {
      background: "#d79b00",
      color: "#fff",
      borderRadius: "8px",
      padding: "3px 6px",
    },
  },
  EmptyDataText: {
    textAlign: "center",
    fontSize: "1.5rem",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
  },
}));

export const OpenPositionTable = ({ columns, data }) => {
  const classes = useStyles();
  const { positionList } = data;

  const { rows, prepareRow, getTableProps } = useTable({
    columns,
    data: positionList || [],
  });

  return (
    <>
      <Box className={classes.ChartBox}>
        <Grid container justifyContent="space-between" className={classes.TitleTable}>
          <Grid item xs={12}>
            <Box
              color="#fff"
              sx={{
                fontSize: {
                  sm: 17,
                  xs: 14,
                },
              }}
            >
              Open Position
            </Box>
          </Grid>
        </Grid>
        <TableContainer>
          <Table {...getTableProps()} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes.TableStyle}>
                {columns?.map((column) => (
                  <TableCell className={classes.TableStyle} key={column.accessor}>
                    {column.Header}
                  </TableCell>
                ))}
                <TableCell className={classes.TableStyle} />
              </TableRow>
            </TableHead>

            {rows?.length > 0 && (
              <TableBody>
                {rows?.map((row, idx) => {
                  if (!positionList[idx].lendingToken) return <></>;

                  prepareRow(row);
                  const rowProps = row.getRowProps();

                  return (
                    <OpenPositionRow
                      key={rowProps.key}
                      row={row}
                      rowProps={rowProps}
                      positionList={positionList}
                    />
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {rows?.length < 1 && (
          <Typography color="secondary" className={classes.EmptyDataText}>
            No open positions
          </Typography>
        )}
      </Box>
    </>
  );
};
