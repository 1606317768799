import { useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import { Typography, Grid, Avatar, Box, Popper } from "@material-ui/core";

import { HealthFactorProgressBar, HealthTooltip } from "components";

const useStyles = makeStyles((theme) => ({
  container: { display: "flex", justifyContent: "center" },
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  md: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatar: {
    marginRight: 10,
    borderRadius: "50%",
  },
  lpToken: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
  },
  spacingToken: {
    marginRight: "2px",
  },
  customTooltip: {
    zIndex: 99,
    pointerEvents: "none",
    marginTop: 10,
    minWidth: 190,
    padding: 20,
    borderRadius: 4,
    backgroundColor: theme.palette.text.primary,
    filter: "drop-shadow(rgba(0, 0, 0, 0.8) 0 2px 10px)",
    "&:before": {
      content: "''",
      position: "absolute",
      top: -4,
      left: "50%",
      transform: "translateX(-50%) rotate(45deg)",
      width: 15,
      height: 15,
      borderRadius: 2,
      backgroundColor: theme.palette.text.primary,
    },
  },
  flexWrap: {
    flexWrap: "nowrap",
  },
}));

const CoinInfo = ({
  logoUrl,
  logoLPOne,
  logoLPTwo,
  isLPToken = false,
  children,
  size,
  description,
  healthFactor,
  currentPrice,
  lendingSymbol,
}) => {
  const classes = useStyles();

  const [isOpen, setOpen] = useState(false);
  const popoverAnchor = useRef(null);

  const popoverHover = () => {
    setOpen(true);
  };

  const popoverLeave = () => {
    setOpen(false);
  };

  return (
    <Grid container alignItems="center" spacing={1} wrap="nowrap">
      <Grid item className={classes.container}>
        {isLPToken ? (
          <Grid container className={classes.flexWrap}>
            <Grid item className={classes.spacingToken}>
              <Avatar src={logoLPOne} alt="Coin Logo" className={classes.lpToken} />
            </Grid>
            <Grid item>
              <Avatar
                src={logoLPTwo}
                style={{
                  marginLeft: -12,
                }}
                alt="Coin Logo"
                className={classes.lpToken}
              />
            </Grid>
          </Grid>
        ) : (
          <Avatar
            src={logoUrl}
            alt="Coin Logo"
            classes={{ root: clsx(classes[size]) }}
            className={classes.avatar}
          />
        )}
      </Grid>
      <Grid item>
        <Typography>{children}</Typography>
        {description && <Typography color="primary">{description}</Typography>}
        {healthFactor && (
          <>
            <Box ref={popoverAnchor} onMouseEnter={popoverHover} onMouseLeave={popoverLeave}>
              <HealthFactorProgressBar
                value={healthFactor}
                size="small"
                price={currentPrice}
                collateralSymbol={children}
                lendingSymbol={lendingSymbol}
              />
            </Box>
            <Popper
              open={isOpen}
              anchorEl={popoverAnchor.current}
              className={classes.customTooltip}
              PaperProps={{
                onMouseEnter: popoverHover,
                onMouseLeave: popoverLeave,
              }}
            >
              <HealthTooltip
                value={healthFactor}
                price={currentPrice}
                collateralSymbol={children}
                lendingSymbol={lendingSymbol}
              />
            </Popper>
          </>
        )}
      </Grid>
    </Grid>
  );
};

CoinInfo.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  logoLPOne: PropTypes.string,
  logoLPTwo: PropTypes.string,
  isLPToken: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  size: PropTypes.oneOf(["large", "default", "md"]),
  description: PropTypes.string,
  healthFactor: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

CoinInfo.defaultProps = {
  size: "default",
  description: "",
  healthFactor: false,
  logoLPOne: "",
  logoLPTwo: "",
  isLPToken: false,
};

export default CoinInfo;
