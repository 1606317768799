import icon from "assets/svg/info-icon.svg";
import { Tooltip, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  TooltipBg: {
    fontSize: "12px",
    marginBottom: 0,
  },
}));

export const IconInfo = ({ title }) => {
  const classes = useStyles();
  return (
    <>
      <Tooltip title={<Box className={classes.TooltipBg}>{title}</Box>} placement="top" arrow>
        <img src={icon} style={{ width: 20, marginRight: 1 }} alt="" />
      </Tooltip>
    </>
  );
};
