import { gql, useQuery as useClientQuery } from "@apollo/client";
import { useWallet } from "hooks";
import _get from "lodash/get";
import { useCallback, useMemo } from "react";

import {
  groupingBorrowLogs,
  groupingTotalStates,
  mappingToGetLendingToken,
  mappingToGetProjectList,
} from "./helperFn";

const GET_PRJ_TOKEN = gql`
  query ExampleQuery($account: String!) {
    projectTokens {
      name
      symbol
      address
      isDepositPaused
      isWithdrawPaused
      underlyingTokens {
        id
        name
        symbol
        address
        linksNumber
      }
      depositedAmount
      lvr
      pitAmount
      borrowedAmount
      outstandingAmount
      borrowingLevelAmount
      currentBorrowingLevel
      __typename
    }

    totalStates {
      type
      amount
      lendingTokenAddress
    }

    lendingTokens {
      name
      symbol
      address
      isPaused
    }
    borrowLogs(where: { userAddress: $account }, orderBy: date, orderDirection: desc) {
      amount
      asset
      prjToken
      type
      date
      userAddress
      prjTokenAddress
    }
  }
`;

export const useFetchGraphData = () => {
  const { account } = useWallet();

  const { data, refetch, loading } = useClientQuery(GET_PRJ_TOKEN, {
    variables: { account },
  });

  const APY = useMemo(() => groupingTotalStates(data), [data]);

  const getLendingApy = useCallback(
    (tokenAddress) =>
      [..._get(APY, "borrowing_apy", [])].find((o) => o.lendingTokenAddress === tokenAddress),
    [APY]
  );

  const lendingTokens = useMemo(() => {
    const tokens = [...mappingToGetLendingToken(data)].filter((o) => !o?.isPaused);

    const usdcAddress = tokens.find((o) => o.symbol === "USDC");

    localStorage.setItem("usdcToken", _get(usdcAddress, "address"));

    return tokens;
  }, [data]);

  const getBorrowingApy = useCallback(
    (tokenAddress) =>
      [..._get(APY, "borrowing_apy", [])].find((o) => o.lendingTokenAddress === tokenAddress),
    [APY]
  );

  return {
    projectTokenList: [...mappingToGetProjectList(data)].filter(
      (o) => !o?.isDepositPaused && !o?.isWithdrawPaused
    ),
    updateDataGraph: () => refetch(account),
    borrowLogs: groupingBorrowLogs(data),
    borrowLogsRaw: _get(data, ["borrowLogs"]),
    availableBorrowTokens: lendingTokens,
    isLoading: loading,
    APY,
    getLendingApy,
    getBorrowingApy,
  };
};
