export const SELECT = {
  tokensIssued: "total_pit",
  totalAmount: "total_amount_collateral_deposited",
  totalOutstanding: "total_outstanding",
  ratio: "collateral_vs_loan_ration",
  borrowingAPY: "apy",
  lenderAPY: "lender_apy",
  totalSupply: "total_supply",
};

export const QueryGraphEntity = {
  [SELECT.totalAmount]: "collateralDepositedHistories",
  [SELECT.tokensIssued]: "pittokenHistories",
  [SELECT.totalOutstanding]: "outstandingHistories",
  [SELECT.ratio]: "collateralVSLoanRatioHistories",
  [SELECT.borrowingAPY]: "borrowingAPYHistories",
  [SELECT.lenderAPY]: "lenderAPYHistories",
  [SELECT.totalSupply]: "lenderAggregateCapitalDepositedHistories",
};

export const INTERVAL = {
  HOUR: "hour",
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
  // YEARS: "5years",
};

export const INTERVALS = [
  {
    name: "1H",
    value: INTERVAL.HOUR,
  },
  {
    name: "1D",
    value: INTERVAL.DAY,
  },
  {
    name: "1W",
    value: INTERVAL.WEEK,
  },
  {
    name: "1M",
    value: INTERVAL.MONTH,
  },
  {
    name: "1Y",
    value: INTERVAL.YEAR,
  },
  // {
  //   name: "5Y",
  //   value: INTERVAL.YEARS,
  // },
];

export const DEFAULT_INTERVAL_VALUE = INTERVAL.YEAR;

export const CHARTS = [
  {
    title: "Network Total PIT Tokens Issued ($)",
    key: QueryGraphEntity[SELECT.tokensIssued],
    color: "#14A38B",
    value: SELECT.tokensIssued,
    tooltip:
      "Total borrowing capacity of all users' deposited collateral assets. i.e. collateral value * LVR for each collateral asset.",
  },
  {
    title: "Total Collateral Value ($)",
    key: QueryGraphEntity[SELECT.totalAmount],
    color: "#FF7171",
    value: SELECT.totalAmount,
    tooltip:
      "Total value of all users' deposited collateral assets. (Shown for selected capital asset or ALL capital assets.)",
  },
  {
    title: "Total Outstanding ($)",
    key: QueryGraphEntity[SELECT.totalOutstanding],
    color: "#F2AC57",
    value: SELECT.totalOutstanding,
    tooltip:
      "Total value of all loans outstanding, including accrued interest. (Shown for selected capital asset or ALL capital assets.)",
  },
  {
    title: "Collateral Vs Loans Ratio",
    key: QueryGraphEntity[SELECT.ratio],
    color: "#A5A6F6",
    value: SELECT.ratio,
    tooltip:
      "Current deposited collateral total value vs open loan positions. (Shown for selected capital asset or ALL capital assets.)",
  },
  {
    title: "Borrow APY",
    key: QueryGraphEntity[SELECT.borrowingAPY],
    color: "#EF5DA8",
    value: SELECT.borrowingAPY,
    tooltip:
      "Current borrow Annual Percentage Yield. Each capital asset has a separate market. Prevailing lender deposits vs borrower demand affects the market's APY. (Shown for selected capital asset or ALL capital assets.)",
  },
  {
    title: "Lender APY",
    key: QueryGraphEntity[SELECT.lenderAPY],
    color: "#78FF56",
    value: SELECT.lenderAPY,
    tooltip:
      "Current lending Annual Percentage Yield. Each capital asset has a separate market. Prevailing lender deposits vs borrower demand affects the market's APY. (Shown for selected capital asset or ALL capital assets.)",
  },
  {
    title: "Lender Aggregate Capital Deposits ($)",
    key: QueryGraphEntity[SELECT.totalSupply],
    color: "#FFF494",
    value: SELECT.totalSupply,
    tooltip:
      "Total value of all lenders' deposited capital assets. (Shown for selected capital asset or ALL capital assets.)",
  },
];
