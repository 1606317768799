import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { Box, MenuItem, Skeleton } from "@mui/material";
import { CoinInfo } from "components/CoinInfo";
import { logoBySymbol } from "constants/TokenListGoerliNetwork";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { useToggle } from "hooks";
import { useDepositedAssetStates } from "hooks/contexts/BorrowContext/useDepositedAssetStates";
import { isEmpty } from "lodash";
import _get from "lodash/get";
import React, { useMemo } from "react";
import { MenuCollateralItem } from ".";

const NO_VALUE = "No valid asset found";

const useStyles = makeStyles((theme) => ({
  SelectItem: {
    color: "#000",
    background: "red",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    minHeight: "70px",

    "& p": {
      color: "#fff",
    },

    "&.MuiMenuItem-root:hover": {
      backgroundColor: "rgba(0, 0, 0, .3)",
    },
  },
  selectStyled: {
    width: "100%",
    minHeight: "50px",
    background: "#fff",
    color: "#000",
    fontSize: "14px",
    position: "relative",
    marginTop: "8px",
    display: "flex",
    border: "#BDBDBD 2px solid",

    [theme.breakpoints.down("xs")]: {
      maxWidth: "unset",
    },

    "& .MuiSelect-icon": {
      color: "#fff",
      marginRight: "10px",
      fontSize: "30px",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "42px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },

    "& .MuiSelect-select.MuiSelect-selectMenu": {
      fontWeight: "500",
      fontSize: 16,
    },

    "& p": {
      fontWeight: "500",
    },

    "& svg": {
      fill: "#BDBDBD",
    },

    "@media(max-width: 576px)": {
      width: "100%",
    },
  },
  imgIcon: {
    width: "42px",
    height: "42px",
    marginRight: "8px",
    borderRadius: "50%",
  },
  arrowIcon: {
    width: "30px !important",
    cursor: "pointer",
  },
  logo: {
    width: "45px",
    marginRight: "8px",
  },
  skeletonWrapper: {
    marginTop: "8px",
    borderRadius: "10px",
  },

  title: {
    marginBottom: 0,
  },
  container: {},
  ".MuiMenu-list p": {
    color: "#fff",
  },
}));

function SelectCollateral({ setSelectedAsset, selectedAsset, lendingAsset }) {
  const classes = useStyles();
  const ctx = useBorrowContext();
  const [depositedAssetStates] = useDepositedAssetStates();
  const [open, setOpen] = useToggle(false);

  const decimalOfContractToken = useMemo(() => _get(ctx, ["decimalOfContractToken"], {}), [ctx]);

  const filterAvailableCollateral = React.useMemo(
    () =>
      depositedAssetStates.map((c) => {
        const decimal = _get(decimalOfContractToken, [c.address]);

        return { ...c, decimal };
      }),
    [depositedAssetStates, decimalOfContractToken]
  );

  const handleChange = (item) => {
    setSelectedAsset(item);
    setOpen();
  };

  const renderCoin = (item) => (
    <Box sx={{ ml: 1 }}>
      <CoinInfo
        size="md"
        logoUrl={
          item?.underlyingTokens?.length ? "" : item?.logo ?? "/assets/coins_list/pjToken.png"
        }
        isLPToken={item?.underlyingTokens?.length > 0}
        logoLPOne={
          item?.underlyingTokens?.length ? logoBySymbol[item?.underlyingTokens[0]?.symbol] : ""
        }
        logoLPTwo={
          item?.underlyingTokens?.length ? logoBySymbol[item?.underlyingTokens[1].symbol] : ""
        }
      >
        {isEmpty(item?.underlyingTokens)
          ? item?.symbol
          : `${item?.underlyingTokens[0].symbol}/${item?.underlyingTokens[1]?.symbol}`}
      </CoinInfo>
    </Box>
  );

  const renderMenuItem = (item) => (
    <MenuCollateralItem
      lendingAsset={lendingAsset}
      projectAsset={item}
      classes={classes}
      handleChange={handleChange}
    >
      {renderCoin(item)}
    </MenuCollateralItem>
  );

  return (
    <Box className={classes.container}>
      {filterAvailableCollateral ? (
        <Select
          onChange={(event) => {
            setSelectedAsset(event.target.value);
          }}
          className={classes.selectStyled}
          displayEmpty
          value={selectedAsset}
          open={open}
          onOpen={setOpen}
          onClose={setOpen}
          renderValue={selectedAsset ? (item) => renderCoin(item) : null}
        >
          {filterAvailableCollateral?.length > 0 && (
            <MenuItem sx={{ color: "#fff" }} value={null} disabled>
              Select collateral to repay
            </MenuItem>
          )}
          {filterAvailableCollateral?.length > 0 ? (
            filterAvailableCollateral.map(renderMenuItem)
          ) : (
            <MenuItem className={classes.SelectItem} value={NO_VALUE} disabled>
              No valid asset found
            </MenuItem>
          )}
        </Select>
      ) : (
        <>
          <Skeleton
            className={classes.skeletonWrapper}
            variant="rounded"
            width="100%"
            height={40}
          />
        </>
      )}
    </Box>
  );
}

export default SelectCollateral;
