export const logoBySymbol = {
  USDC: "/assets/coins_list/usd-coin.svg",
  USB: "/assets/images/usb_token.png",
  WETH: "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
  wstETH: "/assets/coins_list/wstETH-icon.png",
  PRJ1: "/assets/coins_list/pjToken.png",
  PRJ2: "/assets/coins_list/pjToken.png",
  PRJ3: "/assets/coins_list/pjToken.png",
};

export const availableBorrowTokensInstance = [
  {
    uuid: "2",
    name: "USDC",
    symbol: "USDC",
    logo: "/assets/coins_list/usd-coin.svg",
    address: "0xccc70874548F12320DB34b1f36343C5afc5484e4",
  },
  {
    uuid: "2",
    name: "USB",
    symbol: "USB",
    logo: "/assets/images/usb_token.png",
    address: "0xFee9CFE582C501234C8ac18b01EA3F3199f4efB8",
  },
];

export const availableFTokensInstance = [
  {
    uuid: "2",
    name: "fUSDC",
    symbol: "fUSDC",
    logo: "/assets/coins_list/usd-coin.svg",
    address: "0x26868261FB06113cd347A33799Bf8E643c56734f",
  },
  {
    uuid: "2",
    name: "fUSB",
    symbol: "fUSB",
    logo: "/assets/images/usb_token.png",
    address: "0xcEC6943aC4462FBFCc9AD0Be6B1391e2B3b17422",
  },
];

export const availableDepositTokensInstance = [
  {
    uuid: "21aedab7-7171-4b16-977c-dbdadb1cd0a2",
    name: "PRJ1",
    symbol: "PRJ1",
    logo: "/assets/coins_list/prjIcon.png",
    address: "0xed9f4c37094ded8a681e0ffcc9f5a7ee5247001a",
  },
  {
    uuid: "4b274bdf-35ba-4328-b394-790a0c32dbd7",
    name: "PRJ2",
    symbol: "PRJ2",
    logo: "/assets/coins_list/prjIcon.png",
    address: "0x96dc1f009f9e01d15b87f78795b538e5827f365e",
  },
  {
    uuid: "b22bdb03-c7ac-4e94-9268-9e137d642f96",
    name: "PRJ3",
    symbol: "PRJ3",
    logo: "/assets/coins_list/prjIcon.png",
    address: "0x78d1f8c7bc0794d59c50abc4f5e74c47b212f402",
  },
  {
    uuid: "c7b5125b-08fb-4310-a9fb-b2ad8606cdc0",
    name: "PRJ4",
    symbol: "PRJ4",
    logo: "/assets/coins_list/prjIcon.png",
    address: "0x5183e6ce9621af6357649c9873248c341a44ce5c",
  },
  {
    uuid: "9e07ec70-86de-424b-942a-4b87cf583457",
    name: "PRJ5",
    symbol: "PRJ5",
    logo: "/assets/coins_list/prjIcon.png",
    address: "0x4e94f063559874a255e95157a71a3f00c5e90401",
  },
  // {
  //   uuid: "0512fafe-2668-4ef0-bcdf-6f69c35b7dc0",
  //   name: "PRJ6",
  //   symbol: "PRJ6",
  //   logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3267.png",
  //   address: "0x6e59c4a1ed72a9f5bfd12d9f2b172cc91fcdb97e",
  // },
];
