import { Button, Typography, makeStyles } from "@material-ui/core";
import { Box, Dialog, FormControlLabel } from "@mui/material";
import { BorrowModal, CoinInfo, NumericText, ShareModal, Table, TableSkeleton } from "components";
import { SwitchButton } from "components/Leveraged/SwitchButton";
import { Text } from "components/Title/Text";
import { useBorrowContext } from "context/contracts/BorrowContext";
import { BigNumber } from "ethers";
import { useModalState, useWallet } from "hooks";
import numeral from "numeral";
import { SupplyModal } from "pages/MultiAssetDashboard/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { lenderFakeData } from "utils/lenderFakeData";
import { useCommonStyle } from "./commonStyle";

const MAX_HEIGHT_CONTENT = 1235.87890625;

function AssetToBorrowOrLend({ myBorrowingHeight, myLendingHeight }) {
  const [titleHeight, setTitleHeight] = useState(0);

  const maxHeight = useMemo(
    () => MAX_HEIGHT_CONTENT - myBorrowingHeight - myLendingHeight - titleHeight - 1.5,
    [myBorrowingHeight, myLendingHeight, titleHeight]
  );

  const useStyles = makeStyles((theme) => ({
    container: {
      border: "1px solid #434849",
      "& .MuiTableContainer-root": {
        maxHeight,

        [theme.breakpoints.down("sm")]: {
          maxHeight: "unset",
        },
      },

      "& .MuiTableCell-stickyHeader": {
        backgroundColor: "#191919",
      },
      [theme.breakpoints.down("sm")]: {},
    },
    title: {
      background: "#191919",
      padding: "8px 8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#FFF",

      "& p": {
        margin: 0,
      },
    },

    TableStyle: {
      backgroundColor: "rgb(33 34 34) !important",
      color: "#fff !important",
      "& .MuiTableRow-head": {
        borderBottom: "#3f4445 1px solid",
      },
      "& .MuiTableCell-root": {
        borderBottom: "#3f4445 1px solid",
        fontSize: "14px",
        textWrap: "nowrap",
      },
      "& .icon-style": {
        width: "20px",
        position: "absolute",
        right: 0,
        bottom: "31%",
      },

      [theme.breakpoints.down("sm")]: {
        "& .MuiTableCell-root": {
          fontSize: "12px",
        },
      },
    },

    EmptyDataText: {
      textAlign: "center",
      fontSize: "1.5rem",
      marginTop: "1.5rem",
      color: "#fff",
    },

    switchButton: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      color: "#6E747B",
      fontSize: 16,
      fontWeight: 600,
    },

    actionBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(0.5),
      justifyContent: "end",
    },
  }));

  const classes = useStyles();
  const commonStyle = useCommonStyle();

  const [hideZero, setHideZero] = React.useState(false);
  const {
    isLoading,
    availableBorrowOrLendTokens,
    decimalOfContractToken,
    totalEstimatedPITRemaining,
    totalEstimatedWeightedLoanInUSD,
    estimatedOutstandingInUSD,
  } = useBorrowContext();

  const { account } = useWallet();
  const [tokenBorrow, setTokenBorrow] = useState();
  const [tokenSupply, setTokenSupply] = useState();

  const [openShare, setOpenShare] = useState(false);
  const [shareData, setShareData] = useState({
    token: "",
    amount: "",
    apy: "",
  });

  const totalHealth = useMemo(() => {
    if (totalEstimatedWeightedLoanInUSD && totalEstimatedPITRemaining)
      return totalEstimatedWeightedLoanInUSD.add(totalEstimatedPITRemaining);

    return 0;
  }, [totalEstimatedPITRemaining, totalEstimatedWeightedLoanInUSD]);

  const healthFactor = useMemo(() => {
    if (totalHealth && totalEstimatedWeightedLoanInUSD) {
      if (!totalEstimatedWeightedLoanInUSD.isZero() && !totalHealth.isZero())
        return totalHealth.div(totalEstimatedWeightedLoanInUSD);
    }

    return 0;
  }, [totalEstimatedWeightedLoanInUSD, totalHealth]);

  const COLUMNS = [
    {
      Header: <Text>Asset</Text>,
      accessor: "asset",
      Cell: ({
        row: {
          original: { symbol, logo },
        },
      }) => <CoinInfo logoUrl={logo}>{symbol}</CoinInfo>,
    },
    {
      Header: (
        <Box>
          <Text>Borrow </Text>
          <Text>APY</Text>
        </Box>
      ),
      accessor: "borrowAPY",
      Cell: ({ value }) => <NumericText value={value?.amount || 0} precision={2} suffix="%" />,
    },
    {
      Header: (
        <Box>
          <Text>Supply </Text>
          <Text>APY</Text>
        </Box>
      ),
      accessor: "lendingAPY",
      Cell: ({ value }) => <NumericText value={value?.amount || 0} precision={2} suffix="%" />,
    },
    {
      Header: <Text>LVR</Text>,
      accessor: "LVR",
      Cell: ({
        row: {
          original: { lvr },
        },
      }) => <Typography color="secondary">{numeral(lvr).format("0,0%")}</Typography>,
    },
    {
      Header: (
        <Box>
          <Text>Wallet </Text>
          <Text>balance</Text>
        </Box>
      ),
      accessor: "balance",
      Cell: ({
        value,
        row: {
          original: { balanceInUsd, symbol },
        },
      }) => (
        <>
          <NumericText value={Number(value).toFixed(9)} precision={2} suffix={symbol} />
          <Box>
            $<NumericText value={Number(balanceInUsd).toFixed(9)} precision={2} />
          </Box>
        </>
      ),
    },
    {
      Header: (
        <Box display="flex" sx={{ textWrap: "nowrap" }} gridGap={2}>
          <Text>Total supply</Text>
        </Box>
      ),
      accessor: "totalSupply",
      Cell: ({
        value,
        row: {
          original: { totalSupplyInUsd, symbol },
        },
      }) => (
        <>
          <NumericText value={Number(value).toFixed(9)} precision={2} suffix={symbol} />
          <Box>
            $
            <NumericText value={Number(totalSupplyInUsd).toFixed(9)} precision={2} />
          </Box>
        </>
      ),
    },
  ];

  const listAvailableBorrowOrLendTokens = React.useMemo(
    () =>
      availableBorrowOrLendTokens &&
      estimatedOutstandingInUSD &&
      availableBorrowOrLendTokens.map((o) => {
        let borrowList = {};
        estimatedOutstandingInUSD.forEach((i) => {
          if (i.lendingToken === o.address) {
            borrowList = { ...borrowList, ...i, ...o };
          }
        });

        return borrowList;
      }),
    [availableBorrowOrLendTokens, estimatedOutstandingInUSD]
  );

  const filterAvailableBorrowOrLendTokens = React.useMemo(() => {
    if (listAvailableBorrowOrLendTokens && hideZero) {
      return listAvailableBorrowOrLendTokens.filter(
        (token) =>
          Number(token.balance) !== 0 ||
          Number(token.balanceOfUnderlyingView) !== 0 ||
          !BigNumber.from(token.loanBody).isZero()
      );
    }

    return listAvailableBorrowOrLendTokens;
  }, [listAvailableBorrowOrLendTokens, hideZero]);

  const {
    isOpen: isOpenBorrowModalOpen,
    onOpen: onOpenBorrowModal,
    onClose: onCloseBorrowModal,
  } = useModalState();

  const handleOpenBorrowModal = useCallback(
    (data) => {
      setTokenBorrow(data);
      onOpenBorrowModal();
    },
    [onOpenBorrowModal]
  );

  const handleCloseModalShare = useCallback(() => {
    setOpenShare(false);
  }, []);

  const handleCloseSupplyModal = useCallback(() => {
    setTokenSupply(null);
  }, []);

  useEffect(() => {
    if (!availableBorrowOrLendTokens) return;
    if (!tokenSupply) return;

    const activeToken = availableBorrowOrLendTokens.find(
      ({ address }) => address === tokenSupply.address
    );
    if (activeToken) {
      setTokenSupply(activeToken);
    }
  }, [availableBorrowOrLendTokens, tokenSupply]);

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        Header: "",
        accessor: "button",
        props: {
          align: "right",
        },
        Cell: ({ row: { original } }) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "4px",
              justifyContent: "end",
            }}
            className={classes.actionBox}
          >
            <Button className={commonStyle.button} onClick={() => handleOpenBorrowModal(original)}>
              Borrow
            </Button>
            <Button className={commonStyle.button} onClick={() => setTokenSupply(original)}>
              Lend
            </Button>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleOpenBorrowModal, setTokenSupply]
  );

  return (
    <Box className={`${classes.container} ${commonStyle.containerBackground}`}>
      <Box
        className={classes.title}
        ref={(node) => setTitleHeight(node?.getBoundingClientRect()?.height)}
      >
        <p style={{ fontSize: "18px", paddingLeft: "16px" }}>Assets to borrow or lend</p>
        <Box className={classes.switchButton}>
          <Box>Hide zero balances</Box>
          <FormControlLabel
            className={classes.SwitchWrapper}
            control={
              <SwitchButton
                sx={{ m: 1 }}
                defaultChecked={hideZero}
                setHideZero={setHideZero}
                hideZero={hideZero}
              />
            }
          />
        </Box>
      </Box>

      {!account && (
        <>
          <Table columns={columns} data={lenderFakeData} />
        </>
      )}

      {isLoading || !filterAvailableBorrowOrLendTokens ? (
        account && <TableSkeleton rows={3} />
      ) : (
        <>
          <Table stickyHeader columns={columns} data={filterAvailableBorrowOrLendTokens} />
          {isOpenBorrowModalOpen && (
            <Dialog open={isOpenBorrowModalOpen} onClose={onCloseBorrowModal} noPadding>
              <BorrowModal
                {...{
                  token: {
                    ...tokenBorrow,
                    decimal: decimalOfContractToken[tokenBorrow?.address?.toLowerCase()],
                  },
                  onClose: () => onCloseBorrowModal(),
                  healthFactor,
                  openShare: () => setOpenShare(true),
                  setShareData: () => setShareData(),
                }}
              />
            </Dialog>
          )}

          {tokenSupply && (
            <Dialog open={tokenSupply} onClose={handleCloseSupplyModal} noPadding>
              <SupplyModal
                data={{
                  ...tokenSupply,
                }}
                onClose={handleCloseSupplyModal}
                openShare={() => setOpenShare(true)}
                setShareData={() => setShareData()}
              />
            </Dialog>
          )}
          <ShareModal
            open={openShare}
            onCloseModal={handleCloseModalShare}
            data={shareData}
            type="borrow"
          />
        </>
      )}
    </Box>
  );
}

export default AssetToBorrowOrLend;
